import React, { FunctionComponent } from "react";
import {
  Alert,
  Skeleton,
  Box,
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Popover,
  useMediaQuery
} from "@mui/material";
import { FilterList, Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  SelectedZoneOrWorkplaceType,
  setCurrentFloors,
  setIsSelected,
  setSelectedZoneOrWorkplace
} from "../../features/Reports/slices/report.slice";
import FloorSelector from "./floor-selector.component";
import { PricingModel } from "../../features/Login/typings/login.types";
import EquipmentsFilter from "../../features/Reports/components/equipment-filter.component";
import EquipmentCategoryFilter from "../../features/Reports/components/equipment-category-filter.component";
import PlaceCategoryFilter from "../../features/Reports/components/place-category-filter.component";
import { AreaChartsReport } from "./area-chart.component";
import WeekdayBars from "./weekday-report-chart.component";
import { FloorPlanMemo, ReportEntities } from "../../features/Reports/typings/reports.types";
import { currentEntityData } from "./functions/facility-report.functions";
import FacilityReportSVG from "./facility-report-svg.component";
import CapacityPerHourReport from "./capacity-per-hour-report.component";
import FloorSingleSelector from "./floor-single-selector.component";
import DateAndTypeSelector from "./DateAndTypeSelector/date-and-type-selector.component";

interface OwnProps {
  zoneBookingReport: boolean;
  floorData: FloorPlanMemo;
}

type Props = OwnProps;

const FacilityReportDialog: FunctionComponent<Props> = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:400px)");
  const {
    capacitySelectedFloors,
    currentFloors,
    capacitySelectedWorkplaceOrZone,
    selectedZoneOrWorkplace,
    selectableEntities,
    selectedLocation
  } = useSelector((state: RootState) => state.report);
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);
  const [anchorElDevice, setAnchorElDevice] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElPlace, setAnchorElPlace] = React.useState<HTMLButtonElement | null>(null);
  const [anchorElDeviceCategories, setAnchorElDeviceCategories] =
    React.useState<HTMLButtonElement | null>(null);
  const openDevice = Boolean(anchorElDevice);
  const openPlace = Boolean(anchorElPlace);
  const openDeviceCategories = Boolean(anchorElDeviceCategories);

  const getFloorNameById = (id: number) => {
    // returns selected one's name when selecting place or zone
    if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
      return selectedZoneOrWorkplace.name;
    }

    const entities = selectableEntities as ReportEntities[];
    if (!entities) return "";

    return (
      entities
        .find((entity: { id: number }) => entity.id === selectedLocation?.id)
        ?.floors?.find(floor => floor.inventoryId === id)?.name || ""
    );
  };

  const entityData = currentEntityData(
    selectedZoneOrWorkplace,
    capacitySelectedWorkplaceOrZone,
    currentFloors,
    capacitySelectedFloors
  );

  const capacityObjectsMapped = entityData?.map(object => ({
    id: object?.id || 1,
    data: object?.capacity,
    label: getFloorNameById(object?.id || 1)
  }));

  return (
    <>
      {currentFloors.some(floor => floor.open) && (
        <Dialog
          fullScreen
          onClose={() => dispatch(setCurrentFloors([]))}
          aria-labelledby="simple-dialog-title"
          open={currentFloors.some(floor => floor.open)}
          PaperProps={{ sx: { backgroundImage: "none" } }}
        >
          <Grid container style={{ width: "100%", height: "auto", padding: "2%" }}>
            <Grid
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                flexWrap: "wrap"
              }}
            >
              {/* floor selector */}
              <div style={{ marginBottom: "1.5em" }}>
                <FloorSingleSelector zoneBooking={props.zoneBookingReport} />
              </div>

              {/* date, place/zone type selector */}
              <DateAndTypeSelector zoneBooking={props.zoneBookingReport} />

              {/* close dialog */}
              <IconButton
                data-testid="close-report-dialog"
                sx={{ position: "absolute", top: "20px", right: "20px" }}
                onClick={() => {
                  dispatch(setCurrentFloors([]));
                  dispatch(setIsSelected(false));
                  dispatch(
                    setSelectedZoneOrWorkplace({
                      id: [],
                      name: [],
                      type: SelectedZoneOrWorkplaceType.ZONE,
                      open: false,
                      floorInventoryId: 0,
                      bookingType: undefined
                    })
                  );
                }}
                size="large"
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
              {/* filter group - device, device category, place category, equipment, etc. */}
              {pricingModels.includes(PricingModel.ENTERPRISE) && (
                <Grid
                  container
                  direction="row"
                  justifyContent={"flex-start"}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Button
                    onClick={e => setAnchorElDevice(e.currentTarget)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10, marginBottom: 5 }}
                    startIcon={<FilterList />}
                  >
                    {t("Devices")}
                  </Button>
                  <Button
                    onClick={e => setAnchorElDeviceCategories(e.currentTarget)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10, marginBottom: 5 }}
                    startIcon={<FilterList />}
                  >
                    {t("Device Categories")}
                  </Button>
                  <Button
                    onClick={e => setAnchorElPlace(e.currentTarget)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10, marginBottom: 5 }}
                    startIcon={<FilterList />}
                  >
                    {t("Place Categories")}
                  </Button>
                  <Popover
                    open={openDevice}
                    anchorEl={anchorElDevice}
                    onClose={() => setAnchorElDevice(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box sx={{ padding: "10px", width: 200 }}>
                      <EquipmentsFilter />
                    </Box>
                  </Popover>
                  <Popover
                    open={openDeviceCategories}
                    anchorEl={anchorElDeviceCategories}
                    onClose={() => setAnchorElDeviceCategories(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box sx={{ padding: "10px", width: 200 }}>
                      <EquipmentCategoryFilter />
                    </Box>
                  </Popover>
                  <Popover
                    open={openPlace}
                    anchorEl={anchorElPlace}
                    onClose={() => setAnchorElPlace(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    <Box sx={{ padding: "10px", width: 200 }}>
                      <PlaceCategoryFilter />
                    </Box>
                  </Popover>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              justifyContent={"flex-start"}
              direction={"row"}
              sx={{ margin: "10px 0px 0px 0px", width: "100%" }}
              data-testid="facility-report-and-floor-selector-grid"
            >
              {!!currentFloors[0].id &&
              props.floorData[currentFloors[0].id] &&
              entityData &&
              entityData[0]?.capacity?.capacityPerformancePerWorkplace ? (
                <>
                  <FacilityReportSVG
                    key={props.floorData[currentFloors[0].id].floorData.floorInventoryId}
                    floorData={props.floorData}
                    zoneBookingReport={props.zoneBookingReport}
                  />
                  {currentFloors.length === 1 && (
                    <Grid item xs={12} md={6}>
                      <Box
                        style={{
                          height: 500,
                          marginLeft: 10,
                          border: `1px solid rgba(0, 0, 0, 0.42)`,
                          borderRadius: 4,
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center"
                        }}
                      >
                        <Alert severity="info">
                          <Grid container direction={"column"}>
                            {t("You can add a floor to compare to")}
                            <FloorSelector zoneBooking={props.zoneBookingReport} />
                          </Grid>
                        </Alert>
                      </Box>
                    </Grid>
                  )}
                </>
              ) : (
                <Skeleton variant="rectangular" width={"100%"} height={"78vh"} />
              )}
            </Grid>
            {capacityObjectsMapped && (
              <Grid item data-testid="area-charts-report-grid" md={12} style={{ width: "100%" }}>
                <AreaChartsReport capacityObjects={capacityObjectsMapped} />
              </Grid>
            )}
            <Grid item md={12} sx={{ width: "100%" }} data-testid="capacity-per-hour-report-grid">
              {entityData &&
                entityData.length > 0 &&
                selectableEntities &&
                capacityObjectsMapped &&
                capacitySelectedFloors && (
                  <Fade
                    mountOnEnter
                    unmountOnExit
                    in={!!entityData[0].capacity?.capacityPerformancePerDay}
                  >
                    <Grid container direction={!isMobile ? "row" : "column"} wrap={"nowrap"}>
                      <CapacityPerHourReport
                        width={isMobile ? "95%" : undefined}
                        capacityObjects={capacityObjectsMapped}
                      />
                      <WeekdayBars width={isMobile ? "95%" : undefined} data={entityData} />
                    </Grid>
                  </Fade>
                )}
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default FacilityReportDialog;
