import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ColorOverlayFilter } from "pixi-filters";
import BoundingBox from "../../Domain/Types/BoundingBox.type";
import { PlaceVariant } from "../../Domain/Types/FloorPlan/PlaceVariant.type";
import { ParkPlaceSprite } from "./Sprite/ParkPlaceSprite";
import { WorkPlaceSprite } from "./Sprite/WorkPlaceSprite";
import { ToolSelection } from "../Views/CreateFloorPlanView/CreateFloorPlanView";

interface Props {
  boundingBox: BoundingBox;
  isSelected?: boolean;
  colorOverlay?: number;
  isHighlighted?: boolean;
  isSelectable: boolean;
  variant: PlaceVariant;
  placeTypeId: number | undefined;
  placeScale: number;
  icon?: string;
  tool?: ToolSelection;
}

export default function PlaceSprite({
  boundingBox,
  isSelected = false,
  colorOverlay,
  isHighlighted,
  isSelectable,
  variant,
  placeTypeId = 1,
  placeScale,
  icon,
  tool
}: PropsWithChildren<Props>) {
  //
  // Color
  //
  const selectedColor = 0x0027eb;
  const highlightedColor = 0xeeee00;
  const defaultDeskColor = 0x28b869;
  const occupiedDeskColor = 0xff0000;
  const disabledDeskColor = 0xd2dae1;
  const forbiddenDeskColor = 0xd2dae1; // 0xca61cb;
  const tempDeskColor = 0xdddd56;

  const selectedColorFilter = [new ColorOverlayFilter(selectedColor, 0.4)];
  const [selectedFilter, setSelectedFilter] = useState<ColorOverlayFilter[]>([]);

  const variantColor = (value: PlaceVariant) => {
    switch (value) {
      case PlaceVariant.AVAILABLE:
        return defaultDeskColor;
      case PlaceVariant.OCCUPIED:
        return occupiedDeskColor;
      case PlaceVariant.WEAK:
        return disabledDeskColor;
      case PlaceVariant.FORBIDDEN:
        return forbiddenDeskColor;
      case PlaceVariant.TEMP:
        return tempDeskColor;
    }
  };

  // Color Overlay Filter to show capacity rates
  const capacityFilters = useMemo(() => {
    if (!colorOverlay) return [];
    return [new ColorOverlayFilter(colorOverlay, 0.6)];
  }, [colorOverlay]);

  // apply color filter for places within the selected zone
  const highlightFilter = isHighlighted ? [new ColorOverlayFilter(highlightedColor, 0.2)] : [];

  useEffect(() => {
    if (isSelected) {
      setSelectedFilter(selectedColorFilter);
      return;
    }
    setSelectedFilter([]);
  }, [isSelected]);

  const filterGroup = [...capacityFilters, ...highlightFilter, ...selectedFilter];

  return (
    <CreatePlaceSprite
      placeTypeId={placeTypeId}
      placeScale={placeScale}
      boundingBox={boundingBox}
      filterGroup={filterGroup}
      variantColor={variantColor(variant)}
      variant={variant}
      isSelectable={isSelectable}
      tool={tool}
      icon={icon}
    />
  );
}

type CreatePlaceSpriteProps = {
  placeTypeId: number;
  placeScale: number;
  boundingBox: BoundingBox;
  filterGroup: ColorOverlayFilter[];
  variantColor: number;
  variant: PlaceVariant;
  isSelectable: boolean;
  tool?: ToolSelection;
  icon?: string;
};

export function CreatePlaceSprite({
  placeTypeId,
  placeScale,
  boundingBox,
  filterGroup,
  variantColor,
  variant,
  isSelectable,
  tool,
  icon
}: CreatePlaceSpriteProps) {
  // this sprite is shared for park place and electric charging station
  const parkPlaceSprite = (
    <ParkPlaceSprite
      placeTypeId={placeTypeId}
      boundingBox={boundingBox}
      filterGroup={filterGroup}
      variantColor={variantColor}
      variant={variant}
      placeScale={placeScale}
      isSelectable={isSelectable}
      icon={icon}
      tool={tool}
    />
  );

  const workPlaceSprite = (
    <WorkPlaceSprite
      boundingBox={boundingBox}
      filterGroup={filterGroup}
      variantColor={variantColor}
      variant={variant}
      placeScale={placeScale}
      isSelectable={isSelectable}
      tool={tool}
    />
  );

  switch (placeTypeId) {
    case 1: // normal workplace type
      return workPlaceSprite;
    case 2: // car parking place
      return parkPlaceSprite;
    case 4: // electric charging station
      return parkPlaceSprite;
    default:
      return null;
  }
}
