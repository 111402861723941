import { TextField, Autocomplete, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { setEquipment, setUserEquipment } from "../slices/booking.slice";
import { RootState } from "../../../app/rootReducer";
import { IZoneCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { MultiselectOption } from "../../Reports/typings/reports.types";

type Option = {
  id: number;
  name: string;
};

type P = {
  categories: IZoneCategory[];
  onSelectedChange: (selected: MultiselectOption[]) => void;
  isOpenCategoryFilter: boolean;
};

const ZoneCategoryFilter: React.FC<P> = ({
  categories,
  onSelectedChange,
  isOpenCategoryFilter
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bookingStateZoneCategories = useSelector(
    (state: RootState) => state.booking.inputs.equipment?.zoneCategoryIds
  );

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<Option[]>([]);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (categories.length > 0) {
      setOptions(categories);
      // checking preferredCategory and appling them at first => skip for zone category

      if (bookingStateZoneCategories !== undefined) {
        setSelected(
          categories.filter(category => bookingStateZoneCategories.includes(category.id))
        );
      }

      setLoading(false);
    }
  }, [categories]);

  // Update booking state on options change
  useEffect(() => {
    if (!loading) {
      const newSelected = selected.map(s => s.id);
      if (JSON.stringify(bookingStateZoneCategories) !== JSON.stringify(newSelected)) {
        const zoneCategoryIds = selected.map(s => s.id);
        dispatch(
          setEquipment({
            zoneCategoryIds
          })
        );
        dispatch(
          setUserEquipment({
            userIndex: 0,
            equipment: {
              zoneCategoryIds
            }
          })
        );
      }
    }
  }, [dispatch, selected, loading, bookingStateZoneCategories]);

  return (
    <Autocomplete
      sx={{
        width: "15rem",
        padding: "5px 0 20px 0",
        display: isOpenCategoryFilter ? "inherit" : "none"
      }}
      id="zone-category-filter"
      data-testid="zone-category-filter-autocomplete"
      disabled={loading}
      multiple
      value={selected}
      limitTags={2}
      onChange={(event, newValue) => {
        setSelected(newValue);
        onSelectedChange(newValue);
      }}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          data-testid="zone-category-filter-field"
          {...params}
          variant="standard"
          label={t("Zone Categories")}
        />
      )}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.name} key={option.id} />
        ));
      }}
    />
  );
};

export default ZoneCategoryFilter;
