import { ProviderContext } from "notistack";
import { TFunction } from "i18next";

export const addDevicesOptions = ({
  value,
  availableDevices,
  currentDevices,
  setCurrentDevices,
  setAvailableDevices,
  enqueueSnackbar,
  t
}: {
  value: any[];
  availableDevices: any[];
  currentDevices: any[];
  setAvailableDevices: (a: any[]) => void;
  setCurrentDevices: (c: any[]) => void;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.id);

  const selected = availableDevices?.filter(device => valueIds.includes(device.id));
  const newAvailable = availableDevices?.filter(device => !valueIds.includes(device.id));

  if (selected.length === 0) {
    return enqueueSnackbar(t("_errorAssigningWorkplaceDevice"), { variant: "error" });
  }

  const newCurrent = [...selected, ...currentDevices];

  setCurrentDevices(newCurrent);
  setAvailableDevices(newAvailable);
};

export const clearDevicesOptions = ({
  availableDevices,
  currentDevices,
  setCurrentDevices,
  setAvailableDevices
}: {
  availableDevices: any[];
  currentDevices: any[];
  setAvailableDevices: (a: any[]) => void;
  setCurrentDevices: (c: any[]) => void;
}) => {
  const newAvail = [...currentDevices, ...availableDevices];

  setCurrentDevices([]);
  setAvailableDevices(newAvail);
};

export const removeDevicesOptions = ({
  value,
  availableDevices,
  currentDevices,
  setAvailableDevices,
  setCurrentDevices,
  enqueueSnackbar,
  t
}: {
  value: any[];
  availableDevices: any[];
  currentDevices: any[];
  setAvailableDevices: (a: any[]) => void;
  setCurrentDevices: (c: any[]) => void;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
}) => {
  const valueIds = value.map(val => val.id);

  const remainCurr = currentDevices.filter(device => valueIds.includes(device.id));
  const toAvailable = currentDevices.filter(device => !valueIds.includes(device.id));

  if (toAvailable.length === 0)
    return enqueueSnackbar(t("_errorAssigningWorkplaceDevice"), { variant: "error" });

  const newAvail = [...toAvailable, ...availableDevices];
  const newCurr = [...remainCurr];

  setCurrentDevices(newCurr);
  setAvailableDevices(newAvail);
};
