import { DeviceHub } from "@mui/icons-material";
import {
  Autocomplete,
  Fade,
  Paper,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material";
import { DateTime } from "luxon";
import React, { Fragment, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";
import FormRecurrence from "./FormRecurrence/form-recurrence.component";
import {
  handleConference,
  handleSetInputs,
  initBookingTime,
  setDatesAndValidate
} from "./date-time.functions";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { updateInitialBookingTimeByNow } from "./booking.utils";

/**
 * @description Component to select date and time for the booking process in two modes single or schedule builder.
 */

type Props = {
  picker: boolean;
  setValidDates: React.Dispatch<SetStateAction<boolean>>;
  validDate: boolean;
};

const FormDateTime: React.FC<Props> = ({ picker, setValidDates, validDate }) => {
  const { inputs } = useSelector((state: RootState) => state.booking);
  const {
    settings,
    userInformation: { sub }
  } = useSelector((state: RootState) => state.login);
  const [message, setMessage] = useState<string>();
  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  /**
   * @description Takes the input Dates and Times and verifies them according to business logic.
   * @version 0.1.0
   */
  useEffect(() => {
    // Verify Inputs whenever inputs change
    setDatesAndValidate(inputs, setMessage, setValidDates);
  }, [inputs, setValidDates, i18n.language]);

  useEffect(() => {
    handleConference(inputs, dispatch, users, setUsers, sub);
  }, [inputs, dispatch, users, setUsers, sub]);

  useEffect(() => {
    handleSetInputs(inputs, dispatch, picker, settings.standardEndTime);
  }, [inputs, dispatch, picker, settings.standardEndTime]);

  useEffect(() => {
    const todayDate = DateTime.now().toFormat("yyyy-MM-dd");

    // Set default times initially if they are not set
    if (!inputs.bookingStart && !inputs.bookingEnd) {
      const [initBookingStart, initBookingEnd] = initBookingTime(
        settings.standardStartTime,
        settings.standardEndTime
      );

      dispatch(
        setInputs({
          bookingStart: initBookingStart,
          bookingEnd: initBookingEnd,
          bookingFrom: todayDate,
          bookingTo: todayDate,
          specificDays: [todayDate]
        })
      );
    }
  }, [
    dispatch,
    inputs.bookingEnd,
    inputs.bookingStart,
    settings.standardEndTime,
    settings.standardStartTime
  ]);

  useEffect(() => {
    // compare the booking inputs date/time with now/day and initialize the booking time
    updateInitialBookingTimeByNow(inputs, settings, dispatch);
  }, [inputs.bookingFrom]);

  useEffect(() => {
    if (inputs.mode === BookingType.PLACEZONE) {
      dispatch(
        setInputs({
          bookingFrom: DateTime.now().plus({ days: 1 }).toFormat("yyyy-MM-dd"),
          bookingTo: DateTime.now().plus({ days: 7 }).toFormat("yyyy-MM-dd")
        })
      );
    }
  }, [dispatch, inputs.mode]);

  return (
    <Fragment>
      {inputs.mode === "conferencezone" && (
        <Tooltip
          enterTouchDelay={0}
          title={t("The best conference zone for you and your guests will be chosen.") as string}
          aria-label="add"
          sx={{ display: "none" }}
        >
          <ToggleButton
            style={{
              marginTop: 10,
              background: inputs.activityBasedBooking
                ? theme.palette.primary.main
                : theme.palette.background.default,
              color: inputs.activityBasedBooking ? "#ffffff" : theme.palette.text.primary
            }}
            onClick={() =>
              dispatch(
                setInputs({
                  activityBasedBooking: !inputs.activityBasedBooking
                })
              )
            }
            value="auto"
            selected={inputs.activityBasedBooking}
            aria-label="left aligned"
          >
            {t("Activity Based Booking")} <DeviceHub />
          </ToggleButton>
        </Tooltip>
      )}

      <Fade in={true} mountOnEnter unmountOnExit>
        <Paper
          data-testid={"dateTime"}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            padding: theme.spacing(3),
            marginRight: theme.spacing(3),
            margin: "2% 0 2% 0",
            width: "100%",
            backgroundImage: "none"
          }}
        >
          <Typography variant={"h5"}>{t("Date and Time")}</Typography>
          <FormRecurrence />
        </Paper>
      </Fade>
      {!validDate && (
        <Typography
          sx={{
            textAlign: "left",
            color: theme.palette.error.main,
            position: "relative"
          }}
          variant={"body2"}
        >
          {message}
        </Typography>
      )}
      {inputs.mode === "conferencezone" && inputs.activityBasedBooking && (
        <>
          <Autocomplete
            multiple
            disableCloseOnSelect
            id="tags-outlined9"
            isOptionEqualToValue={(option: UserOrEmailMapping, value: UserOrEmailMapping) =>
              option.email === value.email
            }
            getOptionLabel={getOptionLabelOfUserOrEmailMapping}
            data-testid={"autocomplete"}
            options={users}
            filterSelectedOptions
            style={{ width: "100%" }}
            onChange={(event, values) => {
              event.persist();
              dispatch(setInputs({ zoneAccess: values }));
            }}
            value={inputs.zoneAccess || []}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={t("Choose your colleagues")}
              />
            )}
          />

          <TextField
            margin="dense"
            id="name"
            label={t("Conference Subject")}
            type="text"
            value={inputs.description || ""}
            onChange={(event: any) => dispatch(setInputs({ description: event.target.value }))}
            fullWidth
          />
        </>
      )}
    </Fragment>
  );
};

export default FormDateTime;
