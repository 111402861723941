import { Grid, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { GetBookingLocationParams } from "../../features/Booking-Form/thunks/booking.thunks";
import { LocationAvailability } from "../../features/Booking-Form/typings/booking-location";
import { LocationInputs } from "../../features/Booking-Form/typings/inputs-location";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { setSettings } from "../../features/Login/slices/login.slice";
import {
  useRemoteFetchLocationAvailability,
  useRemoteFetchLocations
} from "../../hooks/Remote/Location/useRemoteFetchLocations";
import { useRemoteSetUserSettings } from "../../hooks/Remote/User/useRemoteSetUserSettings";
import { transformBookingUsers } from "../BookingForm/form-confirm.functions";
import { LocationCardMT } from "../LocationCard/LocationCardNew/LocationCardMT";
import { LocationNonPrefferedCard } from "../LocationCard/LocationCardNonPreferred/location-card-non-preferred.component";

type P = {
  locationToState: (inputs: LocationInputs) => void;
};

/**
 * @description Component that renders the possible booking locations.
 * @version 0.1.0
 */

export const LocationsView = forwardRef<HTMLDivElement, P>(({ locationToState }, ref) => {
  const [allLocations, setAllLocations] = useState<LocationInventory[]>();
  const [bookingLocations, setBookingLocations] = useState<LocationAvailability[]>([]);
  const {
    userInformation: { sub },
    settings
  } = useSelector((state: RootState) => state.login);

  const { inputs } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const { mutateAsync: fetchAllLocations, isLoading } = useRemoteFetchLocations();
  const { mutateAsync: fetchLocationAvailabilities, isLoading: bookingLocationIsLoading } =
    useRemoteFetchLocationAvailability();

  const { mutate: updateUserSettings, status: statusSetUserSettings } = useRemoteSetUserSettings();

  async function fetchAndAssignLocations() {
    if (inputs.usersBookedFor && !inputs.activityBasedBooking) {
      const bookingUser = transformBookingUsers(
        inputs.usersBookedFor,
        sub,
        inputs,
        settings.timezone
      );

      const bookingFilterDto: GetBookingLocationParams = {
        users: bookingUser,
        startTime: inputs.bookingStart as string,
        endTime: inputs.bookingEnd as string,
        startDate: inputs.bookingFrom as string,
        endDate: inputs.bookingTo as string,
        bookingType: inputs.bookingType,
        weekdays: inputs.weekdays,
        frequence: inputs.frequence,
        interval: inputs.interval,
        specificDays: inputs.specificDays,
        bookingMonthDay: inputs.bookingMonthDay,
        bookingYearDay: inputs.bookingYearDay
      };

      fetchLocationAvailabilities(bookingFilterDto).then(r => {
        setBookingLocations(r);
      });
      fetchAllLocations(undefined).then(r => setAllLocations(r));
    }
  }

  // reload list when location is enabled
  useEffect(() => {
    if (statusSetUserSettings === "success") fetchAndAssignLocations().then();
  }, [statusSetUserSettings]);

  // load locations on init
  useEffect(() => {
    if (!inputs || !sub || !settings.timezone) return;
    fetchAndAssignLocations().then();
  }, [
    sub,
    inputs.activityBasedBooking,
    inputs.isMobileWorking,
    inputs.bookingFrom,
    inputs.bookingTo,
    inputs.bookingStart,
    inputs.bookingEnd,
    inputs.bookingType,
    inputs.usersBookedFor,
    inputs.isMobileWorking,
    inputs.weekdays,
    inputs.frequence,
    inputs.interval,
    inputs.specificDays,
    inputs.bookingMonthDay,
    inputs.bookingYearDay,
    settings.timezone
  ]);

  // if there is an external user, only display card when it is not a mobile working card
  const activeCardsToDisplay = useMemo(
    () =>
      bookingLocations?.filter(loc => {
        const hasExternalUsers = inputs.usersBookedFor?.some(user => user.isExternal === true);
        if (hasExternalUsers) return !loc.isMobileWorking;
        return true;
      }),
    [inputs.usersBookedFor, bookingLocations]
  );
  // cards that cannot be selected
  const inactiveCardsToDisplay = useMemo(
    () =>
      allLocations
        ?.filter(
          loc =>
            // ids match or is mobile working
            !bookingLocations.some(locOld => locOld.id === loc.id || loc.isMobileWorking)
        )
        .filter(
          loc =>
            // is booking currently allowed
            loc.allowForBooking &&
            DateTime.fromISO(loc.bookingAllowedAt as string).valueOf() <=
              DateTime.fromISO(inputs.bookingFrom as string).valueOf()
        ),
    [allLocations, inputs.bookingFrom, bookingLocations]
  );

  function handleEnable(locationToEnable: LocationInventory) {
    const preferredLocations = settings.preferredLocations?.concat(locationToEnable.id.toString());
    const updatedSettings = {
      ...settings,
      preferredLocations
    };
    // update settings so they are updated when returning to first screen
    dispatch(
      setSettings({
        settings: updatedSettings
      })
    );
    updateUserSettings(updatedSettings);
  }

  return (
    <Grid container direction={"row"} wrap={"wrap"} sx={{ minHeight: "15px" }} ref={ref}>
      <>
        {isLoading || (bookingLocationIsLoading && <LocationPlaceholder />)}
        {!isLoading &&
          !bookingLocationIsLoading &&
          activeCardsToDisplay &&
          activeCardsToDisplay.map(loc => (
            <div key={loc.id}>
              <LocationCardMT key={loc.id} location={loc} locationToState={locationToState} />
            </div>
          ))}
        {!isLoading &&
          !bookingLocationIsLoading &&
          bookingLocations &&
          inactiveCardsToDisplay &&
          inactiveCardsToDisplay.map(nonPreferredLoc => (
            <div key={nonPreferredLoc.uid}>
              <LocationNonPrefferedCard
                key={nonPreferredLoc.uid}
                location={nonPreferredLoc}
                onClickEnable={() => handleEnable(nonPreferredLoc)}
              />
            </div>
          ))}
      </>
    </Grid>
  );
});

const LocationPlaceholder = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <>
      <Skeleton
        ref={ref}
        variant="rectangular"
        style={{ margin: "10px 15px 15px 0px" }}
        width={400}
        height={160}
      />
      <Skeleton
        ref={ref}
        variant="rectangular"
        style={{ margin: "10px 15px 15px 0px" }}
        width={400}
        height={160}
      />
    </>
  );
});

export default LocationsView;
