import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Datepicker } from "@mobiscroll/react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { useDispatch } from "../../app/helpers";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { NotSerializedBookingInputs } from "../../features/Booking-Form/typings/booking-inputs";

export type BookingTimeTemp = {
  bookingStart: string | undefined;
  bookingEnd: string | undefined;
};

type P = {
  inputs: NotSerializedBookingInputs;
  setIsDateOpen: (o: boolean) => void;
};

export const FormEditTimeDialog: React.FC<P> = props => {
  const { inputs, setIsDateOpen } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { timeFormat } = useTimeFormat();

  const [tempTime, setTempTime] = useState<BookingTimeTemp>({
    bookingStart: undefined,
    bookingEnd: undefined
  });

  useEffect(() => {
    setTempTime({
      bookingStart: inputs.bookingStart ?? "08:00",
      bookingEnd: inputs.bookingEnd ?? "17:00"
    });
  }, [inputs.bookingStart, inputs.bookingEnd]);

  return (
    <Grid container data-testid="edit-schedule-time-modal-container">
      <Grid container data-testid="edit-schedule-time-modal-header">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography align={"center"} variant={"h5"}>
            {t("Time")}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: "right", placeSelf: "center" }}>
          <Button
            variant="text"
            color="inherit"
            data-testid={"edit-schedule-time-modal-close"}
            onClick={() => setIsDateOpen(false)}
          >
            <Close />
          </Button>
        </Grid>
      </Grid>

      <Grid container sx={{ justifyContent: "center" }}>
        <Datepicker
          select="range"
          data-testid={"timePicker"}
          controls={["time"]}
          timeFormat={timeFormat}
          onChange={picker => {
            if (picker.value && Array.isArray(picker.value)) {
              if (picker.value[0]) {
                const bs = DateTime.fromJSDate(picker.value[0] as Date).toFormat("HH:mm");
                setTempTime((prevState: BookingTimeTemp) => ({
                  ...prevState,
                  bookingStart: bs
                }));
              }
              if (picker.value[1]) {
                const be = DateTime.fromJSDate(picker.value[1] as Date).toFormat("HH:mm");
                setTempTime((prevState: BookingTimeTemp) => ({
                  ...prevState,
                  bookingEnd: be
                }));
              }
            }
          }}
          value={[tempTime.bookingStart, tempTime.bookingEnd]}
          stepMinute={15}
        />

        <Button
          data-testid={"edit-schedule-time-modal-confirm"}
          onClick={() => {
            dispatch(
              setInputs({
                bookingStart: tempTime.bookingStart,
                bookingEnd: tempTime.bookingEnd
              })
            );
            setIsDateOpen(false);
          }}
        >
          {t("Confirm")}
        </Button>
      </Grid>
    </Grid>
  );
};
