import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton, styled } from "@mui/material";

interface StyledInputDialogHeaderIconButtonProps {
  onClose: () => void;
}

export const StyledInputDialogHeaderIconButton = styled(
  (props: StyledInputDialogHeaderIconButtonProps) => (
    <IconButton
      aria-label="close"
      data-testid="calendarView-dialogTitle-close-btn"
      size="large"
      onClick={props.onClose}
      {...props}
    >
      <Close />
    </IconButton>
  )
)(({ theme }) => ({
  color: theme.palette.grey[500],
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1)
}));

interface StyledInputDialogHeaderTitleProps {
  id: string;
  children: React.ReactNode;
}

export const StyledInputDialogHeaderTitle = styled((props: StyledInputDialogHeaderTitleProps) => (
  <DialogTitle data-testid={props.id} {...props}>
    {props.children}
  </DialogTitle>
))(({ theme }) => ({
  padding: theme.spacing(2),
  margin: 0
}));
