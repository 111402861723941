import React, { MutableRefObject } from "react";
import { FilterList, KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";

type P = {
  showDeviceFilters: boolean;
  showPlaceCategoryFilter: boolean;
  showZoneCategoryFilter: boolean;
  setAnchorElDevice: (el: HTMLButtonElement | null) => void;
  setAnchorElDeviceCategories: (el: HTMLButtonElement | null) => void;
  isOpenCategoryFilter: boolean;
  setIsOpenCategoryFilter: (o: boolean) => void;
  categoryButtonRef: MutableRefObject<null>;
};

const BookingFiltersButtons: React.FC<P> = ({
  showDeviceFilters,
  showPlaceCategoryFilter,
  showZoneCategoryFilter,
  setAnchorElDevice,
  setAnchorElDeviceCategories,
  isOpenCategoryFilter,
  setIsOpenCategoryFilter,
  categoryButtonRef
}) => {
  const { t } = useTranslation();

  const showCategoryFilter = showPlaceCategoryFilter || showZoneCategoryFilter;

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}
        data-testid="booking-filters-box"
        ref={categoryButtonRef}
      >
        {showCategoryFilter && (
          <Button
            onClick={() => setIsOpenCategoryFilter(!isOpenCategoryFilter)}
            variant="contained"
            color="primary"
            sx={{ mr: 1.5, mb: 1, mt: 1 }}
            startIcon={isOpenCategoryFilter ? <KeyboardArrowUp /> : <FilterList />}
            data-testid="booking-categories-btn"
          >
            {t("Categories")}
          </Button>
        )}
        {showDeviceFilters && (
          <Button
            onClick={e => setAnchorElDevice(e.currentTarget)}
            sx={{ mr: 1.5, mb: 1, mt: 1 }}
            data-testid="booking-categories-devices-btn"
            startIcon={<FilterList />}
            variant="contained"
            color="primary"
          >
            {t("Devices")}
          </Button>
        )}
        {showDeviceFilters && (
          <Button
            data-testid="booking-categories-devices-category-btn"
            sx={{ mr: 1.5, mb: 1, mt: 1 }}
            startIcon={<FilterList />}
            variant="contained"
            color="primary"
            onClick={e => setAnchorElDeviceCategories(e.currentTarget)}
          >
            {t("Device Categories")}
          </Button>
        )}
      </Box>
    </>
  );
};

export default BookingFiltersButtons;
