import i18n from "i18next";
import { DateTime } from "luxon";
import React, { Dispatch, SetStateAction } from "react";
import { AppDispatch } from "../../app/store";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import {
  Frequence,
  NotSerializedBookingInputs
} from "../../features/Booking-Form/typings/booking-inputs";
import { getAllUsers } from "../../utils/axios-requests";
import { checkEndBeforeStart, checkStartInPast } from "../../utils/utilities";

export const setDatesAndValidate = (
  inputs: NotSerializedBookingInputs,
  setMessage: Dispatch<SetStateAction<string | undefined>>,
  setValidDates: React.Dispatch<SetStateAction<boolean>>
) => {
  // the error must be instanced here to grant proper translation
  // a better solution would be to make the translation when rendering the message in setMessage
  // TODO only set the key and translate the message where it is rendered
  const messages = {
    error: {
      endBeforeStart: i18n.t("_endBeforeStart"),
      startInPast: i18n.t("_startInPast"),
      missing: i18n.t("_missing"),
      noWeekdays: i18n.t("_noWeekdays"),
      duration: i18n.t("_tooLongBooking"),
      selectedSpecific: i18n.t("_selectSpecificDates")
    }
  };

  if (!inputs.bookingStart || !inputs.bookingEnd || !inputs.bookingFrom || !inputs.bookingTo) {
    setMessage(messages.error.missing);
  } else if (checkStartInPast(inputs.bookingFrom, inputs.bookingStart)) {
    setValidDates(false);
    setMessage(messages.error.startInPast);
  } else if (
    checkEndBeforeStart(
      inputs.bookingFrom,
      inputs.bookingStart,
      inputs.bookingTo,
      inputs.bookingEnd
    )
  ) {
    setMessage(messages.error.endBeforeStart);
    setValidDates(false);
  } else if (
    DateTime.fromISO(inputs.bookingFrom).valueOf() !=
      DateTime.fromISO(inputs.bookingTo).valueOf() &&
    !Object.values(inputs.weekdays).includes(true)
  ) {
    // Using schedule builder ("from" is the same as "to" for single booking)
    setValidDates(false);
    setMessage(messages.error.noWeekdays);
  } else if (
    inputs.frequence === Frequence.SPECIFIC &&
    (!inputs.specificDays || inputs.specificDays.length <= 0)
  ) {
    setValidDates(false);
    setMessage(messages.error.selectedSpecific);
  } else {
    setValidDates(true);
  }
};

export const handleConference = (
  inputs: NotSerializedBookingInputs,
  dispatch: AppDispatch,
  users: UserOrEmailMapping[],
  setUsers: Dispatch<SetStateAction<UserOrEmailMapping[]>>,
  sub: string
) => {
  if (inputs.mode === "conferencezone" && inputs.activityBasedBooking) {
    dispatch(setInputs({ zoneAccess: [] }));
    if (users.length === 0)
      getAllUsers().then(r =>
        setUsers(r.data.filter((user: { userId: string }) => user.userId !== sub))
      );
  }
};

export const handleSetInputs = (
  inputs: NotSerializedBookingInputs,
  dispatch: AppDispatch,
  picker: boolean,
  standardEndTime: string | undefined
) => {
  if (picker && inputs.bookingFrom !== inputs.bookingTo) {
    dispatch(
      setInputs({
        bookingStart: DateTime.now().plus({ minutes: 30 }).toFormat("HH:mm"),
        bookingEnd:
          standardEndTime ??
          DateTime.now().set({ hour: 17, minute: 0, second: 0 }).toFormat("HH:mm"),
        bookingFrom: DateTime.now().toFormat("yyyy-MM-dd"),
        bookingTo: DateTime.now().toFormat("yyyy-MM-dd")
      })
    );
  }
};

/**
 * Always round up start time to the next quarter (e.g. current time = 10:03 am -> start time 10:15 am
 * if users default end time is in the past (so would be start time) make end time = start time + 1h
 * if start time + 1h is tomorrow make end time 23:59
 * The red warning message that start should not be in the past should never appear as default
 */
export const initBookingTime = (bookingStart: string, bookingEnd: string): [string, string] => {
  // constant
  const defaultTimeFormat = "HH:mm";
  const localTime = DateTime.local();
  const min = calMinuteInterval(localTime);

  // local time with interval
  const localStart = localTime.set({ minute: min }).toFormat(defaultTimeFormat);
  const localEnd = localTime.plus({ hour: 1 }).set({ minute: min }).toFormat(defaultTimeFormat);

  const dateStart = DateTime.fromISO(bookingStart);
  const dateEnd = DateTime.fromISO(bookingEnd);
  const startWithInterval = dateStart
    .set({ minute: calMinuteInterval(dateStart) })
    .toFormat(defaultTimeFormat);
  const endWithInterval = dateEnd
    .set({ minute: calMinuteInterval(dateEnd) })
    .toFormat(defaultTimeFormat);

  // bookingStart <= now < bookingEnd, start only is in the past
  if (
    DateTime.fromISO(localTime.toFormat("HH:mm")) >= DateTime.fromISO(bookingStart) &&
    DateTime.fromISO(localTime.toFormat("HH:mm")) < DateTime.fromISO(bookingEnd)
  ) {
    if (localStart === endWithInterval) return [localStart, localEnd];

    return [localStart, endWithInterval];
  }

  // bookingStart < now && bookingEnd < now, both are in the past
  if (
    DateTime.fromISO(localTime.toFormat("HH:mm")) > DateTime.fromISO(bookingStart) &&
    DateTime.fromISO(localTime.toFormat("HH:mm")) > DateTime.fromISO(bookingEnd)
  ) {
    const setDay = DateTime.fromISO(localTime.toFormat("HH:mm")).plus({ hour: 1 }).day;
    const today = DateTime.fromISO(localTime.toFormat("HH:mm")).day;

    // but the case of start + 1 hour is tomorrow
    if (setDay > today) {
      return [localStart, "23:59"];
    }
    return [localStart, localEnd];
  }

  return [startWithInterval, endWithInterval];
};

// return minutes based on 15 minutes interval
export const calMinuteInterval = (time: DateTime) => Math.ceil(time.minute / 15) * 15;

// return valid time range for editing schedule time
export function validTimeRange(startDate: string) {
  const startDateWOTime = DateTime.fromISO(startDate.slice(0, 10));
  const localTimeInterval = DateTime.local()
    .set({ minute: calMinuteInterval(DateTime.local()) })
    .toFormat("HH:mm");

  return startDateWOTime.diffNow().valueOf() > 0 ? "00:00" : localTimeInterval;
}
