import React, { useEffect, useMemo, useState } from "react";
import { FormControlLabel, Grid, IconButton, Radio, RadioGroup } from "@mui/material";
import { LockOpen, TaskAlt } from "@mui/icons-material";
import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscEventcalendarView,
  MbscEventClickEvent,
  MbscEventCreateEvent,
  MbscEventDragEvent,
  MbscEventUpdateEvent,
  MbscPageLoadedEvent,
  MbscPageLoadingEvent
} from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import BookingSvgDialog from "../../Svg/svg-dialog.component";
import { EVENT_CALENDAR_GER } from "../schedule-calendar-localization";
import { ScheduleEventContent } from "../schedule-event-content.component";
import { ScheduleUnlockDialog } from "./ScheduleUnlockDialog/ScheduleUnlockDialog";
import {
  checkInvalidCreateOnSchedule,
  updateCalendarDate,
  updateTimes
} from "../schedule.functions";
import {
  BookingScheduleInterface,
  RestrictionZone
} from "../../../features/Booking-Form/typings/booking.types";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { useRemoteUnlockLock } from "../../../hooks/Remote/Unlockable/useRemoteUnlockLock";
import { BulkUnlockable } from "./ScheduleUnlockDialog/types";
import { checkIfMobileWorking } from "../ScheduleTodayBookings/schedule-today-bookings-dialog.functions";
import {
  checkIsFastUnlock,
  handleClickFastUnlock
} from "./ScheduleUnlockDialog/functions/schedule-unlock.functions";
import { handleClickFastCheckin } from "./ScheduleCheckinDialog/functions/schedule-checkin.functions";
import { ScheduleCheckinDialog } from "./ScheduleCheckinDialog/ScheduleCheckinDialog";
import { useRemoteCheckin } from "../../../hooks/Remote/Checkin/useRemoteCheckin";
import { attemptCheckin, handleCheckinResponse } from "../../../features/Checkin/functions/checkin";
import { ScheduleCreateOnCalendarDialog } from "./ScheduleCreateOnCalendarDialog/ScheduleCreateOnCalendarDialog.component";
import { setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { ScheduleDataStatusIndicator } from "../../../features/Schedule/partial/ScheduleDataStatusIndicator.component";
import { ScheduleEventPopover } from "../schedule-event-popover.component";

type P = {
  monthView?: boolean;
  users?: TeamMember[];
  selectedRestriction?: RestrictionZone;
  selectedColleagues?: TeamMember[];
  deletingBookings?: boolean;
  setDeletingBookings?: (d: boolean) => void;
  selectedZone?: string;
  setSelectedAppointment?: (selectedAppointment: number) => void;
  selectedAppointments?: number[];
  schedules: BookingScheduleInterface[];
  scheduleDataStatus: "error" | "loading" | "success" | "idle";
  refetchAllSchedule: () => void;
  setCalendarDate: (d: { firstDay: string | undefined; lastDay: string | undefined }) => void;
};

const calendarWeekView: MbscEventcalendarView = {
  schedule: {
    type: "week",
    startDay: 1,
    endDay: 5
  }
};
const calendarMonthView: MbscEventcalendarView = {
  calendar: {
    type: "month"
  }
};

const ScheduleCalendar: React.FC<P> = ({
  users,
  monthView,
  setSelectedAppointment,
  selectedAppointments,
  selectedColleagues,
  deletingBookings,
  setDeletingBookings,
  schedules,
  scheduleDataStatus,
  refetchAllSchedule,
  setCalendarDate
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const {
    settings: { language, timezone },
    userInformation: {
      sub: userInformationSub,
      company: {
        meta: { checkinSettings, bookingsOnSaturdaysAllowed, bookingsOnSundaysAllowed }
      }
    }
  } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();

  // Popper
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [targetScheduleId, setTargetScheduleId] = useState<number>();
  const [selectedSchedule, setSelectedSchedule] = useState<BookingScheduleInterface>();
  const [isShowFloorDialogOpen, setIsShowFloorDialogOpen] = useState<boolean>(false);

  // Calendar
  const [calendarData, setCalendarData] = useState<MbscCalendarEvent[]>([]);
  const initialView = monthView ? "month" : "week";
  const [currentView, setCurrentView] = useState<"week" | "month">(initialView);
  const [currentLoadedEvent, setCurrentLoadedEvent] = useState<MbscPageLoadedEvent>(); // MbscPageLoadingEvent
  const initialCalView = currentView === "month" ? calendarMonthView : calendarWeekView;
  const [calView, setCalView] = useState<MbscEventcalendarView>(initialCalView);

  // Modal of Unlockable for selected unlockable schedule
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState<boolean>(false);
  // Modal of Unlockable for bulk unlockable schedule
  const [isBulkUnlockModalOpen, setIsBulkUnlockModalOpen] = useState<boolean>(false);

  // Fast Checkin for today schedule
  const [todaysCheckin, setTodaysCheckin] = useState<BookingScheduleInterface[] | undefined>(
    undefined
  );
  // Modal of checkin for today schedules
  const [isCheckinModalOpen, setIsCheckinModalOpen] = useState(false);

  // Fast Unlockable for schedule
  const [unlockableSchedules, setUnlockableSchedules] = useState<
    BookingScheduleInterface[] | undefined
  >(undefined);
  // Fast Unlockable data
  const [bulkUnlockData, setBulkUnlockData] = useState<BulkUnlockable[] | undefined>(undefined);

  // booking CreateOnCalendar Modal open
  const [isCreateOnCalendar, setIsCreateOnCalendar] = useState<{
    start: DateTime | null;
    end: DateTime | null;
    open: boolean;
  }>({ start: null, end: null, open: false });

  const targetSchedule = useMemo(() => {
    return schedules.find(s => s.id === targetScheduleId);
  }, [schedules, targetScheduleId]);

  const { isFastUnlock, isFastUnlockDoorName } = checkIsFastUnlock(unlockableSchedules);

  const { mutate: unlock, status: unlockStatus } = useRemoteUnlockLock(
    isFastUnlock,
    isFastUnlockDoorName as string
  );

  const {
    mutate: mutateCheckin,
    error: checkinError,
    status: checkinStatus,
    data: checkinRes
  } = useRemoteCheckin();

  useEffect(() => {
    // Update calendar view
    setCalView(initialCalView);
  }, [initialCalView]);

  // Update Calendar data on view changes
  const refetchData = () => {
    updateCalendarDate(currentLoadedEvent, setCalendarDate, refetchAllSchedule);
  };

  const onPageLoading = (event: MbscPageLoadingEvent) => {
    updateCalendarDate(event, setCalendarDate);
  };

  function handleClickUnlock() {
    setIsUnlockModalOpen(true);
  }

  const getCalendarEventContent = (event: MbscCalendarEventData) => {
    return (
      <ScheduleEventContent
        event={event}
        schedule={schedules.find(s => s.id === event.id)}
        selectedColleagues={selectedColleagues}
        users={users}
        selectedAppointments={selectedAppointments}
        setSelectedAppointment={setSelectedAppointment}
      />
    );
  };

  async function onEventUpdate(event: MbscEventUpdateEvent) {
    await updateTimes(
      {
        bookingId: Number(event.event.id),
        newStartTime:
          DateTime.fromJSDate(new Date(event.event.start as string)).toISO() ||
          DateTime.now().toISO(),
        newEndTime:
          DateTime.fromJSDate(new Date(event.event.end as string)).toISO() ||
          DateTime.now().toISO(),
        timezone,
        event: event
      },
      userInformationSub,
      schedules.find(s => s.id === event.event.id),
      calendarData,
      { enqueueSnackbar, setCalendarData, refetchAllSchedule }
    );
  }

  function onEventClick(event: MbscEventClickEvent) {
    if (event.event.id) {
      setTargetScheduleId(Number(event.event.id));
      setAnchorEl(event.domEvent.target);
      setPopoverOpen(true);
    }
  }

  function handleCreateByDragEnd(args: MbscEventDragEvent) {
    const bookingStartDate = DateTime.fromJSDate(args.event.start as Date);
    const bookingEndDate = DateTime.fromJSDate(args.event.end as Date);

    const isInvalid = checkInvalidCreateOnSchedule(
      bookingStartDate,
      bookingsOnSaturdaysAllowed,
      bookingsOnSundaysAllowed,
      enqueueSnackbar,
      t
    );

    if (!isInvalid) return;

    setIsCreateOnCalendar({ start: bookingStartDate, end: bookingEndDate, open: true });
    dispatch(
      setInputs({
        bookingFrom: bookingStartDate.toFormat("yyyy-MM-dd"),
        bookingTo: bookingEndDate.toFormat("yyyy-MM-dd")
      })
    );
  }

  // handle create on calendar event
  function handleCreateOnCalendar(args: MbscEventCreateEvent) {
    if (args.action !== "click") return;
    const bookingStartDate = DateTime.fromJSDate(args.event.start as Date);

    const isInvalid = checkInvalidCreateOnSchedule(
      bookingStartDate,
      bookingsOnSaturdaysAllowed,
      bookingsOnSundaysAllowed,
      enqueueSnackbar,
      t
    );

    if (!isInvalid) return;
    setIsCreateOnCalendar({ start: bookingStartDate, end: bookingStartDate, open: true });
    dispatch(
      setInputs({
        bookingFrom: bookingStartDate.toFormat("yyyy-MM-dd"),
        bookingTo: bookingStartDate.toFormat("yyyy-MM-dd")
      })
    );
  }

  const handleSingleUnlockableScheduleCheckin = (bookingId: number, bookingType: BookingType) => {
    attemptCheckin(bookingId, bookingType, checkinSettings, mutateCheckin, {
      t,
      enqueueSnackbar
    });
  };

  const handleShowFloor = (schedule: BookingScheduleInterface) => {
    setSelectedSchedule(schedule);
    setIsShowFloorDialogOpen(true);
  };

  const handleShowFloorCloseFn = () => {
    setSelectedSchedule(undefined);
    setIsShowFloorDialogOpen(false);
  };

  useEffect(() => {
    // Update calendar view
    setCalView(initialCalView);
  }, [initialCalView]);

  useEffect(() => {
    if (deletingBookings !== undefined && !deletingBookings) {
      refetchData();
    }
  }, [deletingBookings]);

  useEffect(() => {
    refetchData();
  }, [users]);

  useEffect(() => {
    if (!schedules.length) {
      setTodaysCheckin(undefined);
      setCalendarData([]);
      setUnlockableSchedules(undefined);
      return;
    }
    const schedulesConst = schedules.map(schedule => {
      return {
        id: schedule.id,
        title:
          schedule.locationName === "Mobile Working"
            ? t("Mobile Working")
            : `${schedule.locationName} ${schedule.floorName}`,
        start:
          DateTime.fromISO(schedule.startDate).setZone(timezone).toISO() || DateTime.now().toISO(),
        end: DateTime.fromISO(schedule.endDate).setZone(timezone).toISO() || DateTime.now().toISO(),
        color: schedule.color || "#6b6b6b",
        editable: !schedule.isGuest
      };
    });

    setCalendarData(schedulesConst);

    // filter todays schedule which requires check-in
    const todays = schedules.filter(
      schedule =>
        DateTime.fromISO(schedule.startDate).toFormat("yyyy MM dd") ===
          DateTime.now().toFormat("yyyy MM dd") &&
        !schedule.checkedIn &&
        !checkIfMobileWorking(schedule) &&
        schedule.checkInPeriod !== -1
    );
    if (todays.length > 0) {
      setTodaysCheckin(todays);
    } else setTodaysCheckin(undefined);

    // check if schedules have doorLock data then show the fast lock icon
    const unlockables = schedules.filter(
      schedule => schedule.doorLockData && schedule.doorLockData?.length >= 1
    );
    if (unlockables.length > 0) {
      setUnlockableSchedules(unlockables);
    } else setUnlockableSchedules(undefined);
  }, [schedules, checkinStatus, unlockStatus]);

  useEffect(() => {
    const checkinSetting = checkinSettings.find(
      s => todaysCheckin && todaysCheckin[0].bookingType === s.bookingType
    );

    handleCheckinResponse(
      {
        checkinRes,
        checkinError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: false
      },
      { t, enqueueSnackbar, refetchAllSchedule, setShowDialog: setIsCheckinModalOpen }
    );
  }, [checkinError, checkinStatus, checkinRes]);

  return (
    <>
      <Grid
        container
        sx={{ mb: 1, alignItems: "center" }}
        data-testid="schedule-calendar-top-container"
      >
        {/* schedule calendar, switch for month-view or week-view  */}
        {monthView && (
          <Grid
            item
            sx={{ alignContent: "center", ml: 0.5 }}
            data-testid="schedule-calendar-top-item-switch-monthview"
          >
            <RadioGroup
              aria-label="Views"
              style={{ flexDirection: "row" }}
              name="views"
              value={currentView}
              onChange={e => setCurrentView(e.target.value as "week" | "month")}
            >
              <FormControlLabel value="week" control={<Radio />} label={t("Work Week")} />
              <FormControlLabel value="month" control={<Radio />} label={t("Month")} />
            </RadioGroup>
          </Grid>
        )}

        <Grid
          item
          sm
          sx={{ display: "flex", justifyContent: "space-between" }}
          data-testid="schedule-calendar-top-container-btn-group"
        >
          <Grid item container data-testid="schedule-calendar-top-container-btn-items1">
            {/* when multiple checkin schedules exist */}
            {todaysCheckin && (
              <Grid item data-testid="schedule-calendar-item-multiCheckin">
                <IconButton
                  data-testid="multiCheckin-btn"
                  onClick={() =>
                    handleClickFastCheckin(
                      todaysCheckin,
                      checkinSettings,
                      setIsCheckinModalOpen,
                      mutateCheckin,
                      { t, enqueueSnackbar }
                    )
                  }
                  size="large"
                >
                  <TaskAlt />
                </IconButton>
              </Grid>
            )}

            {/* when multiple unlockable schedules exist */}
            {unlockableSchedules && (
              <Grid item data-testid="schedule-calendar-item-unlockSchedules">
                <IconButton
                  data-testid="unlock-schedule-fast-btn"
                  onClick={() =>
                    handleClickFastUnlock(
                      checkinSettings,
                      unlockableSchedules,
                      unlock,
                      handleSingleUnlockableScheduleCheckin,
                      setIsBulkUnlockModalOpen,
                      setBulkUnlockData
                    )
                  }
                  size="large"
                >
                  <LockOpen />
                </IconButton>
              </Grid>
            )}
          </Grid>

          <Grid item display={"flex"} data-testid="schedule-calendar-top-container-btn-items2">
            <Grid item>{/* where save-booking-schedule-icon button will be */}</Grid>

            {/* schedule data status indicator and button */}
            <Grid item>
              <ScheduleDataStatusIndicator
                scheduleDataStatus={scheduleDataStatus}
                refetchAllSchedule={refetchAllSchedule}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Eventcalendar
        height={900}
        firstDay={1}
        onEventClick={onEventClick}
        view={calView}
        data={calendarData}
        onPageLoaded={e => setCurrentLoadedEvent(e)} // MbscPageLoadedEvent
        onPageLoading={onPageLoading} // MbscPageLoadingEvent
        timeFormat="H:mm"
        dragToResize={currentView === "week"}
        dragToCreate={true}
        clickToCreate={"single"}
        onEventCreate={handleCreateOnCalendar}
        onEventDragEnd={handleCreateByDragEnd}
        onEventUpdate={onEventUpdate}
        {...(language === "de" ? EVENT_CALENDAR_GER : [])}
        renderScheduleEventContent={getCalendarEventContent}
        renderLabelContent={getCalendarEventContent}
      />

      <ScheduleEventPopover
        isOpen={popoverOpen}
        schedule={targetSchedule}
        allSchedules={schedules}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl}
        calendarData={calendarData}
        onCalendarDataChange={setCalendarData}
        onScheduleChange={handleShowFloor}
        selectedColleagues={selectedColleagues}
        onClickUnlock={handleClickUnlock}
        refetchAllSchedule={refetchAllSchedule}
        setDeletingBookings={setDeletingBookings}
        setCalendarData={setCalendarData}
      />

      {/** handles todays checkin schedule */}
      {isCheckinModalOpen && (
        <ScheduleCheckinDialog
          isOpen={isCheckinModalOpen}
          onClose={() => setIsCheckinModalOpen(false)}
          checkinSchedules={todaysCheckin}
          refetchAllSchedule={refetchAllSchedule}
        />
      )}

      {/** handles locking and unlocking doors on site */}
      {targetSchedule && isUnlockModalOpen && targetSchedule.doorLockData && (
        <ScheduleUnlockDialog
          unlockableData={targetSchedule.doorLockData}
          open={isUnlockModalOpen}
          onClose={() => setIsUnlockModalOpen(false)}
          title={"target"}
          bookingId={targetSchedule?.id}
          bookingType={targetSchedule.bookingType}
          checkedIn={targetSchedule.checkedIn}
          unCheckedinSchedules={undefined}
          refetchAllSchedule={refetchAllSchedule}
        />
      )}

      {/** handles unlocking bulk doors from accumulated bookings instantly */}
      {bulkUnlockData && bulkUnlockData?.length > 0 && isBulkUnlockModalOpen && (
        <ScheduleUnlockDialog
          open={isBulkUnlockModalOpen}
          onClose={() => setIsBulkUnlockModalOpen(false)}
          unlockableData={[]}
          bulkUnlockData={bulkUnlockData}
          title={"bulk"}
          bookingId={bulkUnlockData[0].bookingId}
          bookingType={bulkUnlockData[0].bookingType}
          checkedIn={(unlockableSchedules && unlockableSchedules[0].checkedIn) || false}
          unCheckedinSchedules={unlockableSchedules?.filter(s => !s.checkedIn)[0]}
          refetchAllSchedule={refetchAllSchedule}
        />
      )}

      {/* handle create on calendar dialog */}
      {isCreateOnCalendar.open && (
        <ScheduleCreateOnCalendarDialog
          open={isCreateOnCalendar.open}
          isCreateOnCalendar={isCreateOnCalendar}
          onClose={() => {
            setIsCreateOnCalendar({ start: null, end: null, open: false });
            refetchAllSchedule();
          }}
        />
      )}

      {selectedSchedule && (
        <BookingSvgDialog
          isShowDialogOpen={isShowFloorDialogOpen}
          bookingId={selectedSchedule.id}
          bookingType={selectedSchedule.bookingType as BookingType}
          highlight={selectedSchedule.bookingInventoryId}
          closeFn={handleShowFloorCloseFn}
          labelButton={t("Close")}
        />
      )}
    </>
  );
};

export default ScheduleCalendar;
