import { useEffect, useState } from "react";
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { ZoneAccessControlSelect } from "./ZoneAccessControlSelect";
import { useTranslation } from "react-i18next";
import { useRemoteZoneAccess } from "../../../../../../../hooks/Remote/ZoneAccess/useRemoteZoneAccess";
import { useRemoteFetchZoneAccess } from "../../../../../../../hooks/Remote/ZoneAccess/useRemoteFetchZoneAccess";
import { DoorLock, ZoneAccessResolved } from "../../../../../Domain/Types/FloorPlan/DoorLock.type";
import { useDispatch, useSelector } from "../../../../../../../app/helpers";
import { updateAccess } from "../../../../../../../features/FloorManager/slices/doorLocks.slice";
import { RootState } from "../../../../../../../app/rootReducer";

type Props = {
  selectedZone: IZoneSchedule;
  onClose: () => void;
};

export function ZoneAccessControlDialogContent({ selectedZone, onClose }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loadingAccess, setLoadingAccess] = useState(true);
  const doorLocks = useSelector((store: RootState) => store.floorManager.doorLocks);
  const zoneInventoryId = selectedZone.inventory?.id || selectedZone.inventoryId;

  const { data: allAccessRequest, isFetching: accessRequestFetching } =
    useRemoteFetchZoneAccess(zoneInventoryId);

  useEffect(() => {
    const accessConfig = async (): Promise<void> => {
      if (!zoneInventoryId || !allAccessRequest) return;

      // check wheter currentLocks is overlapped in the availableLocks array
      const currIds = allAccessRequest.currentDoorLocks.map(lock => lock.componentLockId);
      const checkAvailableLocks = allAccessRequest.availableDoorLocks.filter(
        lock => !currIds.includes(lock.componentLockId)
      );

      dispatch(
        updateAccess({
          zoneInventoryId: zoneInventoryId,
          access: {
            availableDoorLocks: checkAvailableLocks,
            currentDoorLocks: allAccessRequest.currentDoorLocks
          }
        })
      );

      if (!accessRequestFetching) setLoadingAccess(false);
    };
    accessConfig();
  }, [accessRequestFetching]);

  const { mutate: mutateZoneAccess, isSuccess: isZoneAccessSuccess } = useRemoteZoneAccess();

  useEffect(() => {
    if (isZoneAccessSuccess) onClose();
  }, [isZoneAccessSuccess]);

  return (
    <Card sx={{ p: 2, minHeight: "22vh", maxHeight: "70%", overflowY: "visible", width: "25vw" }}>
      <Stack direction={"column"} gap={3} data-testid="zone-access-control-dialog-content-stack">
        <Grid container data-testid="zone-access-control-dialog-content-container">
          <Grid item xs />
          <Grid item xs={9} sx={{ marginBottom: "20px" }}>
            {/* title of access control dialog */}
            <Typography align={"center"} lineHeight={2}>
              {t("Access Control {{thing}}", {
                thing: `${selectedZone.inventory?.name || ""} (${selectedZone.category?.name})`
              })}
            </Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: "right" }}>
            <IconButton
              sx={{ float: "right" }}
              data-testid="btn-group-close"
              onClick={onClose}
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        {/* description of access control dialog */}
        <Typography variant="body2" align={"left"} lineHeight={1.5}>
          {t("_descriptionForZoneAccessControl")}
        </Typography>

        <Box gap={1} alignItems={"center"}>
          <ZoneAccessControlSelect selectedZone={selectedZone} loadingAccess={loadingAccess} />
        </Box>

        <Button
          sx={{ mt: 1.5, mb: 1 }}
          data-testid={"update-zone-access-save"}
          onClick={() =>
            mutateZoneAccess({
              zoneInventoryId: zoneInventoryId,
              doorLocks: doorLockDto(doorLocks[zoneInventoryId])
            })
          }
        >
          {t("Save")}
        </Button>
      </Stack>
    </Card>
  );
}

export const doorLockDto = (items: ZoneAccessResolved): DoorLock[] => {
  return items.currentDoorLocks.map(lock => ({
    entityType: lock.entityType,
    componentLockId: lock.componentLockId
  }));
};
