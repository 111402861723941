import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { isValidSelected } from "../../../features/FloorManager/slices/selected.slice";
import { useRemoteUpdateFloorPlan } from "../../../hooks/Remote/FloorPlan/useRemoteUpdateFloorPlan";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";
import { ITimeframe } from "../Domain/Types/FloorPlan/Timeframe.type";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { IViewport } from "../Domain/Types/FloorPlan/Viewport.type";

/**
 * stores floor plans onto a stack to allow undo operations
 */
export function usePayload({
  timeframe,
  floorInventoryId
}: {
  timeframe: ITimeframe;
  floorInventoryId: number;
}) {
  const { t } = useTranslation();

  const selected = useSelector((state: RootState) => state.floorManager.selected);

  const [isInitialized, setIsInitialized] = useState<string>("");
  const [doRemount, setDoRemount] = useState(false);

  const unmountDone = useCallback(() => {
    if (!doRemount) throw new Error("Remount not expected");
    setDoRemount(false);
  }, [doRemount]);
  useEffect(() => {
    if (doRemount) setTimeout(unmountDone, 5);
  }, [doRemount, unmountDone]);

  const {
    mutate: mutateFloorPlan,
    isLoading: isFloorPlanLoading,
    error: isFloorPlanUpdateError,
    status: floorPlanUpdateStatus
  } = useRemoteUpdateFloorPlan();

  /**
   * pushes the current state into the history
   * also wipes the futures, redo will become unavailable
   */
  function handlePublish(payload: IFloorPayload, newFloorFrame?: File): void {
    const isValid = isValidSelected(selected);
    if (!isValid) return;

    if (!floorInventoryId) throw new Error("Expected floorInventoryId to be defined");

    const runStartTime = Date.now();
    setIsInitialized(t("Publishing..."));

    const zones: any[] = payload.zones.map((zone: IZoneSchedule) => {
      const places = payload.places
        .filter(workplace => workplace.zoneScheduleId == zone.id)
        .map(workplace => {
          // eslint-disable-next-line prefer-const
          let newWorkplace: any = {
            id: workplace.id,
            description: workplace.description,
            disabled: workplace.disabled,
            inventoryId: workplace?.inventory?.id || workplace.inventoryId,
            inventory: workplace.inventory,
            permissions: workplace.permissions,
            position: workplace.position,
            rotate: workplace.rotate,
            categoryId: workplace.category?.id || workplace.categoryId,
            equipmentInventoryIds: workplace.equipments?.map(d => d.id),
            placeTypeId: workplace.placeTypeId
          };

          if (newWorkplace.id < 0) delete newWorkplace.id;
          return newWorkplace;
        });

      // eslint-disable-next-line prefer-const
      let newZone: any = {
        id: zone.id,
        description: zone.description,
        disabled: zone.disabled,
        inventoryId: zone?.inventory?.id || zone.inventoryId,
        inventory: zone.inventory,
        permissions: zone.permissions,
        categoryId: zone.category?.id || zone.categoryId,
        coordinates: zone.coordinates,
        zoneTypeId: zone.zoneTypeId,
        minUsers: zone.minUsers,
        maxUsers: zone.maxUsers,
        tableArrangements: zone.tableArrangements,
        equipmentInventoryIds: zone.equipments?.map(d => d.id),
        places
      };

      if (newZone.id < 0) delete newZone.id;
      return newZone;
    });

    const parsedPayload = handleNewFloorFrame(newFloorFrame, {
      start: timeframe.start,
      end: timeframe.end,
      floorInventoryId: payload.floorInventoryId,
      viewport: payload.viewport,
      zones
    });

    mutateFloorPlan(parsedPayload);

    const runDuration = Date.now() - runStartTime;
    setTimeout(() => Math.max(3000 - runDuration, 400));
  }

  return {
    handlePublish,
    isInitialized,
    isFloorPlanLoading,
    isFloorPlanUpdateError,
    doRemount,
    floorPlanUpdateStatus
  };
}

export function handleNewFloorFrame(
  newFloorFrame: File | undefined,
  parsed: {
    start: string;
    end: string | null;
    floorInventoryId: number;
    viewport: IViewport;
    zones: any[];
  }
) {
  const parsedForm = new FormData();
  parsedForm.append("json", JSON.stringify(parsed));

  if (!newFloorFrame) return parsedForm;

  parsedForm.append("file", newFloorFrame);
  return parsedForm;
}
