import { array, Infer, number, object, string, undefined } from "myzod";
import { floorScheduleSchema } from "./floor-schedule.entity";
import { permissionSchema } from "./permission.entity";
import { inventorySchema } from "./shared/inventory.entity";
import { disabledScheduleSchema } from "./shared/timeframe";
import { viewportSchema } from "./shared/viewport";
import { FloorWarningData } from "./floor-warningData.entity";
import { IPlaceCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { IZoneCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";

/** Floor inventory */
export const floorInventorySchema = inventorySchema.and(
  object({
    locationInventoryId: number(),
    level: string()
      .map(str => String(str))
      .onTypeError("Level is required"),

    viewport: viewportSchema,
    permissions: array(permissionSchema).or(undefined()),

    schedule: array(floorScheduleSchema, { min: 1 }),
    disabledSchedule: array(disabledScheduleSchema)
  })
);
export type FloorInventory = Infer<typeof floorInventorySchema>;

export const floorResponseSchema = array(floorInventorySchema);

export type FloorAvailability = {
  id: number;
  name: string;
  availableInventoryIds: number[];
  numberOfBookableObjects: number;
  disabledInventoryIds: number[];
  occupiedInventoryIds: number[];
  forbiddenInventoryIds: number[];
  requiredUserWarningData: FloorWarningData;
  optionalUserWarningData: FloorWarningData;
  usedPlaceCategories?: IPlaceCategory[];
  usedZoneCategories?: IZoneCategory[];
};
