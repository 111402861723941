import { TextField, Autocomplete, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { setEquipment, setUserEquipment } from "../slices/booking.slice";
import { RootState } from "../../../app/rootReducer";
import { IPlaceCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { MultiselectOption } from "../../Reports/typings/reports.types";

type Option = {
  id: number;
  name: string;
};

type P = {
  categories: IPlaceCategory[];
  onSelectedChange: (selected: MultiselectOption[]) => void;
  isOpenCategoryFilter: boolean;
};

const PlaceCategoryFilter: React.FC<P> = ({
  categories,
  onSelectedChange,
  isOpenCategoryFilter
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bookingStatePlaceCategories = useSelector(
    (state: RootState) => state.booking.inputs.equipment?.workplaceCategoryIds
  );
  const preferredPlaceCategories = useSelector(
    (state: RootState) => state.login.settings.preferredPlaceCategories
  );

  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (categories.length > 0) {
      setOptions(categories);

      // check preferredCategory and apply them at first
      const preSelectedPlaceCate = categories.filter(opt =>
        preferredPlaceCategories.map(cate => Number(cate)).includes(opt.id)
      );
      if (preSelectedPlaceCate) setSelected(preSelectedPlaceCate);

      if (bookingStatePlaceCategories !== undefined) {
        setSelected(
          categories.filter(category => bookingStatePlaceCategories.includes(category.id))
        );
      }

      setLoading(false);
    }
  }, [categories]);

  // Update booking state on options change
  useEffect(() => {
    if (!loading) {
      const newSelected = selected.map(s => s.id);
      if (JSON.stringify(bookingStatePlaceCategories) !== JSON.stringify(newSelected)) {
        const workplaceCategoryIds = selected.map(s => s.id);
        dispatch(
          setEquipment({
            workplaceCategoryIds
          })
        );
        dispatch(
          setUserEquipment({
            userIndex: 0,
            equipment: {
              workplaceCategoryIds
            }
          })
        );
      }
    }
  }, [dispatch, selected, loading, bookingStatePlaceCategories]);

  return (
    <Autocomplete
      sx={{
        width: "15rem",
        padding: "5px 0 20px 0",
        display: isOpenCategoryFilter ? "inherit" : "none"
      }}
      id="place-category-filter"
      data-testid="place-category-filter-autocomplete"
      value={selected}
      limitTags={2}
      onChange={(event, newValue) => {
        setSelected(newValue);
        onSelectedChange(newValue);
      }}
      disabled={loading}
      multiple
      options={options}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          data-testid="place-category-filter-field"
          {...params}
          variant="standard"
          label={t("Place Categories")}
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.name} key={option.id} />
        ));
      }}
    />
  );
};

export default PlaceCategoryFilter;
