import { PropsWithChildren, forwardRef } from "react";
import { Stack, useTheme } from "@mui/material";

interface Props {
  color: string;
}

export const InfoBox = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ color, children }, ref) => {
    const theme = useTheme();

    return (
      <Stack
        gap={1}
        ref={ref}
        sx={{
          border: `1px solid ${color}`,
          backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#000000",
          opacity: 0.9,
          position: "relative",
          right: 0,
          p: 1,
          borderRadius: 2
        }}
      >
        {children}
      </Stack>
    );
  }
);
