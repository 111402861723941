import { v4 as uuid } from "uuid";
import * as _ from "lodash";
import { useRemotePlaceCreateInventory } from "../../../hooks/Remote/Inventory/useRemoteInventory";
import { IFloorPayload } from "../Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceInventory } from "../Domain/Types/FloorPlan/PlaceInventory.type";
import { IPlaceSchedule } from "../Domain/Types/FloorPlan/PlaceSchedule";
import BoundingBox from "../Domain/Types/BoundingBox.type";
import { InventoryStatus } from "../Domain/Types/FloorPlan/InventoryStatus.type";
import { IZoneSchedule } from "../Domain/Types/FloorPlan/ZoneSchedule";
import { IZoneInventory } from "../Domain/Types/FloorPlan/ZoneInventory.type";
import { HistoryStateActionType } from "./useHistoryState/useHistoryState";

type Props = {
  floorPlan: IFloorPayload;
  dispatchFloorPlan: (action: {
    type: HistoryStateActionType.UPDATE;
    payload: IFloorPayload;
  }) => void;
};

export function useInventoryManagement({ floorPlan, dispatchFloorPlan }: Props) {
  const {
    mutate: mutatePlace,
    isLoading: mutatePlaceIsLoading,
    error: mutatePlaceError
  } = useRemotePlaceCreateInventory();

  function changePlaceInventory(selectedDesk: IPlaceSchedule, inventory: IPlaceInventory) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    const place = newFloorPlan.places.find(wp => wp.id === selectedDesk.id);

    if (!place) return;

    place.inventory = inventory;
    place.inventoryId = inventory.id;
    const findType = newFloorPlan.placeTypes.find(
      type => type.id === place.inventory?.placeTypeId ?? 1
    );

    if (findType) {
      place.inventory = {
        ...place.inventory,
        placeType: findType,
        placeTypeId: findType.id,
        boundingBox: findType?.defaultBoundingBox ||
          findType?.appearance.boundingBox || { width: 160, height: 80 }
      };
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function changeZoneInventory(selectedZone: IZoneSchedule, inventory: IZoneInventory) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    const zone = newFloorPlan.zones.find(zn => zn.id === selectedZone.id);

    if (!zone) return;

    zone.inventory = inventory;
    zone.inventoryId = inventory.id;

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function changePlaceZone(selectedDesks: IPlaceSchedule[], zoneId: number) {
    const newFloorPlan = _.cloneDeep(floorPlan);
    const selectedIds = selectedDesks.map(d => d.id);
    for (const id of selectedIds) {
      const place = newFloorPlan.places.find(wp => wp.id === id);

      if (!place) return;

      place.zoneScheduleId = zoneId;
    }

    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  function createPlaceInventory({
    name,
    locationInventoryId,
    boundingBox,
    selectedDesk
  }: {
    name: string;
    locationInventoryId: number;
    boundingBox: BoundingBox;
    selectedDesk: IPlaceSchedule;
  }) {
    if (!floorPlan.floorInventoryId) throw new Error("Expected floorInventoryId to be defined");

    const addPlaceEntry: IPlaceInventory = {
      id: -1,
      uid: uuid(),
      name,
      boundingBox,
      placeTypeId: 1,
      status: InventoryStatus.Active,
      locationInventoryId
    };

    const newFloorPlan = _.cloneDeep(floorPlan);
    newFloorPlan.freeInventories.places.push(addPlaceEntry);
    newFloorPlan.places.map(workplace => {
      if (workplace.id == selectedDesk.id) {
        workplace.inventory = addPlaceEntry;
        workplace.inventoryId = addPlaceEntry.id;
      }

      return workplace;
    });

    mutatePlace(addPlaceEntry);
    dispatchFloorPlan({ type: HistoryStateActionType.UPDATE, payload: newFloorPlan });
  }

  return {
    createPlaceInventory,
    changePlaceInventory,
    changePlaceZone,
    changeZoneInventory,
    mutatePlaceIsLoading,
    mutatePlaceError
  };
}

export type useInventoryManagementActions = {
  createPlaceInventory: ({
    name,
    locationInventoryId,
    boundingBox,
    selectedDesk
  }: {
    name: string;
    locationInventoryId: number;
    boundingBox: BoundingBox;
    selectedDesk: IPlaceSchedule;
  }) => void;
  changePlaceInventory: (selectedDesk: IPlaceSchedule, inventory: IPlaceInventory) => void;
  changeZoneInventory: (selectedZone: IZoneSchedule, inventory: IZoneInventory) => void;
  changePlaceZone: (selectedDesks: IPlaceSchedule[], zoneId: number) => void;
};
