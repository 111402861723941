import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, IconButton, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IFloorPayload } from "../../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { DeskZoneSelect } from "./DeskZoneSelect";
import { useInventoryManagementActions } from "../../../../../Hooks/useInventoryManagement";
import { Close } from "@mui/icons-material";

type Props = {
  selectedDesks: IPlaceSchedule[];
  floorPlan: IFloorPayload;
  onClose: () => void;
  inventoryManagement: useInventoryManagementActions;
};

export function DeskZoneDialogContent({
  selectedDesks,
  floorPlan,
  onClose,
  inventoryManagement
}: Props) {
  const [selectedZone, setSelectedZone] = useState<IZoneSchedule | undefined>(
    floorPlan.zones.find(zone => zone.id == selectedDesks[0].zoneScheduleId)
  );

  const { t } = useTranslation();

  const handleUpdateDeskZone = () => {
    if (!selectedZone) return;

    inventoryManagement.changePlaceZone(selectedDesks, selectedZone.id);
    setSelectedZone(undefined);
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const targetZone = floorPlan.zones.find(zone => String(zone.id) == event.target.value);

    setSelectedZone(targetZone);
  };
  const titleOfSelectedDesks = selectedDesks.length > 1 ? t("MultiplePlacesLabel") : "";
  return (
    <Card sx={{ p: 2, maxHeight: "80vh", minWidth: "20vw" }}>
      <Stack direction={"column"} gap={2}>
        <Typography align={"center"} lineHeight={2.5}>
          {t("AssignPlaceZoneTitle")} {titleOfSelectedDesks}
          <IconButton
            sx={{ float: "right" }}
            data-testid="btn-group-close"
            onClick={() => {
              setSelectedZone(undefined);
              onClose();
            }}
            size="large"
          >
            <Close />
          </IconButton>
        </Typography>

        {selectedDesks[0].inventory && (
          <Typography>{/* Zone: <Chip label={zone?.inventory?.name} /> */}</Typography>
        )}

        <Box gap={1} display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <DeskZoneSelect
            selectedZone={selectedZone}
            onChange={handleChangeSelect}
            zones={floorPlan.zones}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            data-testid="desk-zone-assign-save-btn"
            onClick={() => {
              handleUpdateDeskZone();
              onClose();
            }}
            sx={{ width: "100%" }}
          >
            {t("Save")}
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}
