import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { TFunction } from "i18next";

type Props = {
  type: "Place" | "Zone";
  showMultiInfoHeader: boolean;
  entries: (IPlaceSchedule | IZoneSchedule)[];
  showHeader?: boolean;
};

export function MultiInfoHeader({ type, showMultiInfoHeader, entries, showHeader = true }: Props) {
  const { t } = useTranslation();
  return (
    <>
      {showMultiInfoHeader && (
        <Box sx={{ marginBottom: 1 }}>
          {/* title of multi entries sidebar */}
          {showHeader && (
            <Typography sx={{ textAlign: "center", marginTop: 1, marginBottom: 1 }} variant="h6">
              {type === "Place" ? t("Edit multiple places") : t("Edit multiple zones")}
            </Typography>
          )}
          {/* lists of selected entries inventory name */}
          {entries.length <= 3 ? (
            entries.map(entry => {
              return (
                <Typography key={entry.id} sx={{ textAlign: "center" }} variant="subtitle2">
                  {checkEntryName(entry, t)}
                </Typography>
              );
            })
          ) : (
            <>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkEntryName(entries[0], t)}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkEntryName(entries[1], t)}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkEntryName(entries[2], t)}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="body2">
                {t(`{{x}} more`, { x: entries.length - 3 })}
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export function checkEntryName(entry: IPlaceSchedule | IZoneSchedule, t: TFunction) {
  return entry.inventory?.name || t(`new entry (${entry.id})`);
}
