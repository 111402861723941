import { useEffect, useState, useMemo } from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Theme,
  Typography,
  useTheme
} from "@mui/material";
import { MoreTime } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IZoneSchedule } from "../../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { PlaceMediumIcon, PlaceSmallIcon } from "../../../../Canvas/Partials/place-icons.component";
import { useRemoteFetchTableArrangement } from "../../../../../../../hooks/Remote/TableArrangement/useRemoteFetchTableArrangement";
import {
  SingleTableArrangement,
  TableArrangement,
  TableArrangementAssignments
} from "../../../../../Domain/Types/FloorPlan/TableArrangement.type";
import { useRemoteFloorPlanService } from "../../../../../../../hooks/Remote/FloorPlan/useRemoteFloorPlanService";
import { v4 as uuidv4 } from "uuid";

type Props = {
  zoneSchedule: IZoneSchedule;
  tableArr: TableArrangementAssignments[] | undefined;
  onChangeConfService: (id: number, service: SingleTableArrangement[]) => void;
  onClose: () => void;
};

export const TablePlacementDialogContent = ({
  zoneSchedule,
  tableArr,
  onChangeConfService,
  onClose
}: Props) => {
  const { t } = useTranslation();

  const { data: fetchData } = useRemoteFetchTableArrangement();

  const [selectedTableArr, setSelectedTableArr] = useState<TableArrangement[]>([]);
  const [unselectedTableArr, setUnselectedTableArr] = useState<TableArrangement[]>([]);

  const availableTableArr = useMemo(() => {
    if (fetchData) return fetchData;

    return [];
  }, [fetchData]);

  useEffect(() => {
    if (availableTableArr) {
      const tableAr = tableArr?.find(t => t.zoneScheduleId === zoneSchedule.id)?.tableAssignmentIds;
      const unselect = availableTableArr.filter(
        avail => !tableAr?.map(tab => tab.tableArrangementId).includes(avail.id)
      );
      const select = availableTableArr.filter(avail =>
        tableAr?.map(tab => tab.tableArrangementId).includes(avail.id)
      );

      setUnselectedTableArr(unselect);
      setSelectedTableArr(select);
    }
  }, [availableTableArr]);

  const { mutate: mutateService } = useRemoteFloorPlanService();

  return (
    <>
      <Card sx={{ p: 2, maxHeight: "80vh" }} data-testid={"tableplacement-assignment"}>
        <Stack direction={"column"} gap={2}>
          {/* header of dialog content */}
          <Typography align={"center"} lineHeight={2.5}>
            {t("TablePlacementContentTitle")}
          </Typography>

          {/* parent element of display and select options */}
          <Grid container spacing={2} data-testid="tableplacement-parentEl">
            {/* display options */}
            <Grid item xs={6} data-testid="tableplacement-displayOpts">
              <Paper
                sx={{ p: 1, textAlign: "-webkit-center", height: "100%" }}
                data-testid="tableplacement-displayOpts-paper"
              >
                <Typography>{t("Options")}</Typography>
                <Divider sx={{ mb: 2 }} />
                <MultiPlacesOptSelection
                  type="option"
                  zoneId={zoneSchedule.id}
                  selectedTableArr={selectedTableArr}
                  unselectedTableArr={unselectedTableArr}
                  setSelectedTableArr={setSelectedTableArr}
                  setUnselectedTableArr={setUnselectedTableArr}
                />
              </Paper>
            </Grid>

            {/* select options */}
            <Grid item xs={6} data-testid="tableplacement-selectOpts">
              <Paper
                sx={{ p: 1, textAlign: "-webkit-center", height: "100%" }}
                data-testid="tableplacement-selectOpts-paper"
              >
                <Typography>{t("Selection")}</Typography>
                <Divider sx={{ mb: 2 }} />
                <MultiPlacesOptSelection
                  type="selected"
                  zoneId={zoneSchedule.id}
                  selectedTableArr={selectedTableArr}
                  unselectedTableArr={unselectedTableArr}
                  setSelectedTableArr={setSelectedTableArr}
                  setUnselectedTableArr={setUnselectedTableArr}
                />
              </Paper>
            </Grid>
          </Grid>

          {/* buttons */}
          <Grid container item sx={{ justifyContent: "flex-end" }}>
            <Button data-testid="cancel-btn" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              data-testid="confirm-btn"
              onClick={() => {
                const arr = [...selectedTableArr.map(arr => arr.id)].map(select => ({
                  tableArrangementId: select,
                  preparationTime: null
                }));
                onChangeConfService(zoneSchedule.id, arr);
                mutateService({ zoneScheduleId: zoneSchedule.id, tableArrangements: arr });
                onClose();
              }}
            >
              {t("Confirm")}
            </Button>
          </Grid>
        </Stack>
      </Card>
    </>
  );
};

export function MultiPlacesOptSelection({
  type,
  selectedTableArr,
  unselectedTableArr,
  setSelectedTableArr,
  setUnselectedTableArr
}: {
  type: "option" | "selected";
  zoneId: number;
  selectedTableArr: TableArrangement[];
  unselectedTableArr: TableArrangement[];
  setSelectedTableArr: (s: TableArrangement[]) => void;
  setUnselectedTableArr: (u: TableArrangement[]) => void;
}) {
  const theme = useTheme();

  const [isUsingTimer, setIsUsingTimer] = useState(false);

  function handleClick(selectedId: number) {
    if (type === "option") {
      const selected = unselectedTableArr.filter(t => t.id === selectedId);
      const unselected = unselectedTableArr.filter(t => t.id !== selectedId);

      const newSelected = [...selectedTableArr, ...selected];

      setSelectedTableArr(newSelected);
      setUnselectedTableArr(unselected);
    }
    if (type === "selected") {
      const remainSelected = selectedTableArr.filter(t => t.id !== selectedId);
      const optToAdd = selectedTableArr.filter(t => t.id === selectedId);

      const newUnselected = [...unselectedTableArr, ...optToAdd];

      setSelectedTableArr(remainSelected);
      setUnselectedTableArr(newUnselected);
    }
  }

  const entries = type === "option" ? unselectedTableArr : selectedTableArr;

  return (
    <Grid
      xs={12}
      data-testid="place-icons-container"
      container
      item
      sx={{
        width: 180,
        height: "100%",
        padding: "5px",
        placeContent: "center",
        mt: "-25px",
        mb: "30px"
      }}
    >
      {entries &&
        generateTableArr(type, entries, handleClick, isUsingTimer, setIsUsingTimer, theme)}
    </Grid>
  );
}

export function generateTableArr(
  type: "option" | "selected",
  entries: TableArrangement[],
  handleClick: (selectedId: number) => void,
  isUsingTimer: boolean,
  setIsUsingTimer: (u: boolean) => void,
  theme: Theme
) {
  const iconFilledWithTheme = theme.palette.mode === "dark" ? "#ffffff" : "#000000";
  const iconStrokeWithTheme = theme.palette.mode === "dark" ? "#2b2b2b" : "#DBDBDB";
  const placeItemClass = { paddingTop: "5px", margin: "auto", minWidth: "max-content" };
  const placeIconBoxClass = {
    width: 180,
    padding: "8px",
    border: theme.palette.mode === "dark" ? "1px solid #ffffff" : "1px solid #000000",
    textAlign: "center",
    cursor: "pointer"
  };

  const usePreparationTime = false;

  // each containers
  return entries.map(({ id, arrangement }) => {
    // check number of row by length
    const Icon = arrangement.length > 1 ? PlaceSmallIcon : PlaceMediumIcon;

    return (
      <Grid container data-testid={`place-icons-parent${id}`} key={uuidv4()}>
        <Grid item>
          <Button
            size="small"
            sx={{ padding: "initial", lineHeight: "initial", fontSize: "inherit", margin: "4px 0" }}
            onClick={() => handleClick(id)}
            data-testid={`place-icons-group-btn${id}`}
          >
            <Grid
              item
              sx={placeIconBoxClass}
              data-testid={`place-icons-group-${id}`}
              key={uuidv4()}
            >
              {arrangement.map(row => (
                // each rows
                <Grid
                  item
                  xs={8}
                  sx={placeItemClass}
                  data-testid={`place-icons-row-${id}`}
                  key={uuidv4()}
                >
                  {row.map(box => {
                    // each boxes
                    if (box)
                      return (
                        <Icon
                          fill={iconFilledWithTheme}
                          stroke={iconStrokeWithTheme}
                          key={uuidv4()}
                        />
                      );

                    return <Icon fill={"none"} stroke={"none"} key={uuidv4()} />;
                  })}
                </Grid>
              ))}
            </Grid>
          </Button>
        </Grid>

        {/** select preparation time, hide for now */}
        {type === "selected" && usePreparationTime && (
          <Grid item sx={{ alignSelf: "center" }}>
            <IconButton
              size="medium"
              sx={{ ml: "2px" }}
              data-testid={`setup-time-btn${id}`}
              onClick={() => setIsUsingTimer(!isUsingTimer)}
            >
              <MoreTime />
            </IconButton>
          </Grid>
        )}
      </Grid>
    );
  });
}
