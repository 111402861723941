import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import { Close, Delete, LockPerson, MeetingRoom } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import qrcodeIcon from "@iconify/icons-mdi/qrcode";
import { useSnackbar } from "notistack";
import { InfoSideBar } from "../InfoSidebar";
import { IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { InfoBox } from "../InfoBox";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { AssignBox } from "../AssignBox/AssignBox";
import { ZoneInventoryDialogContent } from "./ZoneInventoryDialogContent/ZoneInventoryDialogContent";
import { useInventory } from "../../../../Hooks/useInventory";
import { OptionDialogContent } from "./OptionDialogContent/OptionDialogContent";
import { ZonePermissionDialogContent } from "./ZonePermissionDialogContent/ZonePermissionDialogContent";
import { ZoneAccessControlDialogContent } from "./AccessControlDialogContent/ZoneAccessControlDialogContent";
import { QrCodeComponent } from "../../../Modals/QrCodeModal/QrCodeComponent";
import { useSelector } from "../../../../../../app/helpers";
import { RootState } from "../../../../../../app/rootReducer";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { InfoIconWithTooltip } from "../../../../../Title/InfoIcon";
import {
  handlePatchZoneInventory,
  handleUserNumbersInput,
  initialUserNumbers,
  isEntryAssigned,
  updateAssingedDevice,
  updateMutatedZoneInventory
} from "./ZoneInfoSidebar.partial";
import { ZoneInfoSidebarProps } from "./ZoneInfoSidebar.switch";
import { IDeviceInventory } from "../../../../Domain/Types/FloorPlan/Device.type";

export const ZoneInfoSidebar = forwardRef<HTMLDivElement, ZoneInfoSidebarProps>(
  (
    {
      height,
      zoneSchedules,
      floorPlan,
      isZnInvModalOpen,
      hasSiteId,
      setIsZnInvModalOpen,
      onClose,
      onClickDelete,
      onChangeZoneDescription,
      onChangeName,
      onChangePerm,
      onChangeEnabledStatus,
      onChangeUserNumber,
      updateZoneInventory,
      inventoryManagement,
      categoryManagement,
      equipmentManagement,
      floorPlanName
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
      settings: { colorMode }
    } = useSelector((state: RootState) => state.login);
    const iconColor = colorMode === "light" ? "#6f6f6f" : "white";

    const selectedIds = zoneSchedules.map(m => m.id);

    const zoneTypeId =
      zoneSchedules[0]?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneType?.id;

    const availableDevices = floorPlan.freeInventories.equipments.filter(
      d => !isEntryAssigned(d, floorPlan.zones)
    );

    const zoneTypeName = floorPlan.zoneTypes?.find(type => type.id === zoneTypeId)?.name || "";

    const { minUsers, maxUsers } = initialUserNumbers(zoneSchedules);

    const [zoneName, setZoneName] = useState("");
    const [zoneDesc, setZoneDesc] = useState("");
    const [zoneUserNumber, setZoneUserNumber] = useState<IZoneUserNumber>({
      minUsers: minUsers,
      maxUsers: maxUsers
    });

    const [isZoneCategoryModalOpen, setIsZoneCategoryModalOpen] = useState(false);
    const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
    const [isZnPermDialogOpen, setIsZnPermDialogOpen] = useState(false);
    const [isAccessControlOpen, setIsAccessControlOpen] = useState(false);
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);

    const [assignedDevice, setAssginedDevice] = useState<IDeviceInventory[]>([]);

    const { handleCreateZoneInventory, mutatedZoneInventory } = useInventory({ floorPlan });

    // check if multi selected zone type includes conference zone
    const isConferenceOnlySelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === 3);

    // length of selected zone
    const singleZoneSelected = zoneSchedules.length === 1;

    // check if multi selected zone have same type
    const isIdenticalZoneTypeSelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === zoneTypeId);

    useEffect(() => {
      if (zoneSchedules) {
        setZoneName(zoneSchedules[0]?.inventory?.name ?? "");
        setZoneDesc(zoneSchedules[0]?.description ?? "");
        setZoneUserNumber({
          minUsers: minUsers,
          maxUsers: maxUsers
        });
      }

      updateAssingedDevice(zoneSchedules, setAssginedDevice);
    }, [zoneSchedules]);

    useEffect(() => {
      updateMutatedZoneInventory(mutatedZoneInventory, zoneSchedules, updateZoneInventory);
    }, [mutatedZoneInventory]);

    return (
      <InfoSideBar height={height} width={250} overflow="visible" ref={ref}>
        {zoneSchedules.length > 0 && (
          <InfoBox color={"#00000088"}>
            {/* header of multi zones edit and listing the names */}
            <MultiInfoHeader
              type={"Zone"}
              showMultiInfoHeader={zoneSchedules.length > 1}
              entries={zoneSchedules}
            />

            {/* name of the zone */}
            <CompactTextField
              placeholder={t("Name")}
              value={zoneName}
              rows={2}
              onChange={newText => {
                onChangeName(selectedIds, newText);
                setZoneName(newText);
              }}
              handleOk={newText => {
                for (const zone of zoneSchedules) {
                  handlePatchZoneInventory({
                    zoneInventory: zone.inventory,
                    enqueueSnackbar,
                    t,
                    newName: newText
                  });
                }
              }}
              newText={zoneName}
              setNewText={(t: string) => setZoneName(t)}
            />

            {/* description of the zone */}
            <CompactTextField
              rows={2}
              placeholder={t("Description")}
              value={zoneDesc}
              onChange={desc => onChangeZoneDescription(desc)}
              newText={zoneDesc}
              setNewText={(t: string) => setZoneDesc(t)}
            />

            {/* type of the zone */}
            {isIdenticalZoneTypeSelected && (
              <AssignBox
                buttonDataTestId="type-assign-box"
                onClickAssign={() => setIsZnInvModalOpen(true)}
                id={
                  zoneSchedules[0].zoneTypeId ||
                  zoneSchedules[0].inventory?.zoneType?.id ||
                  zoneSchedules[0].inventory?.zoneTypeId
                }
                name={zoneTypeName}
                type={"Type"}
              />
            )}

            {/** inventory */}
            {singleZoneSelected && (
              <AssignBox
                onClickAssign={() => setIsZnInvModalOpen(true)}
                id={zoneSchedules[0].inventory?.id}
                type={"Inventory"}
              />
            )}

            {/** category */}
            <AssignBox
              onClickAssign={() => setIsZoneCategoryModalOpen(true)}
              id={zoneSchedules[0].category?.id}
              type={"Category"}
              buttonDataTestId="assign-category-btn"
            />

            {/** device */}
            {singleZoneSelected && (
              <AssignBox
                buttonDataTestId="device-assign-box"
                onClickAssign={() => setIsDeviceModalOpen(!isDeviceModalOpen)}
                id={
                  assignedDevice.length
                    ? t(`${assignedDevice.length} (pcs)`)
                    : t("NoEquipmentSidebarLabel")
                }
                type={"Equipment"}
              />
            )}

            {/** property for conference zone */}
            {singleZoneSelected && isConferenceOnlySelected && (
              <>
                {/** min and max users number */}
                <Grid
                  container
                  data-testid="zone-userNumber-input"
                  sx={{ pt: "0.3rem", flexWrap: "nowrap" }}
                >
                  <Grid item>
                    <MiniTextInput
                      fields={[
                        {
                          value: zoneUserNumber.minUsers,
                          placeholder: "Min",
                          id: "min",
                          type: "number"
                        },
                        {
                          value: zoneUserNumber.maxUsers,
                          placeholder: "Max",
                          id: "max",
                          type: "number"
                        }
                      ]}
                      onInput={(id, value) => {
                        handleUserNumbersInput(
                          id,
                          value,
                          zoneSchedules[0].id,
                          zoneUserNumber,
                          setZoneUserNumber,
                          onChangeUserNumber,
                          enqueueSnackbar
                        );
                      }}
                      zoneUserNumber={zoneUserNumber}
                      setZoneUserNumber={(n: IZoneUserNumber) => setZoneUserNumber(n)}
                    />
                  </Grid>
                  <Grid item data-testid="user-number-info-desc" sx={{ pt: "2px", pl: "2px" }}>
                    <InfoIconWithTooltip tooltipText={t("EditInfoUserNumberForConferenceZone")} />
                  </Grid>
                </Grid>
              </>
            )}

            {/** bottom bar */}
            <Box display={"flex"} alignItems={"center"}>
              <Box>
                <IconButton
                  data-testid={"delete-btn"}
                  size={"small"}
                  onClick={() => onClickDelete(selectedIds)}
                >
                  <Delete />
                </IconButton>
              </Box>

              {/* status of the zone */}
              <FormControlLabel
                labelPlacement={"top"}
                sx={{ fontSize: "12px", width: zoneTypeId === 3 || hasSiteId ? "20px" : "initial" }}
                control={
                  <Switch
                    data-testid={"enabled-switch"}
                    size={"small"}
                    checked={!zoneSchedules[0].disabled}
                    onChange={e => onChangeEnabledStatus(selectedIds, e.target.checked)}
                  />
                }
                label={
                  <Typography fontSize={"12px"}>
                    {!zoneSchedules[0].disabled ? t("Active") : t("Inactive")}
                  </Typography>
                }
              />

              {/* access control */}
              {singleZoneSelected && hasSiteId && (
                <Box>
                  <Tooltip title={t("Access Control")}>
                    <div>
                      <IconButton
                        data-testid={"zone-access-btn"}
                        size={"small"}
                        onClick={() => setIsAccessControlOpen(true)}
                        disabled={!zoneSchedules[0].inventory?.id}
                      >
                        <MeetingRoom />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Box>
              )}
              <Box flex={1} />

              {/* adjust permissions */}
              <Box>
                <Tooltip title={t("Adjust permisisons")}>
                  <div>
                    <IconButton
                      data-testid={"permission-btn"}
                      size={"small"}
                      onClick={() => setIsZnPermDialogOpen(true)}
                    >
                      <LockPerson />
                    </IconButton>
                  </div>
                </Tooltip>
              </Box>

              {/* qr code print for conference zone */}
              {isConferenceOnlySelected && (
                <Tooltip title={t("SingleQrCodePrint")}>
                  <div>
                    <IconButton
                      data-testid="tool-btn-printQr"
                      onClick={() => setIsQrModalOpen(!isQrModalOpen)}
                      sx={{ padding: "5px" }}
                      size="large"
                    >
                      <Icon icon={qrcodeIcon} color={iconColor} width={26} height={26} />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
              <Box>
                <IconButton data-testid={"close-btn"} size={"small"} onClick={() => onClose()}>
                  <Close />
                </IconButton>
              </Box>
            </Box>

            {/* zone permission modal */}
            <Modal
              open={isZnPermDialogOpen}
              onClose={() => setIsZnPermDialogOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZonePermissionDialogContent
                  selectedZones={zoneSchedules}
                  onChangePerm={onChangePerm}
                  onClose={() => setIsZnPermDialogOpen(false)}
                />
              </>
            </Modal>

            {/* zone inventory modal */}
            <Modal
              open={isZnInvModalOpen}
              onClose={() => setIsZnInvModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZoneInventoryDialogContent
                  selectedZone={zoneSchedules[0]}
                  floorPlan={floorPlan}
                  inventoryManagement={inventoryManagement}
                  handleCreateZoneInventory={handleCreateZoneInventory}
                  onClose={() => setIsZnInvModalOpen(false)}
                  setZoneName={(n: string) => setZoneName(n)}
                />
              </>
            </Modal>

            {/* zone category modal */}
            <Modal
              open={isZoneCategoryModalOpen}
              onClose={() => setIsZoneCategoryModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <OptionDialogContent
                  pivotEntityName="Zone"
                  subjectEntityName="Category"
                  onClose={() => setIsZoneCategoryModalOpen(false)}
                  displayHeaderId={zoneSchedules[0].id}
                  displayChipValue={zoneSchedules[0].category?.name || 0}
                  subjectEntity={zoneSchedules}
                  subjectEntityEntries={floorPlan.zoneCategories}
                  updateFunctionSub1={categoryManagement.changeZoneCategory}
                />
              </>
            </Modal>

            {/* zone devices assignment modal */}
            <Modal
              open={isDeviceModalOpen}
              onClose={() => setIsDeviceModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <OptionDialogContent
                  subjectEntity={zoneSchedules[0]}
                  subjectEntityName="Devices"
                  displayHeaderId={zoneSchedules[0].id}
                  displayChipValue={""}
                  subjectEntityEntries={[
                    { availableDevices: availableDevices, assignedDevices: assignedDevice }
                  ]}
                  pivotEntityName="Zone"
                  onClose={() => setIsDeviceModalOpen(false)}
                  updateFunctionSub2={equipmentManagement.changeZoneEquipments}
                />
              </>
            </Modal>

            {/* access control modal */}
            <Modal
              open={isAccessControlOpen}
              onClose={() => setIsAccessControlOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <ZoneAccessControlDialogContent
                  selectedZone={zoneSchedules[0]}
                  onClose={() => setIsAccessControlOpen(false)}
                />
              </>
            </Modal>

            {/** print QR Code modal for conference zone */}
            <QrCodeComponent
              open={isQrModalOpen}
              floorPlanName={floorPlanName}
              entries={zoneSchedules}
              onClose={() => setIsQrModalOpen(false)}
            />
          </InfoBox>
        )}
      </InfoSideBar>
    );
  }
);
