import { DateTime } from "luxon";
import { AppDispatch } from "../../app/store";
import { fetchPlan } from "../../features/Booking-Form/functions/floor.functions";
import { handlePlanResponse } from "../../features/Booking-Form/functions/form.functions";
import { setInputs } from "../../features/Booking-Form/slices/booking.slice";
import { fetchBookingFloors } from "../../features/Booking-Form/thunks/booking.thunks";
import {
  BookingType,
  NotSerializedBookingInputs,
  NotSerializedBookingMode
} from "../../features/Booking-Form/typings/booking-inputs";
import { TeamMember } from "../../features/Booking-Form/typings/team-member";
import { FloorAvailability } from "../../features/FloorManager/typings/floor-inventory.entity";
import { MultiselectOption } from "../../features/Reports/typings/reports.types";
import { getLuxonByTime } from "../../utils/utilities";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { IZoneCategory } from "../FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { IPlaceCategory } from "../FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import * as _ from "lodash";

export const inputsChanged = (
  loaded: React.MutableRefObject<boolean>,
  filtersChanged: boolean,
  inputs: NotSerializedBookingInputs
) => !loaded.current || filtersChanged || inputs.bookingEnd;

export const specificDayInputs = (inputs: NotSerializedBookingInputs) =>
  inputs.specificDays?.length && inputs.specificDays?.length > 0;

export const inputsAreMade = (inputs: NotSerializedBookingInputs) =>
  inputs.bookingStart && inputs.bookingFrom && inputs.bookingTo && !inputs.specificDays;

export const inputsReady = (inputs: NotSerializedBookingInputs) =>
  specificDayInputs(inputs) || inputs.bookingMonthDay || inputsAreMade(inputs);

export const parsedFloorArgs = (
  inputs: NotSerializedBookingInputs,
  parsedUserInput: TeamMember[]
) => {
  return {
    users: parsedUserInput,
    startDate: DateTime.fromISO(inputs.bookingFrom ?? DateTime.now().toISO()).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromISO(inputs.bookingTo ?? DateTime.now().toISO()).toFormat("yyyy-MM-dd"),
    weekdays: inputs.weekdays,
    startTime: getLuxonByTime(inputs.bookingStart ?? "08:00").toFormat("HH:mm"),
    endTime: getLuxonByTime(inputs.bookingEnd ?? "17:00").toFormat("HH:mm"),
    bookingType: inputs.bookingType,
    locationInventoryId: inputs.selectedLocation,
    description: "what is description",
    floorInventoryId: inputs.floorInventoryId,
    frequence: inputs.frequence,
    interval: inputs.interval,
    specificDays: inputs.specificDays,
    bookingMonthDay: inputs.bookingMonthDay,
    bookingYearDay: inputs.bookingYearDay,
    conferenceZoneBookingGuests: inputs.zoneAccess?.map(g => ({
      userId: g.userId,
      isRequired: g.isRequired
    }))
  };
};

export const normalBookingSelection = (inputs: NotSerializedBookingInputs) =>
  !inputs.isMobileWorking && !inputs.automatedSeating && !inputs.activityBasedBooking;

export const parsedUsers = (inputs: NotSerializedBookingInputs, currentUser: number) =>
  inputs.mode === "team" &&
  inputs.usersBookedFor &&
  currentUser !== -1 &&
  inputs.usersBookedFor[currentUser]
    ? [inputs.usersBookedFor[currentUser]].map(user => {
        return {
          firstName: user.firstName,
          isExternal: user.isExternal,
          surname: user.surname,
          userId: user.userId,
          equipment: user.equipment
        };
      })
    : inputs.usersBookedFor &&
      inputs.usersBookedFor.map(user => {
        return {
          firstName: user.firstName,
          isExternal: user.isExternal,
          surname: user.surname,
          userId: user.userId,
          equipment: user.equipment
        };
      });

export const checkActivityBased = (
  activityBasedBooking: NotSerializedBookingInputs["activityBasedBooking"],
  nextStep: () => void
) => {
  if (activityBasedBooking) {
    nextStep();
  }
};

export const handleSelectionZoneRestriction = (inputs: NotSerializedBookingInputs) =>
  inputs.usersBookedFor && inputs.usersBookedFor[0] && inputs.bookingFrom && inputs.bookingTo
    ? [
        {
          zoneInventoryId: inputs.usersBookedFor[0].bookingInventoryId as number,
          start: inputs.bookingFrom,
          end: inputs.bookingTo
        }
      ]
    : undefined;

export const fetchFloorPlanState = (
  inputs: NotSerializedBookingInputs,
  setFloorPlan: React.Dispatch<React.SetStateAction<any>>,
  currentFloor?: FloorAvailability
) => {
  // if the floor doesn't have numberOfBookableObjects, this will not fetch plan and wait for next currentFloorIndex comes
  /** @deprecated if (currentFloor && !currentFloor.numberOfBookableObjects) return; */

  if (normalBookingSelection(inputs) && currentFloor) {
    fetchPlan(
      currentFloor.id,
      inputs.bookingFrom,
      inputs.bookingStart,
      inputs.bookingEnd,
      inputs.bookingTo
    ).then(res => {
      handlePlanResponse(res, setFloorPlan);
    });
  }
};

export const updateFilters = (
  inputs: NotSerializedBookingInputs,
  currentUser: number,
  loaded: any,
  dispatch: AppDispatch,
  setFiltersChanged: React.Dispatch<React.SetStateAction<boolean>>,
  filtersChanged: boolean
) => {
  if (inputsChanged(loaded, filtersChanged, inputs) && inputsReady(inputs)) {
    setFiltersChanged(false);

    dispatch(
      fetchBookingFloors(parsedFloorArgs(inputs, parsedUsers(inputs, currentUser) as TeamMember[]))
    ).then(() => {
      loaded.current = true;
    });
  }
};

export const handleFilterSelect = (
  newSelected: MultiselectOption[],
  key: string,
  inputs: NotSerializedBookingInputs,
  currentUser: number,
  dispatch: AppDispatch
) => {
  if (inputs.usersBookedFor) {
    const ids = newSelected.map(s => s.id);
    const newTeam = [...inputs.usersBookedFor];
    if (!newTeam[currentUser].equipment) {
      newTeam[currentUser] = {
        ...newTeam[currentUser],
        equipment: {
          [key]: ids
        }
      };
    } else {
      newTeam[currentUser] = {
        ...newTeam[currentUser],
        equipment: {
          ...newTeam[currentUser].equipment,
          [key]: ids
        }
      };
    }

    dispatch(setInputs({ usersBookedFor: newTeam }));
  }
};

export const checkCurrentUser = (
  usersBookedFor: NotSerializedBookingInputs["usersBookedFor"]
): number => {
  if (usersBookedFor) {
    const n = usersBookedFor.findIndex(user => !user.bookingInventoryId);
    if (n === -1) {
      return 0;
    } else {
      return n;
    }
  } else {
    return 0;
  }
};

export function filterCategorySelection(
  inputs: NotSerializedBookingInputs,
  floorPlan: IFloorPayload,
  setFilteredSelectables: (v: (number | undefined)[] | undefined) => void
) {
  const { equipment } = inputs;
  const { places, zones } = floorPlan;

  if (!equipment) return;

  // filter by selected place category
  if (equipment.workplaceCategoryIds && equipment.workplaceCategoryIds.length > 0) {
    const filtered = places
      .filter(place =>
        equipment.workplaceCategoryIds?.includes(place.category?.id || place.categoryId || 0)
      )
      .map(pl => pl.inventory?.id || pl.inventoryId);
    setFilteredSelectables(filtered);
  } else if (equipment.workplaceCategoryIds && equipment.workplaceCategoryIds.length === 0) {
    setFilteredSelectables(undefined);
  }

  // filter by selected zone category
  if (equipment?.zoneCategoryIds && equipment.zoneCategoryIds.length > 0) {
    const filtered = zones
      .filter(zone =>
        equipment.zoneCategoryIds?.includes(zone.category?.id || zone.categoryId || 0)
      )
      .map(zn => zn.inventory?.id || zn.inventoryId);
    setFilteredSelectables(filtered);
  } else if (equipment.zoneCategoryIds && equipment.zoneCategoryIds.length === 0) {
    setFilteredSelectables(undefined);
  }
}

/**
 * Only indicate categories that actually exist in selected floor plan per booking type
 */
export function initCategoryOptions(
  bookingMode: NotSerializedBookingMode,
  floorPlan: IFloorPayload | undefined,
  currentFloor: FloorAvailability,
  setPlaceCategoryOptions: (placeOpts: IPlaceCategory[]) => void,
  setZoneCategoryOptions: (zoneOpts: IZoneCategory[]) => void
) {
  if (!floorPlan || !currentFloor) return;

  const { places, zones } = floorPlan;
  const { usedPlaceCategories, usedZoneCategories } = currentFloor;

  const [type, typeId] = typeIdPerBookingMode(bookingMode);

  if (type === "place") {
    const usedPlaceCategoriesFromFloorPlan = places
      .filter(pl => pl.inventory?.placeTypeId === typeId)
      .map(p => p.category);
    const placeCategories = _.uniqBy(usedPlaceCategoriesFromFloorPlan, c => c?.id);
    const filtered = usedPlaceCategories?.filter(u => placeCategories.some(p => p?.id === u.id));

    if (filtered && filtered.length > 0) {
      setPlaceCategoryOptions(filtered);
    } else setPlaceCategoryOptions([]);
  }

  if (type === "zone") {
    const usedZoneCategoriesFromFloorPlan = zones
      .filter(zn => zn.inventory?.zoneTypeId === typeId)
      .map(z => z.category);
    const zoneCategories = _.uniqBy(usedZoneCategoriesFromFloorPlan, c => c?.id);
    const filtered = usedZoneCategories?.filter(u => zoneCategories.some(z => z?.id === u.id));

    if (filtered && filtered.length > 0) {
      setZoneCategoryOptions(filtered);
    } else setZoneCategoryOptions([]);
  }
}

// check if additional categories exist in the floor plan
export function checkAdditionalCategory(
  floorPlan: IFloorPayload | undefined,
  setPlaceCategoryOptions: (placeOpts: IPlaceCategory[]) => void,
  setZoneCategoryOptions: (zoneOpts: IZoneCategory[]) => void
) {
  if (!floorPlan) return;

  const { places, zones, placeCategories, zoneCategories } = floorPlan;
  if (!placeCategories || !zoneCategories) return;

  // check place categories and additional category
  const additionalPl = places
    .filter(place => !placeCategories.map(ca => ca.id).includes(place.category?.id || 0))
    .map(p => p.category);
  const additionalZn = zones
    .filter(zone => !zoneCategories.map(cat => cat.id).includes(zone.category?.id || 0))
    .map(z => z.category);

  if (additionalPl.length) {
    setPlaceCategoryOptions([...(additionalPl as IPlaceCategory[]), ...placeCategories]);
  } else setPlaceCategoryOptions(placeCategories);

  // check zone categories and additional category
  if (additionalZn.length) {
    setZoneCategoryOptions([...(additionalZn as IZoneCategory[]), ...zoneCategories]);
  } else setZoneCategoryOptions(zoneCategories);
}

// filter by category selection and return this available list for the seatStatus
export function filterListByCategory(
  currentInventoryIds: number[],
  filteredSelectables: (number | undefined)[] | undefined
) {
  if (filteredSelectables) {
    return [...currentInventoryIds.filter(id => filteredSelectables.includes(id))];
  } else return currentInventoryIds;
}

export function tempFilterListByCategory(
  currentInventoryIds: number[],
  filteredSelectables: (number | undefined)[] | undefined
) {
  if (filteredSelectables) {
    return [...currentInventoryIds.filter(id => !filteredSelectables.includes(id))];
  } else return [];
}

/**
 * return place/zone type Id per booking mode
 */
export const typeIdPerBookingMode = (
  bookingMode: NotSerializedBookingMode
): [type: string, typeId: number] => {
  switch (bookingMode) {
    case BookingType.WORKPLACE:
      return ["place", 1];
    case BookingType.PARKINGPLACE:
      return ["place", 2];
    case BookingType.TEAM:
      return ["place", 1];
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      return ["place", 4];
    case BookingType.PLACEZONE:
      return ["zone", 1];
    case BookingType.OPENSPACE:
      return ["zone", 2];
    case BookingType.CONFERENCEZONE:
      return ["zone", 3];
    case BookingType.RESTRICTED:
      return ["zone", 4];
    default:
      return ["place", 1];
  }
};
