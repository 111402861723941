import { Chip, Theme, Tooltip, styled } from "@mui/material";
import { AccountBox, EvStation, EventSeat, LocalParking } from "@mui/icons-material";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { calBrightnessToHex } from "../../FacilityManager/Functions/calculateBrightness";
import { hexToRGB } from "../../FacilityManager/Functions/hexColorToRGB";
import { ReactComponent as ConferenceIconComp } from "../../../features/Booking-Form/assets/conference.svg";

export const SvgReportHeaderChips = ({
  tooltip,
  label,
  icon
}: {
  tooltip: string;
  label: number;
  icon?: BookingType | "users";
}) => {
  const {
    userInformation: {
      company: { meta }
    }
  } = useSelector((state: RootState) => state.login);

  const chipSxStyle = {
    position: "relative",
    top: "-490px",
    fontSize: "15px",
    marginLeft: 0.1,
    marginRight: 0.1
  };
  const iconStyle = { width: "22px" };

  // create temp theme to convert conference icon color
  const tempTheme = {
    palette: { text: { primary: calBrightnessToHex(hexToRGB(meta.color as string)) || "#32D79B" } }
  };

  const generateIcon = (icon?: string) => {
    switch (icon) {
      case "users":
        return <AccountBox sx={iconStyle} />;
      case BookingType.WORKPLACE:
        return <EventSeat sx={iconStyle} />;
      case BookingType.PARKINGPLACE:
        return <LocalParking sx={iconStyle} />;
      case BookingType.ELECTRICCHARGINGSTATIONPLACE:
        return <EvStation sx={iconStyle} />;
      case BookingType.CONFERENCEZONE:
        return (
          <StyledComponent theme={tempTheme as Theme}>
            <ConferenceIconComp width={"22px"} height={"22px"} />
          </StyledComponent>
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <Tooltip title={tooltip}>
        <Chip
          data-testid={`${tooltip}-header-chip`}
          icon={generateIcon(icon)}
          size={"medium"}
          label={label}
          sx={{ ...chipSxStyle }}
          color={"primary"}
        />
      </Tooltip>
    </>
  );
};

/** svg-icon styled component from Mui 5, instead of using createStyles */
export const StyledComponent = styled("svg")(({ theme }) => ({
  width: "22px",
  height: "22px",
  marginLeft: 6,
  "& path": {
    fill: theme.palette.text.primary
  }
}));
