import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { RemoteApiError } from "../useRemote/types";
import { useTranslation } from "react-i18next";

interface RequestBody {
  name: string;
  status: string;
  uid: string;
  costPerHour?: number;
  equipmentCategoryIds?: number;
  placeInventoryId?: number | null;
  zoneInventoryId?: number | null;
}

export function useRemoteUpdateEquipment() {
  const { t } = useTranslation();
  return useRemoteMutation<void, RemoteApiError, RequestBody>({
    key: "update-equipment",
    url: "/equipment-inventory",
    method: "patch",
    snackbarTextOnSuccess: t("Successfully updated an equipment"),
    snackbarTextFunOnFail: error => `${(error as any).response.data.message}`,
    snackbarTextOnFail: t(`There was a server error`)
  });
}
