import { Box, Grid, Typography } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";
import React from "react";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
type P = {
  schedule: BookingScheduleInterface;
};

export const ScheduleEventExternalUser: React.FC<P> = ({ schedule }) => {
  return (
    <>
      {(schedule.externalName || schedule.externalEmail) && (
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <GroupAdd style={{ marginRight: "1rem" }} />
          <Box>
            <Typography data-testid="schedule-item-external" style={{ fontWeight: "bold" }}>
              {schedule?.externalName}
            </Typography>

            <Typography>{schedule?.externalCompany}</Typography>
            <Typography>{schedule?.externalEmail}</Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};
