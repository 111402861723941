import { Box, Typography } from "@mui/material";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { useTranslation } from "react-i18next";

type Props = {
  showMultiPlacesEdit: boolean;
  placeSchedules: IPlaceSchedule[];
  showHeader?: boolean;
};

export function MultiPlacesHeader({
  showMultiPlacesEdit,
  placeSchedules,
  showHeader = true
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      {showMultiPlacesEdit && (
        <Box sx={{ marginBottom: 1 }}>
          {/* title of multi places sidebar */}
          {showHeader && (
            <Typography
              sx={{ textAlign: "center", marginTop: 1, marginBottom: 1 }}
              component={"span"}
              variant="h6"
            >
              {t("Edit multiple places")}
            </Typography>
          )}
          {/* lists of selected places inventory name */}
          {placeSchedules.length <= 3 ? (
            placeSchedules.map(places => {
              return (
                <Typography key={places.id} sx={{ textAlign: "center" }} variant="subtitle2">
                  {checkPlacesName(places)}
                </Typography>
              );
            })
          ) : (
            <>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkPlacesName(placeSchedules[0])}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkPlacesName(placeSchedules[1])}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="subtitle2">
                {checkPlacesName(placeSchedules[2])}
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="body2">
                {t(`{{x}} more`, { x: placeSchedules.length - 3 })}
              </Typography>
            </>
          )}
        </Box>
      )}
    </>
  );
}

export function checkPlacesName(place: IPlaceSchedule) {
  return place.inventory?.name || `new place (${place.id})`;
}
