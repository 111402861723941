import { useReducer } from "react";
import { Badge, Collapse, Divider, List } from "@mui/material";
import {
  Badge as IconBadge,
  DesktopWindows,
  DevicesOther,
  GroupAdd,
  SupervisorAccountOutlined,
  Settings,
  Tune,
  AdminPanelSettings,
  PieChart,
  SettingsSuggest
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { useInquiryCount } from "../hooks/use-inquiry-count";
import { CapacityDrawerList } from "./capacity-drawer-list.component";
import { FacilityDrawerList } from "./facility-drawer-list.component";
import { ListItemLink } from "./list-item-link.component";
import { MainDrawerList } from "./main-drawer-list.component";
import { DrawerList } from "./drawer-list.component";
import { Offset } from "../ResponsiveDrawer/responsive-drawer-offset.component";

type S = {
  administration: boolean;
  capacity: boolean;
  bookingActivities: boolean;
};
type A =
  | { type: "administration/toggle"; open: boolean }
  | { type: "capacity/toggle"; open: boolean }
  | { type: "bookingActivities/toggle"; open: boolean };

const reducer: React.Reducer<S, A> = (state, action) => {
  switch (action.type) {
    case "administration/toggle":
      return { ...state, administration: action.open };
    case "capacity/toggle":
      return { ...state, capacity: action.open };
    case "bookingActivities/toggle":
      return { ...state, bookingActivities: action.open };
    default:
      throw new Error("Invalid dispatch action");
  }
};

type P = {
  onToggleDrawerMobile: () => void;
  /** whether the responsive drawer is open or collapsed */
  isOpen: boolean;
};

export const ResponsiveDrawerItems: React.FC<P> = ({ onToggleDrawerMobile, isOpen }) => {
  const { t } = useTranslation();
  const inquiryCount = useInquiryCount();

  const {
    userRoles: {
      isTenantAdmin,
      isCompanyAdmin,
      isEmployeeResponsible,
      isHrAdmin,
      isDeviceResponsible,
      isLocationAdmin,
      isCapacityResponsible
    }
  } = useSelector((state: RootState) => state.login);

  const [toggled, dispatch] = useReducer(reducer, {
    administration: false,
    capacity: false,
    bookingActivities: false
  });

  const DISPLAY_BOOKING_ACTIVITIES = false;
  const DISPLAY_SERVICE_MENU = false;
  const DISPLAY_WORKPLACE_CATEGORY_REPORT = false;

  const isTenantOrCompanyAdmin = isTenantAdmin || isCompanyAdmin;
  const isDisplayAdministrationList =
    isTenantOrCompanyAdmin ||
    isHrAdmin ||
    isDeviceResponsible ||
    isLocationAdmin ||
    isCapacityResponsible;

  const onToggleCapacityList = () => {
    dispatch({ type: "capacity/toggle", open: false });
  };

  const subListProps = {
    disablePadding: true,
    onClick: () => {
      onToggleDrawerMobile();
      onToggleCapacityList();
    },
    sx: { paddingLeft: isOpen ? "11%" : 0 }
  };

  return (
    <>
      <Offset />
      <Divider />

      {/* Home, Booking, Schedule */}
      <MainDrawerList
        onToggleDrawerMobile={onToggleDrawerMobile}
        toggleAdmin={() => {
          dispatch({ type: "administration/toggle", open: false });
          onToggleCapacityList();
        }}
      />

      {/* Connections */}
      <List
        disablePadding
        data-testid={"drawer-connection-toggle"}
        onClick={() => {
          onToggleDrawerMobile();
          dispatch({ type: "administration/toggle", open: false });
          onToggleCapacityList();
        }}
      >
        <ListItemLink
          id="connections"
          data-testid={"drawer-connections-toggle"}
          to={"/connections"}
          primary={t("Connections")}
          icon={
            /** MuiBadge-invisible when is not mobile view */
            <Badge badgeContent={inquiryCount} color="primary">
              <GroupAdd />
            </Badge>
          }
        />
      </List>

      {/* Supervisor */}
      <List
        disablePadding
        data-testid={"drawer-supervisor-toggle"}
        onClick={() => {
          onToggleDrawerMobile();
          dispatch({ type: "administration/toggle", open: false });
          onToggleCapacityList();
        }}
      >
        {isEmployeeResponsible && (
          <ListItemLink
            id="supervisor"
            to={"/supervisor"}
            primary={t("Supervisor")}
            data-testid={"drawer-supervisor-toggle"}
            icon={<SupervisorAccountOutlined />}
          />
        )}
      </List>

      <Divider sx={{ margin: "5px 0" }} />

      {/* List of items following to user roles, i.e. location admin, hr admin, etc. */}
      <List disablePadding>
        {isDisplayAdministrationList && (
          <ListItemLink
            id="administration"
            to={"/administration"}
            primary={t("Drawer_Administration")}
            data-testid="drawer-administration-toggle"
            icon={<Settings />}
            onClick={e => {
              e.preventDefault();
              dispatch({ type: "administration/toggle", open: !toggled.administration });
              onToggleCapacityList();
            }}
            isToggled={toggled.administration}
          />
        )}

        <Collapse in={toggled.administration}>
          {/* Organization - Company, Department, and Users */}
          <List {...subListProps} data-testid={"drawer-organization-toggle"}>
            {isTenantOrCompanyAdmin && (
              <ListItemLink
                id="organization"
                to={"/admin/organization"}
                primary={t("Organization")}
                data-testid={"drawer-organization-toggle"}
                icon={<Tune />}
              />
            )}
          </List>

          {/* Buildings - create/edit the location, floor, and the floor plan */}
          {/* isLocationAdmin || isCompanyAdmin || isTenantAdmin */}
          <FacilityDrawerList
            onToggleDrawerMobile={onToggleDrawerMobile}
            onToggleCapacityList={onToggleCapacityList}
            isOpen={isOpen}
          />

          {/* Inventory */}
          <List {...subListProps} data-testid={"drawer-inventory-toggle"}>
            {isDeviceResponsible && (
              <ListItemLink
                id="inventory"
                to={"/equipment/inventory"}
                primary={t("Drawer_Inventory")}
                data-testid={"drawer-inventory-toggle"}
                icon={<DevicesOther />}
              />
            )}
          </List>

          {/* Service - Conferencezone, Catering, and more */}
          {/* not used currently */}
          <List {...subListProps} data-testid={"drawer-service-toggle"}>
            {DISPLAY_SERVICE_MENU && (
              <ListItemLink
                id="service"
                to={"/service"}
                primary={t("Drawer_Services")}
                data-testid={"drawer-service-toggle"}
                icon={<SettingsSuggest />}
              />
            )}
          </List>

          {/* Capacity Report */}
          {/* isCapacityResponsible */}
          <CapacityDrawerList
            onToggleDrawerMobile={onToggleDrawerMobile}
            onToggleList={() => dispatch({ type: "capacity/toggle", open: true })}
            onCloseCapacityList={onToggleCapacityList}
            isToggled={toggled.capacity}
            isOpen={isOpen}
          />

          {DISPLAY_WORKPLACE_CATEGORY_REPORT && (
            <List {...subListProps} data-testid={"drawer-category-report-toggle"}>
              {isDeviceResponsible && (
                <ListItemLink
                  id="workplace-category-report"
                  to={"/equipment/capacity"}
                  primary={t("Drawer_Category Report")}
                  data-testid="drawer-category-report-toggle"
                  icon={<PieChart />}
                />
              )}
            </List>
          )}

          {/* HR */}
          <List data-testid={"drawer-hr-toggle"} {...subListProps}>
            {isHrAdmin && (
              <ListItemLink
                id={"hr-report"}
                primary={t("HR")}
                to={"/hr-report"}
                data-testid={"drawer-hr-toggle"}
                icon={<IconBadge />}
              />
            )}
          </List>

          {/* Administrators */}
          <List {...subListProps} data-testid={"drawer-administratiors-toggle"}>
            {isTenantOrCompanyAdmin && (
              <ListItemLink
                id={"administrators"}
                to={"/admin/admins"}
                primary={t("Administrators")}
                data-testid={"drawer-administrators-toggle"}
                icon={<AdminPanelSettings />}
              />
            )}
          </List>

          {/* Booking Activities, not used currently */}
          {DISPLAY_BOOKING_ACTIVITIES && (
            <DrawerList
              onToggleDrawerMobile={onToggleDrawerMobile}
              onToggleList={() => {
                dispatch({ type: "bookingActivities/toggle", open: false });
                onToggleCapacityList();
              }}
              isToggled={toggled.bookingActivities}
              isOpen={isOpen}
              icon={<DesktopWindows />}
              name={"booking-activities"}
              link1name={"occupation"}
              link2name={"contact-tracing"}
            />
          )}
        </Collapse>
      </List>
    </>
  );
};
