import React, { useEffect, useState } from "react";
import { Button, Fade, MobileStepper, Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { getUserExistingZoneBooking } from "../../utils/axios-requests";
import { PricingModel } from "../Login/typings/login.types";
import {
  nextStepCalc,
  placeMode,
  prevStepCalc,
  zoneMode
} from "./functions/booking-form.functions";
import { clearEquipment, setInputs } from "./slices/booking.slice";
import StepContent from "./step-content.component";
import { BookingType, Frequence, NotSerializedBookingInputs } from "./typings/booking-inputs";
import { bookingRestrictionsResponse } from "./typings/schema";

/**
 * @description The booking form contains the main inputs of the booking process (state)
 * @version 0.1.1
 */

const basePath = "/booking/"; // Should not hardcode this

export const validateDateRange = (
  inputs: NotSerializedBookingInputs,
  picker: boolean,
  validDates: boolean
): boolean => {
  if (
    inputs.frequence &&
    [Frequence.DAILY, Frequence.WEEKLY].includes(inputs.frequence) &&
    (inputs.interval === undefined || inputs.interval <= 0)
  )
    return true;
  if (picker) {
    return !validDates || !inputs.timeSpanBigEnough;
  } else {
    return (
      !validDates || !Object.values(inputs.weekdays).includes(true) || !inputs.timeSpanBigEnough
    );
  }
};

export const BookingForm: React.FC = () => {
  const { inputs } = useSelector((state: RootState) => state.booking);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const prefLocations = useSelector((state: RootState) => state.login.settings.preferredLocations);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const dispatch = useDispatch();

  const [validDates, setValidDates] = useState(false);
  const [adjustedData, setAdjustedData] = useState<any>();
  const [picker, setPicker] = useState(false);
  const [preferredLocations, setPreferredLocations] = useState([0]);

  const { step }: { step: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (prefLocations) setPreferredLocations(prefLocations.map(l => Number(l)));
  }, [prefLocations]);

  useEffect(() => {
    if (!inputs.userBookedBy) dispatch(setInputs({ userBookedBy: userInformation.sub }));
  }, [dispatch, inputs.userBookedBy, userInformation.sub]);

  useEffect(() => {
    dispatch(clearEquipment());
  }, [dispatch, inputs.mode]);
  /*
   * Functions
   */

  const nextStep = (to?: number) =>
    history.push(basePath + nextStepCalc(parseInt(step), to).toString(), { prev: parseInt(step) });

  const prevStep = (to?: number) =>
    history.push(basePath + prevStepCalc(parseInt(step), inputs, dispatch, to).toString(), {
      prev: parseInt(step)
    });

  function getSteps() {
    switch (inputs.mode) {
      case BookingType.WORKPLACE:
        return [t("Mode"), t("Date"), t("Location"), t("Workplace")];
      case BookingType.PARKINGPLACE:
        return [t("Mode"), t("Date and Time"), t("Location"), t("Place")];
      case BookingType.ELECTRICCHARGINGSTATIONPLACE:
        return [t("Mode"), t("Date and Time"), t("Location"), t("Station")];
      case BookingType.TEAM:
        return [t("Mode"), t("Date and Time"), t("Team"), t("Location"), t("Workplace")];
      case BookingType.PLACEZONE:
        return [t("Mode"), t("Date"), t("Location"), t("Zone"), t("Restriction")];
      case BookingType.CONFERENCEZONE:
        return [t("Mode"), t("Invite"), t("Date"), t("Location"), t("Zone")];
      default:
        return [t("Mode"), t("Date and Time"), t("Location"), t("Zone")];
    }
  }

  const steps = getSteps();

  /**
   * @description Checks existing bookings and takes action based on result.
   * - If there are conflicts, open conflict resolve modal
   * - If there are no conflicts, continue to the next step
   * - If there is an error getting conflicts, continue to the next step
   */

  // This whole component needs refactorings
  const checkExistingBookings = () => {
    if (inputs.mode == BookingType.PLACEZONE)
      getUserExistingZoneBooking({
        zoneBookingUsers: inputs.usersBookedFor,
        zoneBookingObject: inputs.restrictionObject.object.zoneBookingObject,
        zoneBookingObjectType: inputs.restrictionObject.object.type,
        zoneBookingObjectClient: inputs.restrictionObject.company.id,
        users: inputs.usersBookedFor!.map(el => ({
          userId: el.userId,
          isExternal: false
        })),
        startDate: inputs.bookingFrom,
        endDate: inputs.bookingTo,
        startTime: inputs.bookingStart,
        endTime: inputs.bookingEnd,
        bookingType: inputs.bookingType,
        weekdays: inputs.weekdays
      })
        .then(res => {
          if (res.data.length) {
            const data = bookingRestrictionsResponse.try(res.data);
            if (data instanceof Error) {
              enqueueSnackbar(t("Could not check your existing bookings"), { variant: "warning" });
            } else {
              const adjustedDataMap = data.map(o => ({
                ...o,
                start: o.start,
                end: o.end
              }));
              setAdjustedData(adjustedDataMap);
            }
          } else {
            nextStep();
          }
        })
        .catch(() => enqueueSnackbar(t("errorRetrievingExistingZoneBooking")));
  };

  const step1 = () => {
    if (BookingType.CONFERENCEZONE === inputs.mode) {
      return !inputs.subject;
    }

    // prohibit continuation when the interval field is empty while daily or weekly are selected
    return validateDateRange(inputs, picker, validDates);
  };

  const step2 = () => {
    if (BookingType.CONFERENCEZONE === inputs.mode) {
      return validateDateRange(inputs, picker, validDates);
    }

    if (inputs.mode !== "team") {
      return true;
    } else {
      if (inputs.usersBookedFor) return inputs?.usersBookedFor?.length < 1;
      else return true;
    }
  };

  const step3 = () => {
    if (inputs.mode !== "team") {
      return inputs.usersBookedFor!.some(({ bookingInventoryId }) => !bookingInventoryId);
    } else {
      return true;
    }
  };

  const step4 = () => {
    if (placeMode(inputs)) {
      return true;
    } else if (zoneMode(inputs)) {
      return inputs.mode === BookingType.PLACEZONE &&
        userInformation.pricingModels.includes(PricingModel.ENTERPRISE)
        ? !inputs.restrictionObject?.object?.zoneBookingObject
        : !inputs.zoneAccess;
    } else if (inputs.mode === "team") {
      return inputs.usersBookedFor!.some(({ bookingInventoryId }) => !bookingInventoryId);
    } else return;
  };
  const step5 = !placeMode(inputs);

  const getDisabled = () => {
    switch (step) {
      case "0":
        return true;
      case "1":
        return step1();
      case "2":
        return step2();
      case "3":
        return step3();
      case "4":
        return step4();
      case "5":
        return step5;
    }
    return true;
  };

  useEffect(() => {
    const s = parseInt(step);
    if (s > 0 && !inputs.mode) history.push(basePath + "0");
  }, [history, inputs.mode, step]);

  return (
    <>
      {Number(step) >= 1 && (
        <>
          <Fade in>
            <Stepper
              data-testid="booking-form-stepper"
              activeStep={Number(step)}
              sx={{
                display: { xs: "none", md: "flex" },
                padding: "24px",
                backgroundColor: theme.palette.background.paper
              }}
            >
              {steps.map(label => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Fade>
          <Fade in>
            <MobileStepper
              style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "10px" }}
              variant="dots"
              steps={getSteps().length}
              position="static"
              activeStep={Number(step)}
              nextButton={
                <Button
                  data-testid="next-button"
                  size="small"
                  onClick={() => {
                    if (step === "4" && inputs.mode === BookingType.PLACEZONE)
                      checkExistingBookings();
                    else nextStep();
                  }}
                  color={"primary"}
                  disabled={getDisabled()}
                >
                  {t("Next")}
                  {<KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button
                  data-testid="back-button"
                  disabled={step === "0" || Number(step) === getSteps().length + 1}
                  size="small"
                  color="secondary"
                  onClick={() => prevStep()} /* disabled={activeStep === 0} */
                >
                  <KeyboardArrowLeft />
                  {t("Back")}
                </Button>
              }
            />
          </Fade>
        </>
      )}
      <StepContent
        step={step}
        nextStep={nextStep}
        prevStep={prevStep}
        preferredLocations={preferredLocations}
        setPicker={setPicker}
        picker={picker}
        adjustedData={adjustedData}
        setValidDates={setValidDates}
        validDates={validDates}
      />
    </>
  );
};

export default BookingForm;
