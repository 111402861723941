import React, { useEffect } from "react";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EventBusy } from "@mui/icons-material";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useRemoteDeleteBookingGuest } from "../../hooks/Remote/Booking/useRemoteDeleteBookingGuest";

type P = {
  userId: string;
  schedule: BookingScheduleInterface;
  calendarData: MbscCalendarEvent[];
  onCalendarDataChange: (events: MbscCalendarEvent[]) => void;
  onClose: () => void;
  refetchAllSchedule: () => void;
};

export const ScheduleEventBookingHeaderGuestContents: React.FC<P> = ({
  userId,
  schedule,
  calendarData,
  onCalendarDataChange,
  onClose,
  refetchAllSchedule
}) => {
  const { t } = useTranslation();
  const guestId = schedule.guests.find(guest => guest.userId === userId)?.id;

  const { mutate: deleteBookingGuest, status: deleteBookingGuestStatus } =
    useRemoteDeleteBookingGuest(guestId || 0);

  const handleDeleteBookingGuest = () => {
    if (!guestId) return;
    deleteBookingGuest(undefined);
  };

  const handleDeleteBookingGuestSuccess = () => {
    const index = calendarData.findIndex(c => c.id === schedule.id);
    if (index >= 0) {
      const newCalendarData = calendarData.slice();
      newCalendarData.splice(index, 1);
      onCalendarDataChange(newCalendarData);
      onClose();
    }
    refetchAllSchedule();
  };

  useEffect(() => {
    if (deleteBookingGuestStatus === "success") handleDeleteBookingGuestSuccess();
  }, [deleteBookingGuestStatus]);

  return <>
    <Grid item sx={{ ml: "auto" }}>
      <Typography>{t("You are a guest")}</Typography>
    </Grid>
    {DateTime.fromISO(schedule.startDate).valueOf() > DateTime.now().valueOf() && (
      <Grid item>
        <Tooltip title={t("Reject invitation") as string}>
          <IconButton
            sx={{ color: "inherit" }}
            onClick={handleDeleteBookingGuest}
            data-testid="schedule-guest-busy-btn"
            size="large">
            <EventBusy />
          </IconButton>
        </Tooltip>
      </Grid>
    )}
  </>;
};
