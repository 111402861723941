import { Button, Grid, useTheme } from "@mui/material";
import { Check } from "@mui/icons-material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { attemptCheckin, handleCheckinResponse } from "../../features/Checkin/functions/checkin";
import { ScheduleEventCheckoutButton } from "./ScheduleEventCheckoutButton/schedule-event-checkout-button.component";
import { useSnackbar } from "notistack";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { useRemoteCheckin } from "../../hooks/Remote/Checkin/useRemoteCheckin";
import { ScheduleTodayBookingsDialog } from "./ScheduleTodayBookings/schedule-today-bookings-dialog.component";
import { handleTodayBookings } from "./ScheduleTodayBookings/schedule-today-bookings-dialog.functions";

type P = {
  selectedSchedule: BookingScheduleInterface;
  allSchedules?: BookingScheduleInterface[];
  refetchAllSchedule: () => void;
};

/**
 * displays the checkin button
 * TODO separate checkin button and checkout button
 * TODO separate conditions to show buttons to a hook
 * @param schedule
 * @constructor
 */
export const ScheduleEventCheckinButton: React.FC<P> = ({
  selectedSchedule,
  allSchedules,
  refetchAllSchedule
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    meta: { checkinSettings }
  } = useSelector((state: RootState) => state.login.userInformation.company);

  // Show today bookings conflict dialog
  const [showTodayBookingDialog, setShowTodayBookingDialog] = useState(false);

  // check the selected schedule has checkin conflicts
  const { updated, deleted } = handleTodayBookings(allSchedules, selectedSchedule);

  // BE takes care that check in is enabled and only returns checkin period if enabled + today.
  // Furthermore the acutal buffer time is also completely calculated in BE (location > company time, but company is fallback)
  const shouldShowCheckInButton = useMemo(() => {
    const checkinBufferTimePerBookingType =
      checkinSettings.find(s => s.bookingType === selectedSchedule.bookingType)
        ?.checkinBufferTime || selectedSchedule.checkInPeriod;

    return (
      !selectedSchedule.checkedIn &&
      checkinBufferTimePerBookingType > 0 &&
      selectedSchedule.checkInPeriod !== -1
    );
  }, [
    checkinSettings,
    selectedSchedule.checkedIn,
    selectedSchedule.endDate,
    selectedSchedule.locationName,
    selectedSchedule.startDate
  ]);

  const shouldShowCheckedInConfirmation = useMemo(
    () =>
      selectedSchedule.checkedIn &&
      DateTime.fromISO(selectedSchedule.startDate).valueOf() < DateTime.now().valueOf() &&
      DateTime.fromISO(selectedSchedule.endDate).valueOf() < DateTime.now().valueOf(),
    [selectedSchedule.checkedIn, selectedSchedule.endDate, selectedSchedule.startDate]
  );

  const {
    mutate: mutateCheckin,
    error: checkinError,
    status: checkinStatus,
    data: checkinRes
  } = useRemoteCheckin();

  useEffect(() => {
    const checkinSetting = checkinSettings.find(
      s => s.bookingType === selectedSchedule.bookingType
    );

    handleCheckinResponse(
      {
        checkinRes,
        checkinError,
        isCheckinPerBookingType: Boolean(checkinSetting),
        geoLocationRequired: Boolean(checkinSetting?.geoLocationRequired),
        checkinOnTheFly: false
      },
      { t, enqueueSnackbar, refetchAllSchedule, setShowDialog: setShowTodayBookingDialog }
    );
  }, [checkinError, checkinStatus, checkinRes]);

  return (
    <>
      {shouldShowCheckedInConfirmation ? (
        <Grid item data-testid="schedule-item-checkedin-btn-grid">
          <Button
            disabled
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "100%",
                padding: "5px 10px",
                fontSize: "0.725rem"
              }
            }}
          >
            <Check />
            {t("Checked In")}
          </Button>
        </Grid>
      ) : (
        <ScheduleEventCheckoutButton
          schedule={selectedSchedule}
          refetchAllSchedule={refetchAllSchedule}
        />
      )}
      {shouldShowCheckInButton && (
        <Grid item xs={5} data-testid="schedule-item-checkin-btn-grid">
          <Button
            data-testid={"schedule-event-checkin-button"}
            onClick={() => {
              if (updated.length || deleted.length) return setShowTodayBookingDialog(true);

              attemptCheckin(
                selectedSchedule.id,
                selectedSchedule.bookingType as BookingType,
                checkinSettings,
                mutateCheckin,
                { t, enqueueSnackbar }
              );
            }}
            color="primary"
            variant="contained"
            sx={{
              height: "100%",
              width: "100%",
              [theme.breakpoints.down("md")]: {
                padding: "5px 10px",
                fontSize: "0.7rem"
              }
            }}
          >
            {t("Check In")}
          </Button>
        </Grid>
      )}
      {/* handle when multiple numbers of today bookings exist and try to checkin */}
      <ScheduleTodayBookingsDialog
        selectedSchedule={selectedSchedule}
        schedules={allSchedules}
        isOpen={showTodayBookingDialog}
        onClose={() => setShowTodayBookingDialog(false)}
        onConfirm={() => {
          attemptCheckin(
            selectedSchedule.id,
            selectedSchedule.bookingType as BookingType,
            checkinSettings,
            mutateCheckin,
            { t, enqueueSnackbar }
          );
        }}
      />
    </>
  );
};
