import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useTheme
} from "@mui/material";
import { DropzoneAreaBase, FileObject } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AxiosResponse } from "axios";
import { ReactComponent as ExcelIcon } from "../../features/FloorManager/assets/xlsx-file-format-extension.svg";
import { rejectFile } from "../EquipmentList/equipmentlist.functions";

interface OwnProps {
  uploadCall: (excel: FileObject) => Promise<AxiosResponse<any>> | undefined;
  initialFiles?: FileObject[];
  triggerCall?: () => void;
  onFinishUpload?: () => void;
}

type Props = OwnProps;

const ExcelUploadModal: FunctionComponent<Props> = ({
  uploadCall,
  initialFiles,
  triggerCall,
  onFinishUpload
}) => {
  const [files, setFiles] = useState<FileObject[]>(initialFiles || []); // vx
  const [excelOpen, setExcelOpen] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  async function handleClickUpload() {
    setExcelOpen(false);
    if (!files || !files.length || files[0] === undefined) return;

    // @ts-ignore
    try {
      await uploadCall(files[0]);
      onFinishUpload?.();
      if (triggerCall) triggerCall();
    } catch (e: unknown) {
      const error = e as any;
      const message = error?.response?.data?.message;
      enqueueSnackbar(t(`${message}`), {
        variant: "error"
      });
    }
  }

  /**
   * not supported
   * ".csv",
   * "text/csv",
   * "application/csv",
   * "text/x-csv",
   * "application/x-csv",
   */
  const acceptableFiles = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel.sheet.macroEnabled.12"
  ];

  return (
    <>
      <Tooltip title={t("Import") as string}>
        <IconButton
          onClick={() => setExcelOpen(true)}
          aria-label="delete"
          style={{ padding: "0px 10px" }}
          color="primary"
          size="large"
        >
          <ExcelIcon style={{ width: 20, height: 20, fill: theme.palette.text.primary }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={excelOpen}
        keepMounted
        onClose={() => setExcelOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">{t("Import data from Excel")}</DialogTitle>
        <DialogContent>
          <DropzoneAreaBase
            acceptedFiles={acceptableFiles}
            showPreviewsInDropzone
            previewGridProps={{ container: { spacing: 1, direction: "row" } }}
            dropzoneText={t("Drop excel or click to upload") + " " + "(.xlsx, .xls)"}
            maxFileSize={2000000}
            filesLimit={1}
            data-testid={"inputDrop"}
            onDropRejected={e => rejectFile(e)}
            showAlerts={false}
            fileObjects={files}
            onAdd={f => setFiles(f)}
            onDelete={() => {
              setFiles([]);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="excel-upload-btn-close"
            onClick={() => setExcelOpen(false)}
            variant="outlined"
          >
            {t("Close")}
          </Button>
          {files && files.length > 0 && (
            <Button
              data-testid="excel-upload-btn-upload"
              onClick={handleClickUpload}
              color="primary"
              variant="contained"
            >
              {t("Upload")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExcelUploadModal;
