import { IconButton } from "@mui/material";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { Delete } from "@mui/icons-material";
import React, { useEffect } from "react";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { useRemoteInactivateBookings } from "../../hooks/Remote/Booking/useRemoteInactivateBookings";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";

type P = {
  schedule: BookingScheduleInterface;
  calendarData: MbscCalendarEvent[];
  onCalendarDataChange: (events: MbscCalendarEvent[]) => void;
  onClose: () => void;
  setDeletingBookings?: (d: boolean) => void;
};

export const ScheduleEventDeleteButton: React.FC<P> = ({
  schedule,
  calendarData,
  onCalendarDataChange,
  onClose,
  setDeletingBookings
}) => {
  const { mutate: inactivateBookings, status: inactivateBookingsStatus } =
    useRemoteInactivateBookings();

  const handleInactiveBookings = () => {
    setDeletingBookings?.(true);
    inactivateBookings({
      bookings: [
        {
          deleted: false,
          bookingIds: [schedule.id],
          bookingType: schedule.bookingType as BookingType
        }
      ]
    });
  };

  const handleInactivateSuccess = () => {
    const index = calendarData.findIndex(c => c.id === schedule.id);
    if (index >= 0) {
      const newCalendarData = calendarData.slice();
      newCalendarData.splice(index, 1);
      onCalendarDataChange(newCalendarData);
      onClose();
    }
    setDeletingBookings?.(false);
  };

  useEffect(() => {
    if (inactivateBookingsStatus === "success") handleInactivateSuccess();
  }, [inactivateBookingsStatus]);

  return (
    <IconButton
      data-testid="schedule-item-delete-btn"
      onClick={handleInactiveBookings}
      size="large"
    >
      <Delete />
    </IconButton>
  );
};
