import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { getFilteredEquipmentInventories } from "../../../utils/axios-requests";
import { setEquipment, setUserEquipment } from "../slices/booking.slice";
import { FilterDto } from "../typings/inputs-filter";

type Option = {
  id: number;
  name: string;
};

type P = {
  filter: FilterDto;
};

const EquipmentFilter: React.FC<P> = ({ filter }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bookingStateDevices = useSelector(
    (state: RootState) => state.booking.inputs.equipment?.equipmentIds
  );
  const [options, setOptions] = useState<Option[]>([]);
  const [selected, setSelected] = useState<Option[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch devices
  useEffect(() => {
    getFilteredEquipmentInventories(filter).then(res => {
      const optionsResponse = res.data as Option[];
      setOptions(optionsResponse);
      // See if filters already selected in redux state
      if (bookingStateDevices !== undefined) {
        setSelected(optionsResponse.filter(option => bookingStateDevices.includes(option.id)));
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update booking state on options change
  useEffect(() => {
    if (!loading) {
      const newSelected = selected.map(s => s.id);
      if (JSON.stringify(bookingStateDevices) !== JSON.stringify(newSelected)) {
        const deviceIds = selected.map(s => s.id);
        dispatch(
          setEquipment({
            deviceIds
          })
        );
        dispatch(
          setUserEquipment({
            userIndex: 0,
            equipment: {
              deviceIds
            }
          })
        );
      }
    }
  }, [dispatch, selected, loading, bookingStateDevices]);

  return (
    <Autocomplete
      disabled={loading}
      id="devices-filter"
      multiple
      options={options}
      value={selected}
      onChange={(event, newValue) => {
        setSelected(newValue);
      }}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          data-testid="devices-filter-field"
          {...params}
          variant="standard"
          label={t("Devices")}
        />
      )}
    />
  );
};

export default EquipmentFilter;
