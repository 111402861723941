import { BookingType } from "../../../../../features/Booking-Form/typings/booking-inputs";
import { CapacityObject } from "../../../../../features/Reports/typings/reports.types";
import { IFloorPayload } from "../../../Domain/Types/FloorPlan/FloorPayload.type";
import { IPlaceSchedule } from "../../../Domain/Types/FloorPlan/PlaceSchedule";
import { colorStringToHex } from "../../../Functions/colorStringToHex";

/** return place booking type enum from type id */
export function findPlaceBookingType(typeId: number) {
  switch (typeId) {
    case 1:
      return BookingType.WORKPLACE;
    case 2:
      return BookingType.PARKINGPLACE;
    case 4:
      return BookingType.ELECTRICCHARGINGSTATIONPLACE;
    case 0:
    default:
      return BookingType.WORKPLACE;
  }
}

/** return zone booking type enum from type id */
export function findZoneBookingType(typeId: number) {
  switch (typeId) {
    case 1:
      return BookingType.PLACEZONE;
    case 2:
      return BookingType.OPENSPACE;
    case 3:
      return BookingType.CONFERENCEZONE;
    case 4:
      return BookingType.RESTRICTED;
    case 0:
    default:
      return BookingType.PLACEZONE;
  }
}

// when selects the zone, find the most places' type and return it to fetch based on the most type
export function findMostPlaceType(highlightedPlaces: IPlaceSchedule[]) {
  const wpLength = {
    id: BookingType.WORKPLACE,
    length: highlightedPlaces.filter(h => h.inventory?.placeTypeId === 1).length
  };
  const parkLength = {
    id: BookingType.PARKINGPLACE,
    length: highlightedPlaces.filter(h => h.inventory?.placeTypeId === 2).length
  };
  const elecLength = {
    id: BookingType.ELECTRICCHARGINGSTATIONPLACE,
    length: highlightedPlaces.filter(h => h.inventory?.placeTypeId === 4).length
  };

  if (wpLength.length >= parkLength.length && wpLength.length >= elecLength.length)
    return wpLength.id;

  if (parkLength.length >= elecLength.length) return parkLength.id;

  return elecLength.id;
}

/** check whether workplace is disabled */
function checkPlaceDisabled(inventoryId: number, floorPlan: IFloorPayload) {
  return floorPlan.places
    .filter(wp => wp.inventoryId === inventoryId)
    .some(wp => wp.disabled === true);
}
/** check whether zone is disabled */
function checkZoneDisabled(inventoryId: number, floorPlan: IFloorPayload) {
  return floorPlan.zones
    .filter(zn => zn.inventoryId === inventoryId)
    .some(zn => zn.disabled === true);
}

/* return color overlay value by capacity rates */
export function generateColorOverlay({
  type,
  inventoryId,
  reportData,
  floorPlan
}: {
  type: "zone" | "place";
  inventoryId: number | undefined;
  reportData: (CapacityObject | undefined)[] | undefined;
  floorPlan: IFloorPayload;
}) {
  if (!reportData || !inventoryId) return;

  if (type === "place" && checkPlaceDisabled(inventoryId, floorPlan)) return 0;
  if (type === "zone" && checkZoneDisabled(inventoryId, floorPlan)) return 0;

  // find the related report data by searching for inventory id
  const findReportData = reportData.find(data =>
    data?.capacity.capacityPerformancePerWorkplace.find(
      capaPerWp => capaPerWp.bookingObject === inventoryId
    )
  );
  // in the report data above, find the capacity rates by inventory id
  const capa = findReportData?.capacity.capacityPerformancePerWorkplace.find(
    ca => ca.bookingObject === inventoryId
  )?.capacityRate;

  if (capa && capa !== 0) {
    const hexColor = colorStringToHex(capa);
    return parseInt(hexColor);
  }
  return 0xe0f8d5;
}

export function renderReportPlaceBySelected(
  floorPlan: IFloorPayload,
  selectedDesks: number[],
  onClickReportPlace?: (inventoryIds: number[], names: string[], placeTypes: BookingType[]) => void
) {
  if (selectedDesks.length) {
    const selectedWps = floorPlan.places.filter(p => selectedDesks.includes(p.id));
    const inventoryIds = selectedWps.map(p => p.inventoryId || p.inventory?.id || 0);
    const names = selectedWps.map(p => p.inventory?.name || "");
    const types = selectedWps.map(p => p.inventory?.placeTypeId || 0);
    const placeTyp = findPlaceBookingType(types[0]);

    onClickReportPlace && onClickReportPlace(inventoryIds, names, [placeTyp]);
  }
}

export function renderReportZoneBySelected(
  floorPlan: IFloorPayload,
  selectedZones: number[],
  highlightedPlace: IPlaceSchedule[],
  onClickReportZone?: (
    inventoryIds: number[],
    names: string[],
    zoneTypes: BookingType[],
    highlightedPlaces: IPlaceSchedule[]
  ) => void
) {
  if (selectedZones.length) {
    const selectedZns = floorPlan.zones.filter(z => selectedZones.includes(z.id));
    const inventoryIds = selectedZns.map(z => z.inventoryId || z.inventory?.id || 0);
    const names = selectedZns.map(z => z.inventory?.name || "");
    const types = selectedZns.map(z => z.inventory?.zoneTypeId || 0);
    const zoneTyp = findZoneBookingType(types[0]);

    onClickReportZone && onClickReportZone(inventoryIds, names, [zoneTyp], highlightedPlace);
  }
}
