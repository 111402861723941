import { UseMutateFunction } from "react-query";
import { RemoteApiError } from "../../../hooks/Remote/useRemote/types";
import { CheckinDto, CheckinResponseDto } from "../typings";
import { TFunction } from "i18next";
import { ProviderContext } from "notistack";
import { BookingType } from "../../Booking-Form/typings/booking-inputs";
import { CheckInSettings } from "../../Login/typings/login.types";

export type MutateCheckin = UseMutateFunction<
  CheckinResponseDto,
  RemoteApiError,
  CheckinDto,
  unknown
>;

/** Attempts to checkin into a booked workplace or conference zone */
export function attemptCheckin(
  scheduleId: number,
  bookingType: BookingType,
  checkinSettings: CheckInSettings[],
  mutateCheckin: MutateCheckin,
  { t, enqueueSnackbar }: { t: TFunction; enqueueSnackbar: ProviderContext["enqueueSnackbar"] }
) {
  const geoRequired = checkinSettings.find(
    setting => setting.bookingType === bookingType
  )?.geoLocationRequired;

  navigator.geolocation.getCurrentPosition(
    position => {
      mutateCheckin({
        latitude: geoRequired ? position.coords.latitude : 0,
        longitude: geoRequired ? position.coords.longitude : 0,
        bookingId: scheduleId,
        bookingType
      });
    },
    () => {
      enqueueSnackbar(t("_checkin_enableGeolocationToCheckin"), {
        variant: "error"
      });
    }
  );
}

/** handle checkin response */
export function handleCheckinResponse(
  state: {
    checkinRes: CheckinResponseDto | undefined;
    checkinError: RemoteApiError | null;
    isCheckinPerBookingType: boolean;
    geoLocationRequired: boolean;
    checkinOnTheFly: boolean;
    isSameTimeNow?: boolean;
  },
  action: {
    t: TFunction;
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
    refetchAllSchedule?: () => void;
    setShowDialog?: (s: boolean) => void;
  }
) {
  const {
    checkinRes,
    checkinError,
    isCheckinPerBookingType,
    geoLocationRequired,
    checkinOnTheFly,
    isSameTimeNow
  } = state;
  const { t, enqueueSnackbar, refetchAllSchedule, setShowDialog } = action;

  // handle checkin Error
  handleCheckinError(checkinError, t, enqueueSnackbar);

  // handle response
  if (checkinRes) {
    const { checkedIn, withinRange, metersRemaining } = checkinRes;
    const kmRemaining = (metersRemaining / 1000).toFixed(2);

    // handle not within range
    if (!withinRange && geoLocationRequired) {
      return enqueueSnackbar(t("_checkin_warnMsgMetersRemaining", { distance: kmRemaining }), {
        variant: "warning"
      });
    }

    // handle checkedIn
    handleCheckedIn(
      { checkedIn, isCheckinPerBookingType, checkinOnTheFly, isSameTimeNow },
      { setShowDialog, refetchAllSchedule, t, enqueueSnackbar }
    );
  }
}

function handleCheckinError(
  checkinError: RemoteApiError | null,
  t: TFunction,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  // error messages
  const errMsgAlreadyCheckedIn = "Booking is already checked in!";
  const errMsgNotWithinRange = "User is not in radius to check in!";
  const errMsgBookingCollision = "Check-In denied due to collision with previous booking!";
  const errMsgBufferElapsed = "Check-In not possible buffer time elapsed!";

  if (checkinError) {
    switch (checkinError.response?.data?.message) {
      case errMsgAlreadyCheckedIn:
        return enqueueSnackbar(t("_checkin_errMsgAlreadyCheckedIn"), { variant: "error" });
      case errMsgNotWithinRange:
        return enqueueSnackbar(t("_checkin_errMsgNotWithinRange"), {
          variant: "error"
        });
      case errMsgBookingCollision:
        return enqueueSnackbar(t("_checkin_errMsgBookingCollision"), {
          variant: "error"
        });
      case errMsgBufferElapsed:
        return enqueueSnackbar(t("_checkin_errMsgBufferElapsed"), {
          variant: "error"
        });
      default:
        return enqueueSnackbar(t("_checkin_errMsgUnknown"), { variant: "error" });
    }
  }
}

/**
 * handle success and return snackbar message
 * according to, if spontaneous booking via QR-code with check-in setting per booking type or only checked-in via QR-Code
 * window.location.reload(); is removed
 */
function handleCheckedIn(
  state: {
    checkedIn: boolean;
    isCheckinPerBookingType: boolean;
    checkinOnTheFly: boolean;
    isSameTimeNow?: boolean;
  },
  action: {
    setShowDialog: ((s: boolean) => void) | undefined;
    refetchAllSchedule: (() => void) | undefined;
    t: TFunction;
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  }
) {
  const { checkedIn, isCheckinPerBookingType, checkinOnTheFly, isSameTimeNow } = state;
  const { setShowDialog, refetchAllSchedule, t, enqueueSnackbar } = action;
  if (checkedIn) {
    const checkinOnTheFlyMsg =
      isCheckinPerBookingType && isSameTimeNow
        ? t("_checkin_bookOnTheFly_success") // "Booked and checked-in successfully",
        : t("_onlyBook_success"); // "Booked successfully",
    const successMsg = checkinOnTheFly ? checkinOnTheFlyMsg : t("_checkin_success"); // "Successfully checked in",
    enqueueSnackbar(successMsg, { variant: "success" });
    setShowDialog?.(false);
    refetchAllSchedule?.();
    return;
  }
}
