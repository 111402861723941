import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultSpinner from "../../components/LoadingSpinner/default-spinner.component";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { DisplayFloorPlanView } from "../FacilityManager/Components/Views/DisplayFloorplanView/DisplayFloorPlanView";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import { useBackgroundImage } from "../FacilityManager/Hooks/useBackgroundImage";
import { useRemoteFetchBookingFloorPlan } from "../../hooks/Remote/FloorPlan/useRemoteFetchBookingFloorPlan";

type P = {
  isShowDialogOpen: boolean;
  /** ID of booking to show the plan of. */
  bookingId: number;
  /** Type of booking to show the plan of. */
  bookingType: BookingType;
  /** ID of the entity that will be hightlighted. Type will be the same as bookingType. */
  highlight?: number;
  /** Function that runs when closing modal. */
  closeFn?(): void;
  /** Label of button that will close modal. */
  labelButton?: string;
};
/**
 * Shows floor plan in a modal. Floor plan will be fetched using booking id.
 * @param props
 */
const BookingSvgDialog: React.FC<P> = ({
  isShowDialogOpen,
  bookingId,
  bookingType,
  closeFn,
  highlight,
  labelButton = "Okay"
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const isIphone13min = useMediaQuery("(device-width:812px)");
  const isIphone13 = useMediaQuery("(device-width:844px)");
  const isIphone13max = useMediaQuery("(device-width:926px)");
  const isIphone11 = useMediaQuery("(device-width:896px)");

  const [floorPlan, setFloorPlan] = useState<IFloorPayload>();

  const { data: floorPlanData, isFetching: isFetchingFloorPlan } = useRemoteFetchBookingFloorPlan({
    bookingId: bookingId,
    bookingType: bookingType
  });

  const { background, unsetBackgroundImage } = useBackgroundImage({
    backgroundImageUrl: floorPlan?.outlineUrl,
    viewport: floorPlan?.viewport
  });

  const handleClose = () => {
    unsetBackgroundImage();
    closeFn && closeFn();
  };

  useEffect(() => {
    if (!floorPlanData) return;

    setFloorPlan(floorPlanData);
  }, [isFetchingFloorPlan]);

  return (
    <Dialog
      open={isShowDialogOpen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth={true}
      fullScreen={isMobile || isIphone13min || isIphone13 || isIphone13max || isIphone11}
      PaperProps={{ sx: { backgroundImage: "none" } }}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {bookingType === BookingType.PLACEZONE || bookingType === BookingType.CONFERENCEZONE
          ? t("Your Zone")
          : t("Your Place")}
      </DialogTitle>
      <DialogContent style={{ height: "80vh" }}>
        {floorPlanData && background ? (
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <DisplayFloorPlanView
              data-testid={"FMBasicView"}
              background={background}
              name={"calendar"}
              floorPlan={floorPlanData}
              seatStatus={{
                availableList: generateHighlight(bookingType, highlight),
                occupiedList: [],
                disableList: [],
                restrictedList: []
              }}
            />
          </div>
        ) : (
          <DefaultSpinner />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          // color="default"
          variant="outlined"
          data-testid="booking-svg-dialog-close-btn"
        >
          {labelButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingSvgDialog;

export function generateHighlight(bookingType: BookingType, highlight: number | undefined) {
  const checkPlaceType =
    bookingType === BookingType.WORKPLACE ||
    bookingType === BookingType.TEAM ||
    bookingType === BookingType.PARKINGPLACE ||
    bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE;

  const highlighted =
    (checkPlaceType || bookingType === BookingType.CONFERENCEZONE) && highlight ? [highlight] : [];

  return highlighted;
}
