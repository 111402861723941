import { forwardRef, useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  Tooltip,
  Typography
} from "@mui/material";
import { Close, Delete, LockPerson } from "@mui/icons-material";
import { Icon } from "@iconify/react";
import qrcodeIcon from "@iconify/icons-mdi/qrcode";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import {
  NewPerms,
  Stretch,
  Transform
} from "../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";
import { InfoBox } from "../InfoBox";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { DeskInventoryDialogContent } from "./DeskInventoryDialogContent/DeskInventoryDialogContent";
import { DeskZoneDialogContent } from "./DeskZoneDialogContent/DeskZoneDialogContent";
import { AssignBox } from "../AssignBox/AssignBox";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useInventory } from "../../../../Hooks/useInventory";
import { useInventoryManagementActions } from "../../../../Hooks/useInventoryManagement";
import { IPlaceInventory } from "../../../../Domain/Types/FloorPlan/PlaceInventory.type";
import { QrCodeComponent } from "../../../Modals/QrCodeModal/QrCodeComponent";
import { IViewport } from "../../../../Domain/Types/FloorPlan/Viewport.type";
import { useCategoryManagementActions } from "../../../../Hooks/useCategoryManagement";
import { useEquipmentManagementActions } from "../../../../Hooks/useEquipmentManagement";
import { OptionDialogContent } from "../ZoneInfoSidebar/OptionDialogContent/OptionDialogContent";
import { DeskPermissionDialogContent } from "./DeskPermissionDialogContent/DeskPermissionDialogContent";
import { useSelector } from "../../../../../../app/helpers";
import { RootState } from "../../../../../../app/rootReducer";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { IEquipmentInventory } from "../../../../Domain/Types/FloorPlan/Equipment.type";
import {
  checkInventoryVal,
  handleUpdateBoundingBox,
  handleUpdatePatchPlaceInventory,
  updateMutatedPlaceInventory
} from "./PlaceInfobox.functions";

type Props = {
  floorPlan: IFloorPayload;
  locationInventoryId: number;
  color: string;
  inventoryManagement: useInventoryManagementActions;
  categoryManagement: useCategoryManagementActions;
  equipmentManagement: useEquipmentManagementActions;
  showMultiPlacesEdit: boolean;
  placeSchedules: IPlaceSchedule[];
  floorPlanName: string;
  onClose: () => void;
  onChangeTransform: (t: Transform) => void;
  onChangeStretch: (t: Stretch) => void;
  onClickDelete: () => void;
  onChangeName: (name: string) => void;
  onChangeDescription: (text: string) => void;
  onChangeEnabledStatus: (enabled: boolean) => void;
  onChangePerm: (newPerms: NewPerms) => void;
  updatePlaceInventory: (placeIds: number[], inventory: IPlaceInventory) => void;
};

export const PlaceInfoBox = forwardRef<HTMLDivElement, Props>(
  (
    {
      floorPlan,
      locationInventoryId,
      color,
      inventoryManagement,
      categoryManagement,
      equipmentManagement,
      showMultiPlacesEdit,
      placeSchedules,
      floorPlanName,
      onChangeTransform,
      onChangeStretch,
      onClickDelete,
      onClose,
      onChangeName,
      onChangeDescription,
      onChangeEnabledStatus,
      onChangePerm,
      updatePlaceInventory
    },
    ref
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const {
      settings: { colorMode }
    } = useSelector((state: RootState) => state.login);
    const iconColor = colorMode === "light" ? "#6f6f6f" : "white";

    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
    const [isDeviceModalOpen, setIsDeviceModalOpen] = useState<boolean>(false);
    const [isZoneDialogOpen, setZoneDialogOpen] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isWpPermDialogOpen, setIsWpPermDialogOpen] = useState(false);
    const [isQrModalOpen, setIsQrModalOpen] = useState<boolean>(false);
    const [placeName, setPlaceName] = useState(
      checkInventoryVal(t, placeSchedules[0].inventory?.name, placeSchedules[0].inventory)
    );
    const [placeDesc, setPlaceDesc] = useState(placeSchedules[0].description ?? "");
    const [placeBoundingBox, setPlaceBoundingBox] = useState(
      placeSchedules[0].inventory?.boundingBox ?? { width: 160, height: 80 }
    );
    const [assignedDevice, setAssginedDevice] = useState<IEquipmentInventory[]>([]);

    const { handleCreatePlaceInventory, mutatedPlaceInventory } = useInventory({ floorPlan });

    function isEntryAssigned(entry: IEquipmentInventory, desks: IPlaceSchedule[]) {
      return desks.some(desk => (desk.equipmentInventoryIds || []).includes(entry.id));
    }

    const workplaceZone = floorPlan.zones.find(zone => zone.id == placeSchedules[0].zoneScheduleId);
    const inventory = floorPlan.places.find(
      workplace => workplace.inventoryId === placeSchedules[0].inventoryId
    )?.inventory;

    const placeTypeId =
      placeSchedules[0].placeTypeId ||
      placeSchedules[0].inventory?.placeTypeId ||
      placeSchedules[0].inventory?.placeType?.id;
    const placeTypeName = floorPlan.placeTypes.find(type => type.id === placeTypeId)?.name;

    const availableDevices = floorPlan.freeInventories.equipments.filter(
      d => !isEntryAssigned(d, floorPlan.places)
    );

    useEffect(() => {
      setAssginedDevice([...placeSchedules[0].equipments]);
    }, [placeSchedules]);

    useEffect(() => {
      updateMutatedPlaceInventory(mutatedPlaceInventory, placeSchedules, updatePlaceInventory);
    }, [mutatedPlaceInventory]);

    return (
      <InfoBox color={color} ref={ref}>
        {/* header of multi places edit and listing the names */}
        <MultiInfoHeader
          type={"Place"}
          showMultiInfoHeader={showMultiPlacesEdit}
          entries={placeSchedules}
        />

        {/** name of the place inventory */}
        <CompactTextField
          rows={2}
          placeholder={t("Name")}
          value={placeName}
          onChange={newValue => {
            onChangeName(newValue);
            setPlaceName(newValue);
          }}
          handleOk={newValue => {
            handleUpdatePatchPlaceInventory(
              "newName",
              placeSchedules,
              enqueueSnackbar,
              t,
              newValue
            );
          }}
          newText={placeName}
          setNewText={(t: string) => setPlaceName(t)}
        />

        {/** description of the place inventory */}
        <CompactTextField
          rows={4}
          placeholder={t("Description")}
          value={placeDesc}
          onChange={v => onChangeDescription(v)}
          newText={placeDesc}
          setNewText={(t: string) => setPlaceDesc(t)}
        />

        {/** set position and rotation */}
        <MiniTextInput
          fields={[
            { value: placeSchedules[0].position.x, placeholder: "x", id: "x", type: "number" },
            { value: placeSchedules[0].position.y, placeholder: "y", id: "y", type: "number" },
            { value: placeSchedules[0].rotate, placeholder: "r", id: "r", type: "number" }
          ]}
          onInput={(id, value) => {
            switch (id) {
              case "r":
                return onChangeTransform({ r: +value });
              case "x":
                return onChangeTransform({ x: +value });
              case "y":
                return onChangeTransform({ y: +value });
            }
          }}
        />

        {/** set width and height as appearance */}
        {inventory && (
          <MiniTextInput
            fields={[
              {
                value: placeBoundingBox.width,
                placeholder: t("Width"),
                id: "width",
                type: "number"
              },
              {
                value: placeBoundingBox.height,
                placeholder: t("HeightPlaceSidebarLabel"),
                id: "height",
                type: "number"
              }
            ]}
            onInput={(id, value) =>
              handleUpdateBoundingBox(id, value, enqueueSnackbar, onChangeStretch)
            }
            newBoundingBox={placeBoundingBox}
            setNewBoundingBox={(v: IViewport) => setPlaceBoundingBox(v)}
            handleOk={newValue => {
              handleUpdatePatchPlaceInventory(
                "newBoundingBox",
                placeSchedules,
                enqueueSnackbar,
                t,
                newValue
              );
            }}
          />
        )}

        {/* type of the place */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="type-assign-box"
            onClickAssign={() => setIsDialogOpen(true)}
            id={placeSchedules[0].placeTypeId || placeSchedules[0].inventory?.placeTypeId}
            name={placeTypeName}
            type={"Type"}
          />
        )}

        {/** inventory */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="inventory-assign-box"
            onClickAssign={() => setIsDialogOpen(true)}
            id={placeSchedules[0].inventory?.id}
            type={"Inventory"}
          />
        )}

        {/** category */}
        <AssignBox
          buttonDataTestId="category-assign-box"
          onClickAssign={() => setIsCategoryModalOpen(!isCategoryModalOpen)}
          id={placeSchedules[0]?.category?.id || placeSchedules[0]?.categoryId}
          type={"Category"}
        />

        {/** zone */}
        <AssignBox
          buttonDataTestId="zone-assign-box"
          onClickAssign={() => setZoneDialogOpen(!isZoneDialogOpen)}
          id={workplaceZone?.inventory?.id || workplaceZone?.inventoryId}
          type={"Zone"}
        />

        {/** device */}
        {!showMultiPlacesEdit && (
          <AssignBox
            buttonDataTestId="device-assign-box"
            onClickAssign={() => setIsDeviceModalOpen(!isDeviceModalOpen)}
            id={
              assignedDevice.length
                ? t(`${assignedDevice.length} (pcs)`)
                : t("NoEquipmentSidebarLabel")
            }
            type={"Equipment"}
          />
        )}

        {/** bottom bar */}
        <Box display={"flex"} alignItems={"center"}>
          <Box>
            <IconButton data-testid={"delete-btn"} size={"small"} onClick={() => onClickDelete()}>
              <Delete />
            </IconButton>
          </Box>
          <FormControlLabel
            labelPlacement={"top"}
            sx={{ fontSize: "12px" }}
            control={
              <Switch
                data-testid={"enabled-switch"}
                size={"small"}
                checked={!placeSchedules[0].disabled}
                onChange={e => onChangeEnabledStatus(e.target.checked)}
              />
            }
            label={
              <Typography fontSize={"12px"}>
                {!placeSchedules[0].disabled ? t("Active") : t("Inactive")}
              </Typography>
            }
          />
          <Box>
            <Tooltip title={t("Adjust permisisons")}>
              <div>
                <IconButton
                  data-testid={"permission-btn"}
                  size={"small"}
                  onClick={() => setIsWpPermDialogOpen(true)}
                >
                  <LockPerson />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
          <Tooltip title={t("MultiQrCodePrint")}>
            <div>
              <IconButton
                data-testid="tool-btn-printQr"
                onClick={() => setIsQrModalOpen(!isQrModalOpen)}
                size="large"
              >
                <Icon icon={qrcodeIcon} color={iconColor} width={26} height={26} />
              </IconButton>
            </div>
          </Tooltip>

          <Box flex={1} />
          <Box>
            <IconButton data-testid={"close-btn"} size={"small"} onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>

        {/* zone assignment modal */}
        <Modal
          open={isZoneDialogOpen}
          onClose={() => setZoneDialogOpen(!isZoneDialogOpen)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskZoneDialogContent
              selectedDesks={placeSchedules}
              floorPlan={floorPlan}
              onClose={() => setZoneDialogOpen(!isZoneDialogOpen)}
              inventoryManagement={inventoryManagement}
            />
          </>
        </Modal>

        {/* place inventory assignment modal */}
        <Modal
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskInventoryDialogContent
              selectedDesk={placeSchedules[0]}
              floorPlan={floorPlan}
              locationInventoryId={locationInventoryId}
              onClose={() => setIsDialogOpen(false)}
              inventoryManagement={inventoryManagement}
              handleCreatePlaceInventory={handleCreatePlaceInventory}
              setPlaceName={(n: string) => setPlaceName(n)}
              setNewBoundingBox={(v: IViewport) => setPlaceBoundingBox(v)}
            />
          </>
        </Modal>

        {/* place category assignment modal */}
        <Modal
          open={isCategoryModalOpen}
          onClose={() => setIsCategoryModalOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <OptionDialogContent
              pivotEntityName="Place"
              subjectEntityName="Category"
              onClose={() => setIsCategoryModalOpen(false)}
              displayHeaderId={placeSchedules[0].id}
              displayChipValue={placeSchedules[0]?.category?.name} // zoneSchedule.category?.name ||
              subjectEntity={placeSchedules}
              subjectEntityEntries={floorPlan.placeCategories}
              updateFunctionSub1={categoryManagement.changeWorkplaceCategory}
            />
          </>
        </Modal>

        {/* place permission modal */}
        <Modal
          open={isWpPermDialogOpen}
          onClose={() => setIsWpPermDialogOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <DeskPermissionDialogContent
              selectedPlaces={placeSchedules}
              showMultiPlacesEdit={showMultiPlacesEdit}
              onChangePerm={onChangePerm}
              onClose={() => setIsWpPermDialogOpen(false)}
            />
          </>
        </Modal>

        {/* place devices assignment modal */}
        <Modal
          open={isDeviceModalOpen}
          onClose={() => setIsDeviceModalOpen(false)}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <>
            <OptionDialogContent
              pivotEntityName="Place"
              subjectEntityName={"Devices"}
              onClose={() => setIsDeviceModalOpen(false)}
              displayHeaderId={placeSchedules[0].id}
              displayChipValue={""}
              subjectEntity={placeSchedules[0]}
              subjectEntityEntries={[
                { availableDevices: availableDevices, assignedDevices: assignedDevice }
              ]}
              updateFunctionSub2={equipmentManagement.changePlaceEquipments}
            />
          </>
        </Modal>

        {/** print QR Code for workplaces */}
        <QrCodeComponent
          open={isQrModalOpen}
          floorPlanName={floorPlanName}
          entries={placeSchedules}
          onClose={() => setIsQrModalOpen(false)}
        />
      </InfoBox>
    );
  }
);
