import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleFiltersButton
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AddBox, Delete, FileDownload } from "@mui/icons-material";
import ExcelUploadModal from "../../../ExcelUpload/excel-upload.modal";
import { bulkEquipmentExcelUpload } from "../../../../utils/axios-requests";
import { EquipmentRow } from "../typings/equipment-row.types";

type Props = {
  table: MRT_TableInstance<EquipmentRow>;
  anchorEl: HTMLElement | null;
  setAnchorEl: (e: HTMLElement | null) => void;
  exportPopOpen: boolean;
  onReload: () => Promise<void>;
  disabledDeleteButton: boolean;
  onDelete: () => void;
};
export const FilterableEquipmentListTopToolbar = ({
  table,
  anchorEl,
  setAnchorEl,
  exportPopOpen,
  onReload,
  disabledDeleteButton,
  onDelete
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Grid container sx={{ display: "flex", p: "10px", justifyContent: "space-between" }}>
        <Typography variant={"h5"} sx={{ pl: 1 }}>
          {t("Equipment")}
        </Typography>

        <Grid item sx={{ display: "flex" }} gap={1}>
          <ExcelUploadModal
            onFinishUpload={() => {
              enqueueSnackbar(t("Successfully uploaded devices"), {
                variant: "success"
              });
            }}
            uploadCall={bulkEquipmentExcelUpload}
            triggerCall={onReload}
          />

          {/* import MRT sub-components */}
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />

          {/* delete selected devices button */}
          <Tooltip title={t("Delete selection")}>
            <span>
              <IconButton
                color="error"
                data-testid="handle-remove-equipment-btn"
                disabled={disabledDeleteButton}
                onClick={onDelete}
                size="large"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>

          {/* export as csv/pdf button */}
          <Tooltip title={t("Export")}>
            <IconButton
              data-testid="Export-device-btn"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
              color={exportPopOpen ? "primary" : "default"}
              size="large"
            >
              <FileDownload />
            </IconButton>
          </Tooltip>

          {/* add new device button */}
          <Tooltip title={t("Add")}>
            <IconButton
              data-testid="Add-new-device-btn"
              onClick={() => table.setCreatingRow(true)}
              size="large"
            >
              <AddBox />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};
