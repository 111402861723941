import { Dispatch, SetStateAction, useState, FC, useEffect } from "react";
import {
  AutocompleteChangeReason,
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  IconButton,
  SelectChangeEvent,
  Stack,
  Typography
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { OptionSelect } from "./OptionSelect";
import { OptionCreate } from "./OptionCreate";
import { OptionDialogButtonGroup } from "./OptionDialogButtonGroup";
import {
  addDevicesOptions,
  clearDevicesOptions,
  removeDevicesOptions
} from "./Partial/WorkplaceDeviceSelectActions";
import { IEquipmentInventory } from "../../../../../Domain/Types/FloorPlan/Equipment.type";

type Props<T, Y> = {
  subjectEntityName: "Inventory" | "Zone" | "Category" | "Devices";
  pivotEntityName: "Zone" | "Place";
  displayChipValue: any;
  displayHeaderId: number;
  onClose: Dispatch<SetStateAction<void>>;
  subjectEntity: Y | Y[];
  subjectEntityEntries: any[];
  newSubjectEntityTemplate?: T;
  CreateForm?: FC<{
    newEntity: T | undefined;
    setNewEntity: Dispatch<SetStateAction<T | undefined>>;
  }>;
  createFunction?: (subjectEntity: T | undefined) => void;
  updateFunctionSub1?: (pivotEntity: Y | Y[], selectedSubjectEntity: T) => void;
  updateFunctionSub2?: (pivotEntity: Y, selectedSubjectEntity: T[]) => void;
};

export const OptionDialogContent = <T, Y>({
  subjectEntityName,
  pivotEntityName,
  displayChipValue,
  displayHeaderId,
  onClose,
  subjectEntity,
  subjectEntityEntries,
  newSubjectEntityTemplate,
  CreateForm,
  createFunction,
  updateFunctionSub1,
  updateFunctionSub2
}: Props<T, Y>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [newSubjectEntity, setNewSubjectEntity] = useState<T | undefined>(newSubjectEntityTemplate);
  const [selectedSubjectEntity, setSelectedSubjectEntity] = useState<any | any[] | undefined>(
    undefined
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [availableEntities, setAvailableEntities] = useState<any[]>([]);
  const [currentEntities, setCurrentEntities] = useState<any[]>([]);

  function handleChange(event: SelectChangeEvent) {
    const findSubjectEntity = subjectEntityEntries.filter(
      item => item.id === (event.target.value as unknown as number)
    );

    if (findSubjectEntity.length === 1) {
      setSelectedSubjectEntity(findSubjectEntity[0]);
    } else {
      setSelectedSubjectEntity(findSubjectEntity);
    }
  }

  function handleDeviceChange(value: any[], reasons: AutocompleteChangeReason) {
    switch (reasons) {
      case "selectOption":
        addDevicesOptions({
          value,
          availableDevices: availableEntities,
          currentDevices: currentEntities,
          setAvailableDevices: setAvailableEntities,
          setCurrentDevices: setCurrentEntities,
          enqueueSnackbar,
          t
        });
        setSelectedSubjectEntity(value);
        break;
      case "removeOption":
        removeDevicesOptions({
          value,
          availableDevices: availableEntities,
          currentDevices: currentEntities,
          setAvailableDevices: setAvailableEntities,
          setCurrentDevices: setCurrentEntities,
          enqueueSnackbar,
          t
        });
        setSelectedSubjectEntity(value);
        break;
      case "clear":
        clearDevicesOptions({
          availableDevices: availableEntities,
          currentDevices: currentEntities,
          setAvailableDevices: setAvailableEntities,
          setCurrentDevices: setCurrentEntities
        });
        setSelectedSubjectEntity([]);
        break;
      default:
        return;
    }
  }

  const translationKeys = {
    Category: {
      singular: "category",
      plural: "categories"
    },
    Inventory: {
      singular: "inventory",
      plural: "inventories"
    },
    Zone: {
      singular: "zone",
      plural: "zones"
    },
    Devices: {
      singular: "devices",
      plural: "devices"
    },
    Place: {
      singular: "place",
      plural: "places"
    }
  };

  const title = (() => {
    if (Array.isArray(subjectEntity) && subjectEntity.length > 1) {
      return t("OptionDialogContentEditMultipleTitle", {
        subjectEntity: t(translationKeys[subjectEntityName].singular),
        pivotEntity: t(translationKeys[pivotEntityName].plural)
      });
    }

    if (pivotEntityName == "Zone" && subjectEntityName === "Category") {
      return t("AssignZoneCategoryTitle");
    }
    if (pivotEntityName == "Zone" && subjectEntityName === "Devices") {
      return t("AssignZoneEquimentTitle") + ` ${displayHeaderId}`;
    }

    if (pivotEntityName == "Place" && subjectEntityName === "Category") {
      return t("AssignPlaceCategoryTitle");
    }
    if (pivotEntityName == "Place" && subjectEntityName === "Devices") {
      return t("AssignPlaceEquimentTitle") + ` ${displayHeaderId}`;
    }

    return (
      t("OptionDialogContentEditTitle", {
        subjectEntity: t(translationKeys[subjectEntityName].singular),
        pivotEntity: t(translationKeys[pivotEntityName].singular)
      }) +
      " " +
      displayHeaderId
    );
  })();
  const componentPreparedToCreate = CreateForm && createFunction && newSubjectEntityTemplate;

  useEffect(() => {
    const deviceConfig = () => {
      const avail = subjectEntityEntries[0].availableDevices as IEquipmentInventory[];
      const assgined = subjectEntityEntries[0].assignedDevices;

      setAvailableEntities(avail);
      setCurrentEntities(assgined);
    };

    if (subjectEntityEntries && subjectEntityName === "Devices") deviceConfig();
  }, [subjectEntityEntries, displayHeaderId]);

  return (
    <Card sx={{ p: 2, maxHeight: "80vh", minWidth: "20vw" }} data-testid={"option-assignment"}>
      <Stack direction={"column"} gap={2}>
        <Grid container>
          <Grid item xs />
          <Grid item xs={9} sx={{ marginBottom: "10px" }}>
            <Typography align={"center"} lineHeight={2.5}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs sx={{ textAlign: "right" }}>
            <IconButton
              sx={{ float: "right" }}
              data-testid="option-btn-group-close"
              onClick={() => {
                setSelectedSubjectEntity(undefined);
                onClose();
              }}
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        {subjectEntity && subjectEntityName !== "Devices" && (
          <Grid data-testid="option-dialog-content-name" display={"flex"} alignItems={"center"}>
            <Typography>{t(subjectEntityName)}</Typography>
            {":  "}
            <Chip label={displayChipValue} />
          </Grid>
        )}

        <Box gap={1} display={"grid"} alignItems={"center"}>
          <OptionSelect
            entries={subjectEntityEntries}
            subjectEntityName={subjectEntityName}
            defaultValue={""}
            onChange={handleChange}
            onDeviceChange={handleDeviceChange}
            availableEntries={availableEntities}
            currentEntries={currentEntities}
          />
          {componentPreparedToCreate && (
            <>
              <Typography display={"flex"} justifyContent={"center"}>
                {t("or")}
              </Typography>
              <Button
                variant={"outlined"}
                size={"small"}
                sx={{ flex: 1 }}
                startIcon={isCreating ? <Remove /> : <Add />}
                onClick={() => {
                  setIsCreating(value => !value);
                }}
                data-testid="option-btn-group-create"
              >
                {t("OptionDialogContentCreateNewLabel", { name: subjectEntityName })}
              </Button>
            </>
          )}
        </Box>

        {componentPreparedToCreate && (
          <Collapse in={isCreating}>
            <OptionCreate subjectEntityName={subjectEntityName}>
              <CreateForm newEntity={newSubjectEntity} setNewEntity={setNewSubjectEntity} />
            </OptionCreate>
          </Collapse>
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <OptionDialogButtonGroup
            isCreating={isCreating}
            setIsCreating={setIsCreating}
            onClose={onClose}
            subjectEntity={subjectEntity}
            selectedSubjectEntity={selectedSubjectEntity}
            handleChange={() => {
              updateFunctionSub1?.(subjectEntity, selectedSubjectEntity);
              updateFunctionSub2?.(subjectEntity as Y, selectedSubjectEntity);
            }}
            handleCreate={() => {
              setNewSubjectEntity(newSubjectEntityTemplate);

              if (CreateForm && createFunction && newSubjectEntityTemplate)
                createFunction(newSubjectEntity);
            }}
          />
        </Box>
      </Stack>
    </Card>
  );
};
