import { Datepicker, locale } from "@mobiscroll/react";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import useTimeFormat from "../../hooks/useTimeFormat/useTimeFormat";
import { calMinuteInterval } from "../BookingForm/date-time.functions";

type P = {
  chosenTimes: {
    startTime: string;
    endTime: string;
  };
  setChosenTimes: React.Dispatch<
    React.SetStateAction<{
      startTime: string;
      endTime: string;
    }>
  >;
  editable: {
    start: boolean;
    end: boolean;
  };
};

export const TimePickers: React.FC<P> = ({ chosenTimes, setChosenTimes, editable }) => {
  const { t, i18n } = useTranslation();
  const { timeFormat } = useTimeFormat();

  return (
    <>
      <div data-testid="time-picker-start-container">
        <Datepicker
          data-testid={"time-picker-start"}
          locale={locale[i18n.language]}
          controls={["time"]}
          timeFormat={timeFormat}
          onChange={ev => {
            const startDate = DateTime.fromJSDate(ev.value as Date).toFormat("HH:mm");
            setChosenTimes(prevState => ({ ...prevState, startTime: startDate }));

            const resStart = DateTime.fromJSDate(ev.value as Date);
            const initialEnd = DateTime.fromISO(
              `${DateTime.now().toFormat("yyyy-MM-dd")}T${chosenTimes.endTime}`
            );

            if (resStart >= initialEnd) {
              setChosenTimes(prevState => ({
                ...prevState,
                startTime: startDate,
                endTime: startDate
              }));
            }
          }}
          value={DateTime.fromISO(
            `${DateTime.now().toFormat("yyyy-MM-dd")}T${chosenTimes.startTime}`
          ).toFormat("HH:mm")}
          stepMinute={15}
          label={t("Starting From")}
          disabled={!editable.start}
          valid={[
            {
              start: DateTime.local()
                .set({ minute: calMinuteInterval(DateTime.local()) })
                .toFormat("HH:mm"),
              end: "23:59"
            }
          ]}
        />
      </div>
      <div data-testid="time-picker-end-container">
        <Datepicker
          data-testid={"time-picker-end"}
          locale={locale[i18n.language]}
          controls={["time"]}
          timeFormat={timeFormat}
          onChange={ev => {
            const date = DateTime.fromJSDate(ev.value as Date).toFormat("HH:mm");
            setChosenTimes(prevState => ({ ...prevState, endTime: date }));
          }}
          value={DateTime.fromISO(
            `${DateTime.now().toFormat("yyyy-MM-dd")}T${chosenTimes.endTime}`
          ).toFormat("HH:mm")}
          stepMinute={15}
          label={t("Ending At")}
          disabled={!editable.end}
          valid={[
            {
              start: chosenTimes.startTime,
              end: "23:59"
            }
          ]}
        />
      </div>
    </>
  );
};
