import { Backdrop, Box, Dialog, Popper, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import { SelectionPrompt } from "./SelectionPrompt";
import {
  handleSelection,
  selectableByVariant,
  selectedEntry
} from "../Functions/SelectFloorPlanView.functions";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ISeatStatus } from "../../../../Domain/Types/FloorPlan/SeatStatus.type";
import { useSelector } from "../../../../../../app/helpers";
import { RootState } from "../../../../../../app/rootReducer";

interface Props {
  floorPlan: IFloorPayload;
  dialogPosition: [number, number];
  seatStatus: ISeatStatus;
  selectedPlace: number | undefined;
  selectedZone: number | undefined;
  bookingInputs: BookingInputs;
  userIndex: number;
  setSelectedPlace: (place: number | undefined) => void;
  setSelectedZone: (zone: number | undefined) => void;
  setUserIndex: (i: number) => void;
  setIsDateOpen: (o: boolean) => void;
  onSingleSelect?: (placeInventoryId: number) => void;
  onTeamSelect?: (index: number, placeInventoryId: number) => void;
  onNextStep: () => void;
}

export function ResponsiveSelectionPrompt({
  floorPlan,
  dialogPosition,
  seatStatus,
  selectedPlace,
  selectedZone,
  bookingInputs,
  userIndex,
  setSelectedPlace,
  setSelectedZone,
  setUserIndex,
  setIsDateOpen,
  onSingleSelect,
  onTeamSelect,
  onNextStep
}: Props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  // when is mobile or tablet, display selection prompt as dialog in the center
  const isBrowser = useMediaQuery(theme.breakpoints.up(1200));

  const { bookingType } = useSelector((state: RootState) => state.booking.inputs);
  const { userInformation } = useSelector((state: RootState) => state.login);

  const baseRef = useRef<HTMLElement>(null);

  const closeSelectionPrompt = (e: any) => {
    e.stopPropagation();
    setSelectedPlace(undefined);
    setSelectedZone(undefined);
  };

  return (
    <>
      {isBrowser ? (
        <Backdrop open onClick={closeSelectionPrompt}>
          <Box
            data-testid="prompt-parent-popper-box"
            ref={baseRef}
            sx={{ position: "fixed", top: dialogPosition[1], left: dialogPosition[0] }}
          />
          {baseRef.current && (
            <Popper
              id={selectedPlace?.toString() || selectedZone?.toString()}
              anchorEl={baseRef.current}
              open
              nonce={undefined}
              onClick={e => e.stopPropagation()} // backdrop event should not fire when interacting with the dialog
            >
              <SelectionPrompt
                selected={selectedEntry(floorPlan, selectedPlace, selectedZone)}
                onConfirm={() => {
                  handleSelection(
                    bookingInputs,
                    userIndex,
                    floorPlan,
                    { selectedPlace, selectedZone },
                    { setSelectedPlace, setUserIndex },
                    { onSingleSelect, onTeamSelect, onNextStep },
                    { enqueueSnackbar, t }
                  );
                }}
                onClose={closeSelectionPrompt}
                selectableByVariant={selectableByVariant(
                  selectedEntry(floorPlan, selectedPlace, selectedZone),
                  seatStatus,
                  bookingType
                )}
                bookingInputs={bookingInputs}
                userIndex={userIndex}
                userId={userInformation.sub}
                setIsDateOpen={setIsDateOpen}
              />
            </Popper>
          )}
        </Backdrop>
      ) : (
        <Dialog open maxWidth={"md"} fullWidth onClose={closeSelectionPrompt}>
          <SelectionPrompt
            selected={selectedEntry(floorPlan, selectedPlace, selectedZone)}
            userIndex={userIndex}
            userId={userInformation.sub}
            setIsDateOpen={setIsDateOpen}
            selectableByVariant={selectableByVariant(
              selectedEntry(floorPlan, selectedPlace, selectedZone),
              seatStatus,
              bookingType
            )}
            onConfirm={() => {
              handleSelection(
                bookingInputs,
                userIndex,
                floorPlan,
                { selectedPlace, selectedZone },
                { setSelectedPlace, setUserIndex },
                { onSingleSelect, onTeamSelect, onNextStep },
                { enqueueSnackbar, t }
              );
            }}
            onClose={closeSelectionPrompt}
            bookingInputs={bookingInputs}
          />
        </Dialog>
      )}
    </>
  );
}
