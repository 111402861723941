import { useState } from "react";
import { Box, Button } from "@mui/material";
import { useTextFieldColors } from "./useTextFieldColors";
import { useTranslation } from "react-i18next";

export function CompactTextField({
  placeholder,
  value,
  rows = 1,
  newText,
  setNewText,
  onChange,
  handleOk,
  readOnly = false
}: {
  placeholder: string;
  value: string | number;
  newText?: string;
  rows?: number;
  onChange?: (newValue: string) => void;
  handleOk?: (newValue: string) => void;
  setNewText?: (t: string) => void;
  readOnly?: boolean;
}) {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { borderColor, placeholderBackgroundColor, textColor } = useTextFieldColors(isFocused);
  const { t } = useTranslation();
  const noInvenAssgined = t("NoInventoryModalLabel");

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  return (
    <Box
      position={"relative"}
      flex={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: 1
      }}
    >
      <span
        style={{
          transitionDuration: "200ms",
          color: textColor,
          fontSize: 12,
          fontFamily: "sans-serif",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          background: placeholderBackgroundColor,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 2,
          paddingBottom: 2
        }}
      >
        {placeholder}
      </span>
      <div style={{ display: "inline-flex", alignSelf: "start", width: "100%" }}>
        <textarea
          readOnly={readOnly}
          value={newText}
          onChange={event => {
            onChange?.(event.target.value);
            setNewText?.(event.target.value);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          rows={rows}
          style={{
            color: textColor,
            margin: 8,
            width: "100%",
            border: "none",
            background: "none",
            outline: "none",
            fontSize: 12,
            resize: "none",
            fontFamily: "sans-serif"
          }}
        />
        {handleOk && newText && (
          <Button
            disabled={value === noInvenAssgined}
            data-testid="textfield-ok-btn"
            onClick={() => handleOk(newText.toString())}
            variant={"text"}
          >
            {t("Ok")}
          </Button>
        )}
      </div>
    </Box>
  );
}
