import React from "react";
import { Grid, IconButton, SxProps, TextField, Theme, Typography } from "@mui/material";
import { Cancel, Check, Settings } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export type InlineFieldEdit = {
  isEditing: boolean;
  valueBefore: string;
  value: string;
};

export const EditableField: React.FC<{
  state: InlineFieldEdit;
  setState: React.Dispatch<React.SetStateAction<InlineFieldEdit>>;
  typographySxProps: SxProps<Theme>;
  "data-testid"?: string;
  onEdit?: (value: string) => Promise<any>;
}> = ({ state, setState, typographySxProps, onEdit, "data-testid": dataTestId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const confirmChanges = async () => {
    if (!onEdit)
      throw new Error("Unexpected missing function, should be defined to reach this condition");
    try {
      await onEdit(state.value);
      setState(p => ({
        isEditing: false,
        valueBefore: p.value,
        value: p.value
      }));
    } catch (err) {
      enqueueSnackbar(t("Something went wrong. Please try again later."), {
        variant: "error"
      });
    }
  };

  const cancelChanges = () => {
    setState(p => ({
      isEditing: false,
      valueBefore: p.valueBefore,
      value: p.valueBefore
    }));
  };

  return !state.isEditing ? (
    <Typography variant="h5" sx={typographySxProps} data-testid={dataTestId}>
      {fmtLen(state.value)}{" "}
      {onEdit && (
        <IconButton
          data-testid="btn-state"
          size="small"
          onClick={e => {
            e.stopPropagation();
            setState(p => ({ ...p, isEditing: true }));
          }}
        >
          <Settings fontSize="small" />
        </IconButton>
      )}
    </Typography>
  ) : (
    <Grid container direction="row">
      <Grid item xs={8}>
        <TextField
          data-testid="change-field"
          value={state.value}
          onChange={e => {
            setState(p => ({ ...p, value: e.target.value }));
          }}
          sx={{ width: "100%" }}
          variant={"standard"}
          onKeyDown={e => {
            switch (e.key) {
              case "Escape":
                cancelChanges();
                break;
              case "Enter":
                confirmChanges();
                break;
            }
          }}
        />
      </Grid>
      <Grid item>
        <IconButton size="small" data-testid="btn-confirm" onClick={confirmChanges}>
          <Check fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton size="small" data-testid="btn-cancel" onClick={cancelChanges}>
          <Cancel fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function fmtLen(str: string, maxLength = 20): string {
  if (str.length > maxLength) return str.substring(0, maxLength - 3) + "...";

  return str;
}
