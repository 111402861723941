import EquipmentManagement from "../FloorManager/equipment-administration.component";

export default function Devices() {
  return (
    <div style={{ flexGrow: 1 }}>
      {/* <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label={t("Inventory")} {...a11yProps(0)} />
        <Tab label={t("Device Usage")} {...a11yProps(1)} />
        <Tab label={t("Preferences")} {...a11yProps(2)} />
      </Tabs> */}

      {/* <TabPanel value={value} index={0}>*/}
      {/* <DeviceManagement /> */}
      <EquipmentManagement />
      {/* </TabPanel> */}
      {/* <TabPanel value={value} index={1}>
        <DeviceManagementReport />
      </TabPanel> */}
      {/* <TabPanel value={value} index={2}>
      <PreferenceReport />
      </TabPanel> */}
    </div>
  );
}
