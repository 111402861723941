import React from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { getOptionLabelOfUserOrEmailMapping } from "../../utils/type/type.utils";

type P = {
  options?: UserOrEmailMapping[];
  handleChange: (event: React.ChangeEvent<any>, value: UserOrEmailMapping[]) => void; // material ui bug! value is TeamMember
  placeholder?: string;
  values?: UserOrEmailMapping[];
};

/**
 * Material UI Autocomplete for Team members.
 * @param param0 Props
 * @todo Automatically fetch Users if they're not passed as props
 */
const AdminPicker: React.FC<P> = ({
  options,
  handleChange,
  placeholder = i18n.t("Choose your colleagues"),
  values
}) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      multiple
      data-testid={"adminPicker"}
      options={options || []}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="outlined"
            label={t("Colleague")}
            fullWidth
            placeholder={placeholder}
          />
        );
      }}
      disableCloseOnSelect
      id={"autocompleteAdminPicker"}
      onChange={handleChange}
      value={values || []}
      getOptionLabel={getOptionLabelOfUserOrEmailMapping}
      sx={{ width: "100%", paddingTop: "10px" }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...rest } = getTagProps({ index });
          return <Chip key={key} {...rest} label={getOptionLabelOfUserOrEmailMapping(option)} />;
        })
      }
    />
  );
};

export default AdminPicker;
