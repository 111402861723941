import {
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { EvStation, LocalParking } from "@mui/icons-material";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { fitAndCorrectType } from "../../BookingForm/form-selection.component";
import { ReactComponent as AvatarIcon } from "../../../features/Booking-Form/assets/avatar.svg";
import { ReactComponent as ConferenceIcon } from "../../../features/Booking-Form/assets/conference.svg";

type P = {
  typToggle: BookingType | null;
  setTypToggle: (t: BookingType | null) => void;
  onEnableFetch: () => void;
};
const BookingTypeSelector: React.FC<P> = ({ typToggle, setTypToggle, onEnableFetch }) => {
  const theme = useTheme();
  const isBrowser = useMediaQuery(theme.breakpoints.up(768));

  const { placeTypes, zoneTypes } = useSelector((state: RootState) => state.login.userInformation);

  const iconSize = isBrowser ? "30px" : "20px";

  const handleBookingTypeToggle = (
    event: React.MouseEvent<HTMLElement>,
    newToggles: BookingType | null
  ) => {
    // enforce that at least one button must be active
    if (newToggles !== null) setTypToggle(newToggles);
    onEnableFetch();
  };

  return (
    <>
      <ToggleButtonGroup
        value={typToggle}
        exclusive
        onChange={handleBookingTypeToggle}
        data-testid="bookingtype-toggle-btn-group"
        sx={{ display: "flex", flexWrap: "nowrap", marginRight: isBrowser ? 3 : 0 }}
      >
        {fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
          <ToggleButton
            value={BookingType.WORKPLACE}
            data-testid={`${BookingType.WORKPLACE}-toggle-btn`}
          >
            <StyledIconComponent theme={theme} iconSize={iconSize}>
              <AvatarIcon width={iconSize} height={iconSize} />
            </StyledIconComponent>
          </ToggleButton>
        )}

        {fitAndCorrectType(BookingType.PARKINGPLACE, placeTypes, zoneTypes) && (
          <ToggleButton
            value={BookingType.PARKINGPLACE}
            data-testid={`${BookingType.PARKINGPLACE}-toggle-btn`}
          >
            <StyledIconComponent theme={theme} iconSize={iconSize}>
              <LocalParking width={iconSize} height={iconSize} />
            </StyledIconComponent>
          </ToggleButton>
        )}

        {fitAndCorrectType(BookingType.ELECTRICCHARGINGSTATIONPLACE, placeTypes, zoneTypes) && (
          <ToggleButton
            value={BookingType.ELECTRICCHARGINGSTATIONPLACE}
            data-testid={`${BookingType.ELECTRICCHARGINGSTATIONPLACE}-toggle-btn`}
          >
            <StyledIconComponent theme={theme} iconSize={iconSize}>
              <EvStation width={iconSize} height={iconSize} />
            </StyledIconComponent>
          </ToggleButton>
        )}

        {fitAndCorrectType(BookingType.CONFERENCEZONE, placeTypes, zoneTypes) && (
          <ToggleButton
            value={BookingType.CONFERENCEZONE}
            data-testid={`${BookingType.CONFERENCEZONE}-toggle-btn`}
          >
            <StyledIconComponent theme={theme} iconSize={iconSize}>
              <ConferenceIcon width={iconSize} height={iconSize} />
            </StyledIconComponent>
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </>
  );
};

export default BookingTypeSelector;

const StyledIconComponent = styled("svg")(
  ({ theme, iconSize }: { theme: Theme; iconSize: string }) => ({
    width: iconSize,
    height: iconSize,
    marginLeft: 2,
    marginRight: 2,
    "& path": {
      fill: theme.palette.text.primary
    }
  })
);
