import { FunctionComponent } from "react";
import { Box, Fade, Grid, Skeleton } from "@mui/material";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { FloorPlanMemo } from "../../features/Reports/typings/reports.types";
import SvgReport from "../Svg/svg-report.component";
import { currentEntityData } from "./functions/facility-report.functions";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";

interface OwnProps {
  floorData: FloorPlanMemo;
  zoneBookingReport: boolean;
}

type Props = OwnProps;

const FacilityReportSVG: FunctionComponent<Props> = ({ floorData, zoneBookingReport }) => {
  const {
    capacitySelectedFloors,
    currentFloors,
    capacitySelectedWorkplaceOrZone,
    selectedZoneOrWorkplace,
    selectableEntities,
    selectedLocation
  } = useSelector((state: RootState) => state.report);

  const entityData = currentEntityData(
    selectedZoneOrWorkplace,
    capacitySelectedWorkplaceOrZone,
    currentFloors,
    capacitySelectedFloors
  );

  return (
    <>
      {!!currentFloors[0].id &&
      floorData[currentFloors[0].id] &&
      entityData &&
      entityData[0]?.capacity?.capacityPerformancePerWorkplace ? (
        currentFloors.map((floor, i) => (
          <Grid item xs={12} md={6} key={floor.id}>
            {floorData[floor.id]?.floorData && (
              <Fade key={floor.id} mountOnEnter in={!!currentFloors[i].id}>
                <Box style={{ marginRight: i === 0 ? 10 : 0, marginLeft: i === 0 ? 0 : 10 }}>
                  <SvgReport
                    height={500}
                    zoneBooking={zoneBookingReport}
                    name={
                      selectableEntities
                        ?.find(entity => entity.id === selectedLocation.id)
                        ?.floors.find(entity => entity.inventoryId === floor.id)?.name
                    }
                    floorData={floorData[floor.id]?.floorData as IFloorPayload}
                    backgroundUrl={
                      floorData[floor.id]?.floorData.outlineUrl ||
                      floorData[floor.id]?.picturePath ||
                      ""
                    }
                    utilizationRates={
                      selectedZoneOrWorkplace?.open &&
                      selectedZoneOrWorkplace?.floorInventoryId === floor.id &&
                      entityData &&
                      entityData[0]
                        ? entityData[0].capacity.capacityPerformancePerWorkplace
                        : entityData?.find(entity => entity?.id === floor.id)?.capacity
                            .capacityPerformancePerWorkplace || [
                            { bookingObject: -1, capacityRate: 0 }
                          ]
                    }
                    currentFloorInfo={{ floor: floor, index: i }}
                  />
                </Box>
              </Fade>
            )}
          </Grid>
        ))
      ) : (
        <Skeleton variant="rectangular" width={"100%"} height={"78vh"} />
      )}
    </>
  );
};

export default FacilityReportSVG;
