import { v4 as uuidv4 } from "uuid";
import { EquipmentInventory } from "../../../../features/FloorManager/typings/svg.types";
import { EquipmentRowValues } from "../typings/equipment-row.types";

/**
 * creates the newly created device data and assembles a device inventory object
 * @param newEquipment data inserted in the inline form
 * @param newData data descending from the table row
 * @return a device inventory object
 */
export function mapNewRowToPartialEquipmentInventory(
  values: EquipmentRowValues
): EquipmentInventory {
  const newDevice = {
    costPerHour: values.costPerHour,
    name: values.name,
    assetRefId: values.assetRefId,
    uid: uuidv4(),
    equipmentCategory: {
      id: values["equipmentCategory.id"],
      name: values["equipmentCategory.name"]
    },
    equipmentCategoryId: values["equipmentCategory.id"],
    status: values.status,
    placeInventoryId: values.placeInventoryId ? Number(values.placeInventoryId) : null,
    zoneInventoryId: values.zoneInventoryId ? Number(values.zoneInventoryId) : null
  };

  return newDevice as unknown as EquipmentInventory;
}
