import { Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { IPlaceSchedule } from "../../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { MultiPlacesHeader } from "../MultiPlacesHeader";
import { handlePermChange } from "../../../Partial/PermissionChange.partial";
import { PermissionSelectGroup } from "../../../Partial/PermissionSelectGroup.partial";
import {
  RemoteCompanyPerm,
  RemoteCompanyUnitsPerm,
  RemoteUsersPerm
} from "../../../../../Domain/Types/FloorPlan/Permission.type";
import { generateNewPerms } from "./PermissionDialogContent.partial";
import { initPerms } from "../../functions/initPerms.function";
import { NewPerms } from "../../../../Views/CreateFloorPlanView/Tools/useFloorplan/useFloorplan";

type Props = {
  selectedPlaces: IPlaceSchedule[];
  showMultiPlacesEdit: boolean;
  onChangePerm: (perms: NewPerms) => void;
  onClose: () => void;
};

export function DeskPermissionDialogContent({
  selectedPlaces,
  showMultiPlacesEdit,
  onChangePerm,
  onClose
}: Props) {
  const { t } = useTranslation();

  const { initialPermCompanies, initialPermCompanyUnits, initialPermUsers } =
    initPerms(selectedPlaces);

  return (
    <Card sx={{ p: 2, maxHeight: "70%", overflowY: "visible", width: "25vw" }}>
      <Grid container>
        <Grid item xs />

        {/* title of permission modal */}
        <Grid item xs={9} sx={{ alignSelf: "center" }}>
          <Typography align={"center"} lineHeight={2.25}>
            {t("Adjust permissions")}
            <MultiPlacesHeader
              showMultiPlacesEdit={showMultiPlacesEdit}
              placeSchedules={selectedPlaces}
              showHeader={false}
            />
          </Typography>
        </Grid>

        <Grid item xs sx={{ textAlign: "right" }}>
          <IconButton data-testid="btn-group-close" onClick={onClose} size="large">
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Stack direction={"column"} gap={4}>
        <PermissionSelectGroup
          initialPermCompanies={initialPermCompanies}
          initialPermCompanyUnits={initialPermCompanyUnits}
          initialPermUsers={initialPermUsers}
          type={"place"}
          isUsingTimeRelatedPerm
          handlePermChange={handlePermChange}
          placeUpdatePerms={(companies, units, users) =>
            handlePlaceUpdatePerms(onClose, onChangePerm, companies, units, users)
          }
        />
      </Stack>
    </Card>
  );
}

export function handlePlaceUpdatePerms(
  onClose: () => void,
  onChangePerm: (perms: NewPerms) => void,
  selectedCompanies: RemoteCompanyPerm[] = [],
  selectedCompanyUnits: RemoteCompanyUnitsPerm[] = [],
  selectedUsers: RemoteUsersPerm[] = []
) {
  const newPerms = generateNewPerms({ selectedCompanies, selectedCompanyUnits, selectedUsers });

  onChangePerm(newPerms);
  onClose();
}

export function singlePlaceName(
  showMultiPlacesEdit: boolean,
  selectedPlaces: IPlaceSchedule[],
  t: TFunction
) {
  if (!showMultiPlacesEdit) {
    if (selectedPlaces[0].inventory && selectedPlaces[0].inventory.name)
      return `${selectedPlaces[0].inventory?.name}`;
    return t("New Place");
  }
}
