import { Theme } from "@mui/material";
import { t } from "i18next";
import {
  BookingType,
  NotSerializedBookingMode
} from "../../../features/Booking-Form/typings/booking-inputs";
import { ValidMaxAdvancedPerType } from "../form-selection-bookingType.component";
import { UnselectableText } from "../partial/form-selection.partial";

export const isSelectable = (
  type: BookingType,
  theme: Theme,
  validMaxAdvancedPerType?: ValidMaxAdvancedPerType
) => {
  const basicStyle = {
    border: "unset",
    cursor: "pointer",
    "&:hover": { boxShadow: `0px 0px 0px 4px ${theme.palette.primary.main} inset;` }
  };
  const unselectableStyle = { border: "1px solid red", cursor: "default" };
  if (!validMaxAdvancedPerType) return basicStyle;

  switch (type) {
    case BookingType.WORKPLACE:
    case BookingType.TEAM:
      if (!validMaxAdvancedPerType?.workplace) return unselectableStyle;
      else return basicStyle;
    case BookingType.PARKINGPLACE:
      if (!validMaxAdvancedPerType?.parkingplace) return unselectableStyle;
      else return basicStyle;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      if (!validMaxAdvancedPerType?.electriccharging) return unselectableStyle;
      else return basicStyle;
    case BookingType.CONFERENCEZONE:
      if (!validMaxAdvancedPerType?.conference) return unselectableStyle;
      else return basicStyle;
  }
};

export const onModeChangeBySelectable = (
  type: BookingType,
  onModeChange: (mode: NotSerializedBookingMode) => void,
  validMaxAdvancedPerType?: ValidMaxAdvancedPerType
) => {
  if (!validMaxAdvancedPerType) return onModeChange(type);

  switch (type) {
    case BookingType.WORKPLACE:
    case BookingType.TEAM:
      if (validMaxAdvancedPerType?.workplace) return onModeChange(type);
      else return;
    case BookingType.PARKINGPLACE:
      if (validMaxAdvancedPerType?.parkingplace) return onModeChange(type);
      else return;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      if (validMaxAdvancedPerType?.electriccharging) return onModeChange(type);
      else return;
    case BookingType.CONFERENCEZONE:
      if (validMaxAdvancedPerType?.conference) return onModeChange(type);
      else return;
  }
};

export const showTooltipBySelectable = (
  type: BookingType,
  validMaxAdvancedPerType?: ValidMaxAdvancedPerType
) => {
  if (!validMaxAdvancedPerType) return null;

  const desc = t("_notAllowedBookingWithinMaximumAdvancedBookingTimePerType");

  switch (type) {
    case BookingType.WORKPLACE:
    case BookingType.TEAM:
      if (!validMaxAdvancedPerType?.workplace) return UnselectableText(desc);
      else return null;
    case BookingType.PARKINGPLACE:
      if (!validMaxAdvancedPerType?.parkingplace) return UnselectableText(desc);
      else return null;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      if (!validMaxAdvancedPerType?.electriccharging) return UnselectableText(desc);
      else return null;
    case BookingType.CONFERENCEZONE:
      if (!validMaxAdvancedPerType?.conference) return UnselectableText(desc);
      else return null;
  }
  return null;
};
