import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react";
import { useSupercluster } from "./useSupercluster";
import Supercluster from "supercluster";
import * as _ from "lodash";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import LocationCapacityMarker from "./location-capacity-marker.component";
import {
  latitudeLocation,
  longitudeLocation,
  zoomLocation
} from "./functions/facility-report.functions";
import { processCoordinates } from "./FacilityReportGoogleMap/process-coordinates.function";
import { DarkMapStyles } from "./google-map.styles";
import getColor from "../../features/Facility/functions/getColor";

interface OwnProps {
  zoneBookingReport: boolean;
}

type Props = OwnProps;

const AnyReactComponent = ({ children }: any) => <div>{children}</div>;

const FacilityReportGoogleMap: FunctionComponent<Props> = mapProps => {
  const {
    settings: { colorMode }
  } = useSelector((state: RootState) => state.login);
  const { mapMarkerLocations } = useSelector((state: RootState) => state.report);

  const [boundsState, setBoundsState] = useState<any>(null);
  const [zoomState, setZoomState] = useState(10);
  const mapRef = useRef<any>(null!);

  const points: Supercluster.PointFeature<Supercluster.AnyProps>[] = useMemo(() => {
    const data = _.uniqBy(
      mapMarkerLocations ?? [],
      mapMarkerLocation => mapMarkerLocation.locationInventoryId
    );

    return data.map(location => processCoordinates(location));
  }, [mapMarkerLocations]);

  const options = {
    radius: 75,
    maxZoom: 22,
    map: (props: any) => ({
      averageCapacity: props.averageCapacity,
      numberOfWorkplaces: props.numberOfWorkplaces,
      count: 1,
      locations: [props],
      name: props.name,
      id: props.id
    }),
    reduce: (acc: any, props: any) => {
      acc.locations = [...acc.locations, props];
      acc.count += 1;
      acc.numberOfWorkplaces += props.numberOfWorkplaces;
      acc.averageCapacity =
        (props.averageCapacity * props.numberOfWorkplaces) / acc.numberOfWorkplaces;
      return acc;
    }
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: boundsState,
    zoom: zoomState,
    options: options
  });

  useEffect(() => {
    /* istanbul ignore next */
    // prevent pinch zoom of the mobile/tablet device
    function handleTouchMove(event: TouchEvent) {
      if (event.touches.length > 1) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    return () => window.removeEventListener("touchmove", handleTouchMove);
  }, []);

  return (
    <GoogleMapReact
      onGoogleApiLoaded={({ map }) => {
        mapRef.current = map;
      }}
      yesIWantToUseGoogleMapApiInternals
      options={colorMode === "dark" ? DarkMapStyles : { mapTypeControl: true, styles: [] }}
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "" }}
      center={{
        lat: latitudeLocation(mapMarkerLocations),
        lng: longitudeLocation(mapMarkerLocations)
      }}
      zoom={zoomLocation(mapMarkerLocations)}
      onChange={({ zoom, bounds }) => {
        setZoomState(zoom);
        setBoundsState([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}
    >
      {clusters.map(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: pointCount,
          averageCapacity,
          locations,
          id
        } = cluster.properties;

        if (isCluster) {
          let radius = 6 * locations?.length ?? 0;
          if (radius < 30) radius = 30;
          return (
            <AnyReactComponent key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
              {zoomState >= 18 ? (
                <div style={{ position: "relative", width: "24px", height: "24px" }}>
                  <LocationOnIcon />
                  {locations?.map((location: any, index: number) => {
                    const angle = (index * (2 * Math.PI)) / locations.length;
                    const x = Math.round(24 / 2 + radius * Math.cos(angle) - 30 / 2);
                    const y = Math.round(24 / 2 + radius * Math.sin(angle) - 30 / 2);
                    return (
                      <div
                        style={{ position: "absolute", left: `${x}px`, top: `${y}px` }}
                        key={location.id}
                      >
                        <LocationCapacityMarker
                          mode={mapProps.zoneBookingReport}
                          id={location.id}
                          name={location.name}
                          averageCapacity={location.averageCapacity}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Box
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id as number),
                      20
                    );
                    mapRef?.current?.setZoom(expansionZoom);
                    mapRef?.current?.panTo({ lat: latitude, lng: longitude });
                  }}
                  sx={{
                    width: `${10 + (pointCount / points.length) * 20}px`, // 30
                    height: `${10 + (pointCount / points.length) * 20}px`, // 30
                    background: getColor(averageCapacity), // 'white
                    color: "white",
                    textDecoration: "none",
                    borderRadius: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "2px solid white",
                    cursor: "pointer",
                    boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.75)",
                    "&:hover": {
                      background: "#131421 !important"
                    }
                  }}
                >
                  <Typography style={{ fontWeight: "bold" }}> {pointCount}</Typography>
                </Box>
              )}
            </AnyReactComponent>
          );
        }

        return (
          <AnyReactComponent
            key={`crime-${cluster.properties.id}`}
            lat={latitude}
            lng={longitude}
            children={
              <LocationCapacityMarker
                mode={mapProps.zoneBookingReport}
                id={id}
                name={cluster.properties.name}
                averageCapacity={cluster.properties.averageCapacity}
              />
            }
          />
        );
      })}
    </GoogleMapReact>
  );
};

export default FacilityReportGoogleMap;
