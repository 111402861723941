import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ValidationError } from "myzod";
import { FloorAvailability } from "../../FloorManager/typings/floor-inventory.entity";
import { NotSerializedBookingInputs as BookingInputs, Frequence } from "../typings/booking-inputs";
import { LocationAvailability } from "../typings/booking-location";
import { Equipment, EquipmentFilter } from "../typings/inputs-filter";
import { TeamMember } from "../typings/team-member";

type BookingState = {
  inputs: BookingInputs;
  locations: {
    inventory: LocationAvailability[];
    isLoading: boolean;
    error?: AxiosError | ValidationError;
  };
  floors: {
    inventory: FloorAvailability[];
    isLoading: boolean;
    currentFloorIndex?: number;
    error?: AxiosError | ValidationError;
  };
};

const initialInputs = {
  frequence: Frequence.SINGLE,
  weekdays: {
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true
  },
  automatedSeating: false,
  activityBasedBooking: false,
  timeSpanBigEnough: true
} as BookingInputs;

const initialState: BookingState = {
  inputs: initialInputs,
  locations: {
    inventory: [],
    isLoading: false
  },
  floors: {
    inventory: [],
    isLoading: false
  }
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setInputs(state, action: PayloadAction<Partial<BookingState["inputs"]>>) {
      state.inputs = { ...state.inputs, ...action.payload };
    },

    setEquipment(state, action: PayloadAction<Partial<EquipmentFilter["equipment"]>>) {
      state.inputs.equipment = { ...state.inputs.equipment, ...action.payload };
      const floors = state.floors;
      if (floors.currentFloorIndex !== undefined && floors.inventory) {
        const currentFloor = floors.inventory[floors.currentFloorIndex];
        if (currentFloor) {
          state.inputs.floorInventoryId = currentFloor.id;
        }
      }
    },

    selectBookingLocation(
      state,
      action: PayloadAction<
        Pick<
          BookingState["inputs"],
          | "selectedLocation"
          | "locationName"
          | "isMobileWorking"
          | "timezone"
          | "automatedSeating"
          | "automatedSeatingObjects"
        >
      >
    ) {
      state.inputs = { ...state.inputs, ...action.payload };
    },

    selectSingleWorkplace(state, action: PayloadAction<TeamMember>) {
      state.inputs.usersBookedFor = [action.payload];
    },

    selectTeamMemberWorkplace(
      state,
      action: PayloadAction<{ index: number; placeInventoryId: number }>
    ) {
      const { placeInventoryId, index } = action.payload;

      const user = state.inputs.usersBookedFor && state.inputs.usersBookedFor[index];
      if (user) {
        user.bookingInventoryId = placeInventoryId;
      }
    },

    clearInputs(state) {
      state.inputs = initialInputs;
    },
    clearFloors(state) {
      state.floors = {
        inventory: [],
        isLoading: false
      };
    },

    clearEquipment(state) {
      state.inputs.equipment = undefined;
    },

    setLocations(state, action: PayloadAction<Partial<BookingState["locations"]>>) {
      state.locations = { ...state.locations, ...action.payload };
    },

    setFloors(state, action: PayloadAction<Partial<BookingState["floors"]>>) {
      state.floors = { ...state.floors, ...action.payload };
    },

    setUserEquipment(state, action: PayloadAction<{ userIndex: number; equipment: Equipment }>) {
      const { userIndex, equipment } = action.payload;
      if (state.inputs?.usersBookedFor && state.inputs.usersBookedFor[userIndex]) {
        state.inputs.usersBookedFor[userIndex].equipment = {
          ...state.inputs.usersBookedFor[userIndex].equipment,
          ...equipment
        };
      } else {
        throw new Error(`No booking user on index ${userIndex}`);
      }
    }
  }
});

export default bookingSlice.reducer;
export const {
  setInputs,
  setLocations,
  setFloors,
  setEquipment,
  clearInputs,
  clearEquipment,
  clearFloors,
  selectBookingLocation,
  selectSingleWorkplace,
  selectTeamMemberWorkplace,
  setUserEquipment
} = bookingSlice.actions;
