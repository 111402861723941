import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Topic } from "@mui/icons-material";
import { DateTime } from "luxon";
import { EditableField, InlineFieldEdit } from "../MenuBar/EditableField/EditableField";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";

type P = {
  subject: string;
  onEditSubject?: (value: string) => Promise<any>;
  isEditable: boolean;
  schedule: BookingScheduleInterface;
};

export const ScheduleEventBookingSubject: React.FC<P> = ({
  subject,
  onEditSubject,
  isEditable,
  schedule
}) => {
  const theme = useTheme();
  const [subjectField, setSubjectField] = React.useState<InlineFieldEdit>({
    isEditing: false,
    valueBefore: subject || "",
    value: subject || ""
  });

  return (
    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
      <Grid item sx={{ marginRight: "1rem", marginTop: "0.3rem" }}>
        <Topic />
      </Grid>
      <Grid item xs={12}>
        <Box>
          {isEditable &&
          DateTime.fromISO(schedule.startDate).valueOf() > DateTime.now().valueOf() ? (
            <EditableField
              state={subjectField}
              setState={setSubjectField}
              onEdit={onEditSubject}
              typographySxProps={{
                fontSize: "18px",
                color: theme.palette.text.primary,
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
              data-testid="SubjectField"
            />
          ) : (
            <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>{subject}</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
