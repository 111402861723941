import { Box, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BookingType } from "../../../../../../features/Booking-Form/typings/booking-inputs";
import { TFunction } from "i18next";

type Props = {
  id?: number | string;
  name?: string; // place or zone type name
  onClickAssign?: () => void;
  type: "Inventory" | "Zone" | "Category" | "Equipment" | "Type" | "TablePlacement";
  buttonDataTestId?: string;
};

export function AssignBox({
  id,
  name,
  onClickAssign,
  type,
  buttonDataTestId = "assign-btn"
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const borderColor = isLight ? "#00000066" : "#ffffff66";

  return (
    <Box
      sx={{
        border: `1px solid ${borderColor}`,
        borderRadius: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 1
      }}
    >
      {id ? (
        <Typography fontSize={12}>{assignTypeText(type, name, id, t, namePerType)}</Typography>
      ) : (
        <Typography color={"red"} fontSize={12}>
          {noAssignTypeText(type, t)}
        </Typography>
      )}
      <Box flex={1} />

      {/* hide the button for now, when the assignBox type is Type for place or zone */}
      {type !== "Type" && onClickAssign && (
        <Button
          data-testid={buttonDataTestId}
          size="small"
          onClick={onClickAssign}
          variant={"text"}
        >
          {t("AssignSidebarLabel")}
        </Button>
      )}
    </Box>
  );
}

export const noAssignTypeText = (type: string, t: TFunction) => {
  switch (type) {
    case "Inventory":
      return t("NoInventoryLabel");
    case "Zone":
      return t("NoZoneLabel");
    case "Category":
      return t("NoCategoryLabel");
    case "Equipment":
      return t("NoEquipmentLabel");
    case "TablePlacement":
      return t("TablePlacementTitle");
    default:
      return t("Not set");
  }
};

export function namePerType(name: string, t: TFunction) {
  let str = name;
  switch (name) {
    case BookingType.WORKPLACE:
      str = "Workplace";
      break;
    case BookingType.PARKINGPLACE:
      str = "Parking Place";
      break;
    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      str = "Electric Charging";
      break;
    case BookingType.PLACEZONE:
      str = "Standard";
      break;
    case BookingType.CONFERENCEZONE:
      str = "Conference Zone";
      break;
  }

  return t(str);
}

export const assignTypeText = (
  type: string,
  name: string | undefined,
  id: number | string,
  t: TFunction,
  namePerType: (name: string, t: TFunction) => string
) => {
  // when table placement, no need to display id or name but only title
  if (type === "TablePlacement") return t("TablePlacementTitle");

  return `${t(type)}: ${name ? namePerType(name, t) : id}`;
};
