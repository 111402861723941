import { Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import React from "react";

type P = {
  tooltipText: string;
};

export const InfoIconWithTooltip = (props: P) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      title={<Typography>{props.tooltipText}</Typography>}
      aria-label="add"
    >
      <span>
        <Info style={{ cursor: "pointer" }} />
      </span>
    </Tooltip>
  );
};
