import { useCallback, useEffect, useState } from "react";
import { Button, Fade, Typography, useTheme } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { UserOrEmailMapping } from "../../features/Admin/typings/admin.types";
import { UserRole } from "../../features/Login/typings/auth.types";
import { getAdmin, setAdmin } from "../../utils/axios-requests";
import {
  getEmailsOfUserOrEmailMapping,
  getUserIdsOfUserOrEmailMapping
} from "../../utils/type/type.utils";
import AdminPicker from "./admin-picker.component";
import { StyledAdminSelectionPaper } from "./admin-selection-styled.component";

type P = {
  adminMode: string;
  options: UserOrEmailMapping[];
  enableNext?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @description Component that renders the admins interface.
 * @version 0.2.0
 */
const AdminSelection: ({ adminMode }: P) => JSX.Element = ({ enableNext, adminMode, options }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { keycloak } = useKeycloak();

  const theme = useTheme();

  const {
    userRoles: { isTenantAdmin, isCompanyAdmin, isFinanceAdmin, isHrAdmin },
    userInformation
  } = useSelector((state: RootState) => state.login);

  const [selectedAdmins, setSelectedAdmins] = useState<UserOrEmailMapping[]>([]);

  const adminName = t(adminMode);

  const selectedMode = useCallback(() => {
    switch (adminMode) {
      case "Company Admin":
        return UserRole.isCompanyAdmin;
      case "HR Admin":
        return UserRole.isHrAdmin;
      case "Zone Booking Capable":
        return UserRole.isZoneBookingCapable;
      case "Device Responsible":
        return UserRole.isEquipmentResponsible;
      default:
        return UserRole.isZoneBookingCapable;
    }
  }, [adminMode]);

  /**
   * @description Effect that gets all users and current admin users when mounted.
   * @version 0.1.0
   */
  useEffect(() => {
    const getAdmins = async () => {
      const admins = await getAdmin(selectedMode());
      setSelectedAdmins(admins.data ?? []);
      if (enableNext && admins.data.length) {
        enableNext(true);
      }
    };

    getAdmins();
  }, [
    enqueueSnackbar,
    isCompanyAdmin,
    isTenantAdmin,
    isFinanceAdmin,
    isHrAdmin,
    selectedMode,
    enableNext
  ]);

  /**
   * @description Function that submits the chosen users as new admins to the API.
   * @version 0.1.0
   */
  const submitAdminRights = () => {
    const data = {
      userIds: getUserIdsOfUserOrEmailMapping(selectedAdmins),
      adminType: selectedMode(),
      emails: getEmailsOfUserOrEmailMapping(selectedAdmins)
    };
    setAdmin(data)
      .then(() => {
        if (
          enableNext &&
          selectedAdmins.filter(user => user.userId === userInformation.sub).length !== 0
        ) {
          enableNext(true);
          keycloak.login();
        } else if (enableNext) enableNext(false);
        enqueueSnackbar(t("EditedAdminModeUsers", { adminMode }), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("ErrorProvidingRights", { adminMode }), {
          variant: "error"
        });
      });
  };

  return (
    <Fade in={!!adminMode}>
      <StyledAdminSelectionPaper theme={theme}>
        <Typography variant={"h5"} sx={{ mb: 1 }}>
          {adminName}
        </Typography>
        <AdminPicker
          handleChange={(e: React.ChangeEvent<UserOrEmailMapping>, values: UserOrEmailMapping[]) =>
            setSelectedAdmins(values)
          }
          values={selectedAdmins}
          placeholder={t("AddAdminMode", { adminName })}
          options={options}
        />

        <br />
        <Button
          data-testid={"submit"}
          variant="contained"
          color="primary"
          onClick={submitAdminRights}
        >
          {t("Set New Admins")}
        </Button>
      </StyledAdminSelectionPaper>
    </Fade>
  );
};

export default AdminSelection;
