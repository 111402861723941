import { Card, styled } from "@mui/material";

export const StyledCardRoot = styled(Card, {
  name: "StyledCardRoot"
})(() => ({
  maxWidth: 345,
  minWidth: 345,
  marginRight: "10px",
  marginBottom: "10px"
}));
