import { SelectedZoneOrWorkplace } from "../../../features/Reports/slices/report.slice";
import { CapacityObject, MapMarkerLocation } from "../../../features/Reports/typings/reports.types";
import { SingleSuperCluster } from "../useSupercluster";
import i18n from "i18next";

export const latitudeLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0]
    ? mapMarkerLocations[0]?.geoInformation[0]?.latitude
    : 50;

export const longitudeLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0]
    ? mapMarkerLocations[0]?.geoInformation[0]?.longitude
    : 6;

export const zoomLocation = (mapMarkerLocations: MapMarkerLocation[] | undefined) =>
  mapMarkerLocations && mapMarkerLocations[0] ? (20 / mapMarkerLocations.length) * 0.8 : 5;

export const currentEntityData = (
  selectedZoneOrWorkplace: SelectedZoneOrWorkplace,
  capacitySelectedWorkplaceOrZone: any,
  currentFloors: { id: number; open: boolean }[],
  capacitySelectedFloors?: CapacityObject[]
) =>
  selectedZoneOrWorkplace?.open
    ? [capacitySelectedWorkplaceOrZone]
    : capacitySelectedFloors?.filter(floor => {
        return currentFloors.some(activeFloor => activeFloor.id === floor.id);
      });

export function compareClustersCoordinates(superClusterClusters: SingleSuperCluster[]) {
  if (superClusterClusters.some(cluster => cluster.properties.cluster === false)) {
    const newClu = superClusterClusters.map((cluster, index) => {
      // skip the comparison for the first cluster(index = 0)
      if (!index) return cluster;

      const isSameLongitudeAsPreviousCluster =
        cluster.geometry.coordinates[0] === superClusterClusters[0].geometry.coordinates[0];
      const isSameLatitudeAsPreviousCluster =
        cluster.geometry.coordinates[1] === superClusterClusters[0].geometry.coordinates[1];

      if (isSameLongitudeAsPreviousCluster && isSameLatitudeAsPreviousCluster) {
        cluster.geometry.coordinates[0] = cluster.geometry.coordinates[0] + index * 0.00018;
      }
      return cluster;
    });
    return newClu;
  }
  return superClusterClusters;
}

export type Currency = { symbol: string; name: string; code: string };
export const currencies: Currency[] = [
  {
    symbol: "$",
    name: i18n.t("US Dollar"),
    code: "USD"
  },
  {
    symbol: "CA$",
    name: i18n.t("Canadian Dollar"),
    code: "CAD"
  },
  {
    symbol: "€",
    name: i18n.t("Euro"),
    code: "EUR"
  },
  {
    symbol: "AED",
    name: i18n.t("United Arab Emirates Dirham"),
    code: "AED"
  },
  {
    symbol: "Af",
    name: i18n.t("Afghan Afghani"),
    code: "AFN"
  },
  {
    symbol: "ALL",
    name: i18n.t("Albanian Lek"),
    code: "ALL"
  },
  {
    symbol: "AMD",
    name: i18n.t("Armenian Dram"),
    code: "AMD"
  },
  {
    symbol: "AR$",
    name: i18n.t("Argentine Peso"),
    code: "ARS"
  },
  {
    symbol: "AU$",
    name: i18n.t("Australian Dollar"),
    code: "AUD"
  },
  {
    symbol: "man.",
    name: i18n.t("Azerbaijani Manat"),
    code: "AZN"
  },
  {
    symbol: "KM",
    name: i18n.t("Bosnia-Herzegovina Convertible Mark"),
    code: "BAM"
  },
  {
    symbol: "Tk",
    name: i18n.t("Bangladeshi Taka"),
    code: "BDT"
  },
  {
    symbol: "BGN",
    name: i18n.t("Bulgarian Lev"),
    code: "BGN"
  },
  {
    symbol: "BD",
    name: i18n.t("Bahraini Dinar"),
    code: "BHD"
  },
  {
    symbol: "FBu",
    name: i18n.t("Burundian Franc"),
    code: "BIF"
  },
  {
    symbol: "BN$",
    name: i18n.t("Brunei Dollar"),
    code: "BND"
  },
  {
    symbol: "Bs",
    name: i18n.t("Bolivian Boliviano"),
    code: "BOB"
  },
  {
    symbol: "R$",
    name: i18n.t("Brazilian Real"),
    code: "BRL"
  },
  {
    symbol: "BWP",
    name: i18n.t("Botswanan Pula"),
    code: "BWP"
  },
  {
    symbol: "Br",
    name: i18n.t("Belarusian Ruble"),
    code: "BYN"
  },
  {
    symbol: "BZ$",
    name: i18n.t("Belize Dollar"),
    code: "BZD"
  },
  {
    symbol: "CDF",
    name: i18n.t("Congolese Franc"),
    code: "CDF"
  },
  {
    symbol: "CHF",
    name: i18n.t("Swiss Franc"),
    code: "CHF"
  },
  {
    symbol: "CL$",
    name: i18n.t("Chilean Peso"),
    code: "CLP"
  },
  {
    symbol: "CN¥",
    name: i18n.t("Chinese Yuan"),
    code: "CNY"
  },
  {
    symbol: "CO$",
    name: i18n.t("Colombian Peso"),
    code: "COP"
  },
  {
    symbol: "₡",
    name: i18n.t("Costa Rican Colón"),
    code: "CRC"
  },
  {
    symbol: "CV$",
    name: i18n.t("Cape Verdean Escudo"),
    code: "CVE"
  },
  {
    symbol: "Kč",
    name: i18n.t("Czech Republic Koruna"),
    code: "CZK"
  },
  {
    symbol: "Fdj",
    name: i18n.t("Djiboutian Franc"),
    code: "DJF"
  },
  {
    symbol: "Dkr",
    name: i18n.t("Danish Krone"),
    code: "DKK"
  },
  {
    symbol: "RD$",
    name: i18n.t("Dominican Peso"),
    code: "DOP"
  },
  {
    symbol: "DA",
    name: i18n.t("Algerian Dinar"),
    code: "DZD"
  },
  {
    symbol: "Ekr",
    name: i18n.t("Estonian Kroon"),
    code: "EEK"
  },
  {
    symbol: "EGP",
    name: i18n.t("Egyptian Pound"),
    code: "EGP"
  },
  {
    symbol: "Nfk",
    name: i18n.t("Eritrean Nakfa"),
    code: "ERN"
  },
  {
    symbol: "Br",
    name: i18n.t("Ethiopian Birr"),
    code: "ETB"
  },
  {
    symbol: "£",
    name: i18n.t("British Pound Sterling"),
    code: "GBP"
  },
  {
    symbol: "GEL",
    name: i18n.t("Georgian Lari"),
    code: "GEL"
  },
  {
    symbol: "GH₵",
    name: i18n.t("Ghanaian Cedi"),
    code: "GHS"
  },
  {
    symbol: "FG",
    name: i18n.t("Guinean Franc"),
    code: "GNF"
  },
  {
    symbol: "GTQ",
    name: i18n.t("Guatemalan Quetzal"),
    code: "GTQ"
  },
  {
    symbol: "HK$",
    name: i18n.t("Hong Kong Dollar"),
    code: "HKD"
  },
  {
    symbol: "HNL",
    name: i18n.t("Honduran Lempira"),
    code: "HNL"
  },
  {
    symbol: "kn",
    name: i18n.t("Croatian Kuna"),
    code: "HRK"
  },
  {
    symbol: "Ft",
    name: i18n.t("Hungarian Forint"),
    code: "HUF"
  },
  {
    symbol: "Rp",
    name: i18n.t("Indonesian Rupiah"),
    code: "IDR"
  },
  {
    symbol: "₪",
    name: i18n.t("Israeli New Sheqel"),
    code: "ILS"
  },
  {
    symbol: "Rs",
    name: i18n.t("Indian Rupee"),
    code: "INR"
  },
  {
    symbol: "IQD",
    name: i18n.t("Iraqi Dinar"),
    code: "IQD"
  },
  {
    symbol: "IRR",
    name: i18n.t("Iranian Rial"),
    code: "IRR"
  },
  {
    symbol: "Ikr",
    name: i18n.t("Icelandic Króna"),
    code: "ISK"
  },
  {
    symbol: "J$",
    name: i18n.t("Jamaican Dollar"),
    code: "JMD"
  },
  {
    symbol: "JD",
    name: i18n.t("Jordanian Dinar"),
    code: "JOD"
  },
  {
    symbol: "¥",
    name: i18n.t("Japanese Yen"),
    code: "JPY"
  },
  {
    symbol: "Ksh",
    name: i18n.t("Kenyan Shilling"),
    code: "KES"
  },
  {
    symbol: "KHR",
    name: i18n.t("Cambodian Riel"),
    code: "KHR"
  },
  {
    symbol: "CF",
    name: i18n.t("Comorian Franc"),
    code: "KMF"
  },
  {
    symbol: "₩",
    name: i18n.t("South Korean Won"),
    code: "KRW"
  },
  {
    symbol: "KD",
    name: i18n.t("Kuwaiti Dinar"),
    code: "KWD"
  },
  {
    symbol: "KZT",
    name: i18n.t("Kazakhstani Tenge"),
    code: "KZT"
  },
  {
    symbol: "LB£",
    name: i18n.t("Lebanese Pound"),
    code: "LBP"
  },
  {
    symbol: "SLRs",
    name: i18n.t("Sri Lankan Rupee"),
    code: "LKR"
  },
  {
    symbol: "Lt",
    name: i18n.t("Lithuanian Litas"),
    code: "LTL"
  },
  {
    symbol: "Ls",
    name: i18n.t("Latvian Lats"),
    code: "LVL"
  },
  {
    symbol: "LD",
    name: i18n.t("Libyan Dinar"),
    code: "LYD"
  },
  {
    symbol: "MAD",
    name: i18n.t("Moroccan Dirham"),
    code: "MAD"
  },
  {
    symbol: "MDL",
    name: i18n.t("Moldovan Leu"),
    code: "MDL"
  },
  {
    symbol: "MGA",
    name: i18n.t("Malagasy Ariary"),
    code: "MGA"
  },
  {
    symbol: "MKD",
    name: i18n.t("Macedonian Denar"),
    code: "MKD"
  },
  {
    symbol: "MMK",
    name: i18n.t("Myanma Kyat"),
    code: "MMK"
  },
  {
    symbol: "MOP$",
    name: i18n.t("Macanese Pataca"),
    code: "MOP"
  },
  {
    symbol: "MURs",
    name: i18n.t("Mauritian Rupee"),
    code: "MUR"
  },
  {
    symbol: "MX$",
    name: i18n.t("Mexican Peso"),
    code: "MXN"
  },
  {
    symbol: "RM",
    name: i18n.t("Malaysian Ringgit"),
    code: "MYR"
  },
  {
    symbol: "MTn",
    name: i18n.t("Mozambican Metical"),
    code: "MZN"
  },
  {
    symbol: "N$",
    name: i18n.t("Namibian Dollar"),
    code: "NAD"
  },
  {
    symbol: "₦",
    name: i18n.t("Nigerian Naira"),
    code: "NGN"
  },
  {
    symbol: "C$",
    name: i18n.t("Nicaraguan Córdoba"),
    code: "NIO"
  },
  {
    symbol: "Nkr",
    name: i18n.t("Norwegian Krone"),
    code: "NOK"
  },
  {
    symbol: "NPRs",
    name: i18n.t("Nepalese Rupee"),
    code: "NPR"
  },
  {
    symbol: "NZ$",
    name: i18n.t("New Zealand Dollar"),
    code: "NZD"
  },
  {
    symbol: "OMR",
    name: i18n.t("Omani Rial"),
    code: "OMR"
  },
  {
    symbol: "B/.",
    name: i18n.t("Panamanian Balboa"),
    code: "PAB"
  },
  {
    symbol: "S/.",
    name: i18n.t("Peruvian Nuevo Sol"),
    code: "PEN"
  },
  {
    symbol: "₱",
    name: i18n.t("Philippine Peso"),
    code: "PHP"
  },
  {
    symbol: "PKRs",
    name: i18n.t("Pakistani Rupee"),
    code: "PKR"
  },
  {
    symbol: "zł",
    name: i18n.t("Polish Zloty"),
    code: "PLN"
  },
  {
    symbol: "₲",
    name: i18n.t("Paraguayan Guarani"),
    code: "PYG"
  },
  {
    symbol: "QR",
    name: i18n.t("Qatari Rial"),
    code: "QAR"
  },
  {
    symbol: "RON",
    name: i18n.t("Romanian Leu"),
    code: "RON"
  },
  {
    symbol: "din.",
    name: i18n.t("Serbian Dinar"),
    code: "RSD"
  },
  {
    symbol: "RUB",
    name: i18n.t("Russian Ruble"),
    code: "RUB"
  },
  {
    symbol: "RWF",
    name: i18n.t("Rwandan Franc"),
    code: "RWF"
  },
  {
    symbol: "SR",
    name: i18n.t("Saudi Riyal"),
    code: "SAR"
  },
  {
    symbol: "SDG",
    name: i18n.t("Sudanese Pound"),
    code: "SDG"
  },
  {
    symbol: "Skr",
    name: i18n.t("Swedish Krona"),
    code: "SEK"
  },
  {
    symbol: "S$",
    name: i18n.t("Singapore Dollar"),
    code: "SGD"
  },
  {
    symbol: "Ssh",
    name: i18n.t("Somali Shilling"),
    code: "SOS"
  },
  {
    symbol: "SY£",
    name: i18n.t("Syrian Pound"),
    code: "SYP"
  },
  {
    symbol: "฿",
    name: i18n.t("Thai Baht"),
    code: "THB"
  },
  {
    symbol: "DT",
    name: i18n.t("Tunisian Dinar"),
    code: "TND"
  },
  {
    symbol: "T$",
    name: i18n.t("Tongan Paʻanga"),
    code: "TOP"
  },
  {
    symbol: "TL",
    name: i18n.t("Turkish Lira"),
    code: "TRY"
  },
  {
    symbol: "TT$",
    name: i18n.t("Trinidad and Tobago Dollar"),
    code: "TTD"
  },
  {
    symbol: "NT$",
    name: i18n.t("New Taiwan Dollar"),
    code: "TWD"
  },
  {
    symbol: "TSh",
    name: i18n.t("Tanzanian Shilling"),
    code: "TZS"
  },
  {
    symbol: "₴",
    name: i18n.t("Ukrainian Hryvnia"),
    code: "UAH"
  },
  {
    symbol: "USh",
    name: i18n.t("Ugandan Shilling"),
    code: "UGX"
  },
  {
    symbol: "$U",
    name: i18n.t("Uruguayan Peso"),
    code: "UYU"
  },
  {
    symbol: "UZS",
    name: i18n.t("Uzbekistan Som"),
    code: "UZS"
  },
  {
    symbol: "Bs.F.",
    name: i18n.t("Venezuelan Bolívar"),
    code: "VEF"
  },
  {
    symbol: "₫",
    name: i18n.t("Vietnamese Dong"),
    code: "VND"
  },
  {
    symbol: "FCFA",
    name: i18n.t("CFA Franc BEAC"),
    code: "XAF"
  },
  {
    symbol: "CFA",
    name: i18n.t("CFA Franc BCEAO"),
    code: "XOF"
  },
  {
    symbol: "YR",
    name: i18n.t("Yemeni Rial"),
    code: "YER"
  },
  {
    symbol: "R",
    name: i18n.t("South African Rand"),
    code: "ZAR"
  },
  {
    symbol: "ZK",
    name: i18n.t("Zambian Kwacha"),
    code: "ZMK"
  },
  {
    symbol: "ZWL$",
    name: i18n.t("Zimbabwean Dollar"),
    code: "ZWL"
  }
];
