import { useEffect, useState } from "react";
import { Fade, Grid } from "@mui/material";
import { EquipmentCategory } from "./typings/svg.types";
import EditableCategoryList from "../../components/Category/editable-category-list.component";
import FilterableEquipmentList from "../../components/EquipmentList/FilterableEquipmentList/filterable-equipment-list";
import { StyledItemBox } from "./partials/FloorManager.styled.component";
import { useRemoteFetchCategoryPerType } from "../../hooks/Remote/Category/useRemoteFetchCategoryPerType";

/**
 * @version 0.2.0
 */
const EquipmentManagement = () => {
  const [equipmentCategories, setEquipmentCategories] = useState<EquipmentCategory[]>([]);

  const { data: remoteCategories, isLoading: isCategoriesLoading } = useRemoteFetchCategoryPerType({
    type: "equipment-category"
  });

  useEffect(() => {
    if (!remoteCategories) return;
    setEquipmentCategories(remoteCategories);
  }, [isCategoriesLoading]);

  return (
    <Fade in>
      <Grid container>
        {/* list of filterable equipment inventories */}
        <StyledItemBox data-testid="list-filterable-equip-inventory">
          <FilterableEquipmentList equipmentCategories={equipmentCategories} />
        </StyledItemBox>

        {/* list of equipment categories */}
        <StyledItemBox data-testid="list-editable-equip-category">
          <EditableCategoryList
            categoryType="equipment-category"
            usedHeadlineKey="Equipment Categories"
          />
        </StyledItemBox>

        {/* list of place categories */}
        <StyledItemBox data-testid="list-place-category">
          <EditableCategoryList categoryType="place-category" usedHeadlineKey="Places Categories" />
        </StyledItemBox>

        {/* list of zone categories */}
        <StyledItemBox data-testid="list-zone-category">
          <EditableCategoryList categoryType="zone-category" usedHeadlineKey="Zone Categories" />
        </StyledItemBox>
      </Grid>
    </Fade>
  );
};

export default EquipmentManagement;
