import { Button, Grid, useTheme } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteCheckout } from "../../../hooks/Remote/Checkin/useRemoteCheckout";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";

type P = {
  schedule: BookingScheduleInterface;
  refetchAllSchedule: () => void;
};

/**
 * button to check out when done working on a site
 * @param schedule
 * @constructor
 */
export const ScheduleEventCheckoutButton: React.FC<P> = ({ schedule, refetchAllSchedule }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    mutate: mutateCheckout,
    isSuccess: isCheckoutSuccess,
    status: checkoutStatus
  } = useRemoteCheckout(schedule.bookingType as BookingType, schedule.id);

  useEffect(() => {
    if (isCheckoutSuccess && checkoutStatus === "success") refetchAllSchedule();
  }, [isCheckoutSuccess, checkoutStatus, schedule]);

  function handleClick() {
    mutateCheckout(undefined);
  }

  const shouldShowCheckoutButton = useMemo(
    () =>
      schedule.checkedIn &&
      DateTime.fromISO(schedule.startDate).valueOf() <=
        DateTime.fromISO(schedule.endDate).valueOf(),
    [schedule.checkedIn, schedule.endDate, schedule.startDate]
  );

  return shouldShowCheckoutButton ? (
    <Grid item xs={5} data-testid="schedule-item-checkedout-btn-grid">
      <Button
        onClick={handleClick}
        color="primary"
        variant="contained"
        data-testid={"schedule-event-checkout-button"}
        sx={{
          height: "100%",
          width: "100%",
          [theme.breakpoints.down('md')]: {
            padding: "5px 10px",
            fontSize: "0.7rem"
          }
        }}
      >
        {t("Check out")}
      </Button>
    </Grid>
  ) : null;
};
