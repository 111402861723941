import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { Alert, Box, Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  clearFloors,
  clearInputs,
  setInputs
} from "../../features/Booking-Form/slices/booking.slice";
import { NotSerializedBookingMode } from "../../features/Booking-Form/typings/booking-inputs";
import { LocationInventory } from "../../features/FloorManager/typings/location-inventory";
import { useRemoteFetchCompanyLocations } from "../../hooks/Remote/Location/useRemoteFetchCompanyLocations";
import { getBookingType } from "./booking.utils";
import BookingSettingsView from "./BookingSettingsView/BookingSettingsView";
import { PlaceType, ZoneType } from "../../features/Login/typings/login.types";
import { FormSelectionBookingType } from "./form-selection-bookingType.component";

/**
 * @description Component that displays the various booking modes.
 * @param props.setInputs is the main state input update.
 * @param props.setPicker is the selected date and time picker mode.
 * @param props.changeAction is the function that cleans up state after going a step back.
 * @version 0.1.0
 */

type Props = {
  nextStep: () => void;
  setPicker: Dispatch<SetStateAction<boolean>>;
  changeAction?: () => void;
};

const FormSelection: React.FC<Props> = ({ nextStep, changeAction }) => {
  const {
    userInformation: { sub, surname, firstName, email },
    settings: { preferredLocations, preferredPlaceCategories }
  } = useSelector((state: RootState) => state.login);
  const { inputs } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [doubleCheckLocations, setDoubleCheckLocations] = useState<LocationInventory[]>([]);

  const { data: companyLocations, isLoading: companyLocationsLoading } =
    useRemoteFetchCompanyLocations({
      isFinanceRequest: false,
      noMobileWorking: true,
      isBookingSettings: true
    });

  /**
   * @description function that passes the current user as person who is booking.
   * @version 0.1.0
   * @param mode
   */

  const onModeChange = (mode: NotSerializedBookingMode) => {
    changeAction && changeAction();
    dispatch(clearInputs());
    dispatch(clearFloors());

    dispatch(
      setInputs({
        mode: mode,
        bookingType: getBookingType(mode),
        usersBookedFor: [
          {
            userId: sub,
            firstName,
            surname: surname,
            isExternal: false,
            email: email
          }
        ],
        isUserBookedBy: true,
        automatedSeating: false
      })
    );
    nextStep();
  };

  const checkPreferredAndDouble =
    preferredLocations &&
    preferredLocations.length > 0 &&
    preferredPlaceCategories &&
    preferredPlaceCategories.length > 0 &&
    doubleCheckLocations.length > 0;

  useEffect(() => {
    if (companyLocations) setDoubleCheckLocations(companyLocations);
  }, [companyLocationsLoading, companyLocations]);

  useEffect(() => {
    if (inputs.booked) {
      dispatch(clearInputs());
    }
  }, [inputs, dispatch]);

  return (
    <Fragment>
      <Grid justifyContent="center" container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          data-testid="box"
          width={"100%"}
          flexDirection={"column"}
        >
          <BookingSettingsView />

          {checkPreferredAndDouble ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems="stretch"
              flexDirection={"row"}
              flexWrap="wrap"
              data-testid="select-booking-mode"
              width={"75vw"}
            >
              <FormSelectionBookingType onModeChange={onModeChange} />
            </Box>
          ) : (
            <Alert style={{ marginTop: 10 }} severity="warning">
              {t("Please select preferred locations")}
            </Alert>
          )}
        </Box>
      </Grid>
    </Fragment>
  );
};

export default FormSelection;

export const StyledIconComponent = styled("svg")(({ theme }) => ({
  width: "120px",
  height: "90px",
  marginBottom: "10px",
  "& path": {
    fill: theme.palette.text.primary
  }
}));

export const fitAndCorrectType = (
  typeName: string,
  placeTypes: PlaceType[],
  zoneTypes: ZoneType[]
) =>
  placeTypes.filter(type => type.name === typeName).length > 0 ||
  zoneTypes.filter(type => type.name === typeName).length > 0;
