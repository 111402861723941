import { ProviderContext } from "notistack";
import { IZoneInventory } from "../../../../Domain/Types/FloorPlan/ZoneInventory.type";
import { TFunction } from "i18next";
import axios from "axios";
import { IZoneSchedule, IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IDeviceInventory } from "../../../../Domain/Types/FloorPlan/Device.type";

export async function apiPatchZoneInventory(
  arg: IZoneInventory,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  t: TFunction
) {
  try {
    const result = await axios.patch(`/inventory/zone/${arg.id}`, arg);
    return result.data;
  } catch (err) {
    enqueueSnackbar(t(`Failed to update zone inventory with id ${arg.id}`), {
      variant: "error"
    });
  }
}

export function handlePatchZoneInventory({
  zoneInventory,
  enqueueSnackbar,
  t,
  newName
}: {
  zoneInventory: IZoneInventory | undefined;
  enqueueSnackbar: ProviderContext["enqueueSnackbar"];
  t: TFunction;
  newName?: string;
}) {
  if (!zoneInventory || !newName) return;

  if (newName) zoneInventory.name = newName;

  apiPatchZoneInventory(zoneInventory, enqueueSnackbar, t);
}

export function handleUserNumbersInput(
  id: string,
  value: string | number,
  zoneId: number,
  zoneUserNumber: IZoneUserNumber,
  setZoneUserNumber: (n: IZoneUserNumber) => void,
  onChangeUserNumber: (ids: number[], userNumber: IZoneUserNumber) => void,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) {
  switch (id) {
    case "min":
      if (Number(value) < 0) {
        return enqueueSnackbar("Min users should be at least 0", {
          variant: "error"
        });
      }
      if (zoneUserNumber.maxUsers && Number(value) > zoneUserNumber.maxUsers) {
        return enqueueSnackbar("Min users cannot be higher than Max users", {
          variant: "error"
        });
      }

      onChangeUserNumber([zoneId], {
        minUsers: Number(value),
        maxUsers: zoneUserNumber.maxUsers
      });
      return;
    case "max":
      if (Number(value) === 0) {
        setZoneUserNumber({
          minUsers: zoneUserNumber.minUsers,
          maxUsers: null
        });
        return onChangeUserNumber([zoneId], {
          minUsers: zoneUserNumber.minUsers,
          maxUsers: null
        });
      }

      if (zoneUserNumber.minUsers && zoneUserNumber.minUsers > Number(value)) {
        return enqueueSnackbar("Min users cannot be higher than Max users", {
          variant: "error"
        });
      }

      onChangeUserNumber([zoneId], {
        minUsers: zoneUserNumber.minUsers,
        maxUsers: Number(value)
      });
      return;
  }
}

export function initialUserNumbers(zoneSchedules: IZoneSchedule[]) {
  const minUsers = zoneSchedules[0]?.minUsers ?? zoneSchedules[0]?.inventory?.minUsers ?? 0;

  let maxUsers: number | null = null;

  if (zoneSchedules[0]?.maxUsers !== undefined && zoneSchedules[0].maxUsers !== null)
    maxUsers = zoneSchedules[0].maxUsers;
  if (zoneSchedules[0]?.maxUsers === undefined && zoneSchedules[0]?.inventory?.maxUsers)
    maxUsers = zoneSchedules[0].inventory.maxUsers;

  return { minUsers, maxUsers };
}

export function isEntryAssigned(entry: IDeviceInventory, zones: IZoneSchedule[]) {
  return zones.some(zone => (zone.equipmentInventoryIds || []).includes(entry.id));
}

export function updateAssingedDevice(
  zoneSchedules: IZoneSchedule[],
  setAssginedDevice: (d: IDeviceInventory[]) => void
) {
  if (!zoneSchedules[0] || !zoneSchedules[0].equipments) return;

  return setAssginedDevice([...zoneSchedules[0].equipments]);
}

export function updateMutatedZoneInventory(
  mutatedZoneInventory: IZoneInventory | undefined,
  zoneSchedules: IZoneSchedule[],
  updateZoneInventory: (zoneIds: number[], inventory: IZoneInventory) => void
) {
  if (mutatedZoneInventory && zoneSchedules) {
    updateZoneInventory(
      zoneSchedules.map(z => z.id),
      mutatedZoneInventory
    );
  }
}
