import React, { useState } from "react";
import { Box, Divider, Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TabPanelProps } from "../Connections/Colleagues/colleagues.component";
import MetaAdministration from "./meta-administration.component";
import Departments from "./departments.component";
import UserManagement from "./UserManagement/user-management.component";

export const Organization = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [valueState, setValueState] = useState(0);

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
    setValueState(newValue);
  };

  return (
    <Box sx={{ "& > *": { margin: theme.spacing(1) } }} data-testid="organization-tabs-parent">
      <Tabs value={valueState} onChange={handleChange} aria-label="simple tabs example">
        <Tab data-testid={"tab1"} label={t("Company")} {...a11yProps(0)} />
        <Tab data-testid={"tab2"} label={t("Departments")} {...a11yProps(1)} />
        <Tab data-testid={"tab3"} label={t("User")} {...a11yProps(2)} />
      </Tabs>
      <Divider style={{ width: "100%" }} variant={"fullWidth"} />
      <TabPanel value={valueState} index={0}>
        <MetaAdministration />
      </TabPanel>
      <TabPanel value={valueState} index={1}>
        <Departments />
      </TabPanel>
      <TabPanel value={valueState} index={2}>
        <UserManagement />
      </TabPanel>
    </Box>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      data-testid="org-simple-tabpanel"
      {...other}
    >
      {value === index && (
        <Box style={{ padding: "20px 0 20px 0" }} data-testid="org-page-box">
          <div data-testid="org-page-child">{children}</div>
        </Box>
      )}
    </div>
  );
}
