import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleFiltersButton
} from "material-react-table";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AddBox, Delete } from "@mui/icons-material";
import { ICategoryCrud } from "../FacilityManager/Domain/Types/FloorPlan/ScheduleCategory.type";

type Props = {
  table: MRT_TableInstance<ICategoryCrud>;
  usedHeadlineKey: string;
  disabledDeleteButton: boolean;
  onDelete: () => void;
};
export const EditableCategoryListToolbar = ({
  table,
  usedHeadlineKey,
  disabledDeleteButton,
  onDelete
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          p: "10px",
          justifyContent: "space-between"
        }}
      >
        <Typography variant={"h5"} sx={{ pl: 1 }}>
          {t(usedHeadlineKey)}
        </Typography>

        <Grid item sx={{ display: "flex" }}>
          {/* import MRT sub-components */}
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />

          {/* add new category button */}
          <Tooltip title={t("Add")}>
            <IconButton
              data-testid="Add-new-category-btn"
              onClick={() => table.setCreatingRow(true)}
              size="large"
            >
              <AddBox />
            </IconButton>
          </Tooltip>

          {/* multi deletion */}
          <Tooltip title={t("Delete selection")}>
            <span>
              <IconButton
                color={"error"}
                data-testid="handle-multidelete-category-btn"
                disabled={disabledDeleteButton}
                onClick={onDelete}
                size="large"
              >
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};
