import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { useTranslation } from "react-i18next";
import { ChevronRight, Close, Update } from "@mui/icons-material";
import { useState } from "react";
import { PlaceBookingDetailDialog } from "../PlaceSelectionPrompt/PlaceBookingDetailDialog";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { useRemoteFetchBookingStatusInformation } from "../../../../../../hooks/Remote/Booking/useRemoteFetchBookingStatusInformation";
import { useDispatch } from "../../../../../../app/helpers";
import { setInputs } from "../../../../../../features/Booking-Form/slices/booking.slice";
import { checkWeekdays, generateBtnText, occupiedFound } from "./SelectionPrompt.functions";

interface Props {
  selected: { place: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined };
  /** true when the selected place is available, false when it is occupied/disabled/not permitted */
  selectableByVariant: boolean;
  bookingInputs: BookingInputs;
  userIndex: number;
  userId: string;
  onConfirm: () => void;
  onClose: (e: any) => void;
  setIsDateOpen: (o: boolean) => void;
}

export function SelectionPrompt({
  selected,
  selectableByVariant = true,
  bookingInputs,
  userIndex,
  userId,
  onConfirm,
  onClose,
  setIsDateOpen
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  // when is mobile or tablet, display selection prompt as dialog in the center
  const isBrowser = useMediaQuery(theme.breakpoints.up(1200));

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const selectedEntry = selected.place || selected.zone;

  const { data: statusInformationData } = useRemoteFetchBookingStatusInformation(
    {
      bookingInventoryIds: [selectedEntry?.inventoryId || selectedEntry?.inventory?.id],
      startDate: bookingInputs.bookingFrom,
      endDate: bookingInputs.bookingTo,
      startTime: bookingInputs.bookingStart,
      endTime: bookingInputs.bookingEnd,
      bookingType: bookingInputs.bookingType,
      timezone: bookingInputs.timezone,
      weekdays: checkWeekdays(bookingInputs.weekdays)
    },
    !!selected && !selectableByVariant // make a request only when the selected place is not available
  );

  const occupiedStatus = statusInformationData?.status.flatMap(sta => sta.occupiedStatus);

  return (
    <>
      <Card sx={{ p: 2, minWidth: isBrowser ? 400 : "fit-content" }}>
        {selectedEntry && (
          <Stack direction={"column"} spacing={2} data-testid="selection-prompt">
            <Grid data-testid="selection-prompt-header" container>
              <Grid item xs={isBrowser ? 11 : 8} sx={{ alignSelf: "center" }}>
                <Typography align={"left"} variant={"h5"}>
                  {selectedEntry.inventory?.name}
                </Typography>
              </Grid>
              {/* allow to change schedule time */}
              <Grid item xs={isBrowser ? 1 : 4} sx={{ textAlign: "right", placeSelf: "center" }}>
                <IconButton
                  data-testid="change-time"
                  onClick={() => setIsDateOpen(true)}
                  aria-label="change time"
                  color={"primary"}
                >
                  <Update />
                </IconButton>
                {!isBrowser && (
                  <IconButton data-testid="close-prompt-btn" onClick={onClose} color={"primary"}>
                    <Close />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            {/* if occupied booking is existed */}
            {!selectableByVariant && (
              <Box>
                {(!statusInformationData || !occupiedStatus) && <CircularProgress size={16} />}
                {occupiedStatus && occupiedStatus?.length >= 1 && (
                  <Grid container>
                    <Grid item sx={{ alignSelf: "center" }} data-testid="occupied-content-text">
                      <Typography>{occupiedFound(occupiedStatus.length, t)}</Typography>
                    </Grid>
                    <Grid
                      data-testid="occupied-content-btn-item"
                      item
                      xs
                      sx={{ textAlign: "right" }}
                    >
                      <IconButton
                        sx={{ marginLeft: "2vw" }}
                        onClick={() => setIsDetailModalOpen(true)}
                        data-testid="occupied-content-button"
                        size="large"
                      >
                        <ChevronRight />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {occupiedStatus?.length === 0 && <Typography>-</Typography>}
              </Box>
            )}

            {/* category name */}
            <Box>
              <Typography variant={"h6"}>{t("Type")}</Typography>
              <Typography>{selectedEntry.category?.name ?? "-"}</Typography>
            </Box>

            {/* description */}
            <Box
              data-testid="prompt-desc-box-parent"
              sx={{
                whiteSpace: "pre-line",
                overflowY: "unset",
                height: isBrowser ? "inherit" : "fit-content"
              }}
            >
              <Typography variant={"h6"}>{t("Description")}</Typography>
              <Box
                data-testid="prompt-desc-box"
                sx={{
                  whiteSpace: "pre-line",
                  overflowY: isBrowser ? "unset" : "auto",
                  maxHeight: isBrowser ? "unset" : "40vh"
                }}
              >
                <Typography>{selectedEntry?.description}</Typography>
              </Box>
            </Box>

            {/* confirm button */}
            <Button
              disabled={!selectableByVariant}
              variant={"contained"}
              data-testid={"select-pl-btn"}
              onClick={() => {
                onConfirm();
                dispatch(setInputs({ bookingDays: statusInformationData?.bookingDays }));
              }}
            >
              {generateBtnText(
                selectableByVariant,
                bookingInputs,
                userIndex,
                userId,
                statusInformationData,
                t
              )}
            </Button>
          </Stack>
        )}

        {!selected && <Alert variant={"filled"} severity={"error"} title={"Oops"} />}

        <PlaceBookingDetailDialog
          isDetailModalOpen={isDetailModalOpen}
          setIsDetailModalOpen={setIsDetailModalOpen}
          timezone={bookingInputs.timezone}
          occupiedStatus={occupiedStatus}
        />
      </Card>
    </>
  );
}
