import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { UserOrEmailMapping } from "../../../features/Admin/typings/admin.types";
import { TeamMember } from "../../../features/Booking-Form/typings/team-member";
import { getOptionLabelOfUserOrEmailMapping } from "../../../utils/type/type.utils";
import { BookingScheduleInterface } from "../../../features/Booking-Form/typings/booking.types";
import { useRemoteUpdateBookingGuests } from "../../../hooks/Remote/Booking/useRemoteUpdateBookingGuest";
import { useRemoteFetchUserInfoForAllUsers } from "../../../hooks/Remote/User/UserInfo/useRemoteFetchUserInfoForAllUsers";
import {
  StyledInputDialogHeaderIconButton,
  StyledInputDialogHeaderTitle
} from "./input-dialog-schedule-styled.component";
import {
  inputDialogEditParticipants,
  isOptionEqualToVal,
  renderParticiapantTags
} from "../../BookingForm/form-zone-restriction.functions";
import { useSnackbar } from "notistack";

/**
 * @description Modal that displays current zone access users and lets the user edit them if allowed to.
 * @param props.appointmentData as the data of the appointment including start and end dates and id.
 * @param props.disabled as a boolean which defines disabled state of the confirm button.
 */

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  appointmentDataId: { id: number };
  selectedUserBookedFor: string;
  mode: boolean;
  disabled?: boolean;
  users?: TeamMember[];
  schedule: BookingScheduleInterface;
  refetchAllSchedule: () => void;
}

export default function InputDialogSchedule(props: SimpleDialogProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { onClose, open, appointmentDataId, schedule, mode, refetchAllSchedule } = props;
  const { userInformation } = useSelector((state: RootState) => state.login);
  const theme = useTheme();

  const [users, setUsers] = useState<UserOrEmailMapping[]>([]);
  const [zoneAccess, setZoneAccess] = useState<UserOrEmailMapping[]>([]);

  const {
    mutate: updateBookingGuests,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess
  } = useRemoteUpdateBookingGuests();
  const { data: remoteUsers, isFetching: isRemoteUsersFetching } =
    useRemoteFetchUserInfoForAllUsers();

  useEffect(() => {
    if (remoteUsers) setUsers(remoteUsers);

    // get zone access(guests list)
    if (mode) {
      // when booking type is not conferencezone
      // getZoneBookingUserByZoneBookings is used in the past
    } else {
      if (users) {
        const guests = users.filter(user =>
          schedule.guests.map(g => g.userId).includes(user.userId || "")
        );
        setZoneAccess(guests);
      }
    }
  }, [appointmentDataId.id, open, users, userInformation.sub, schedule, isRemoteUsersFetching]);

  useEffect(() => {
    if (isUpdateSuccess && !isUpdateLoading) refetchAllSchedule();
  }, [isUpdateLoading, isUpdateSuccess]);

  // Function that pushes the id's of new zone access users to an array and sends that to the backend
  const handleSave = () => {
    onClose();

    if (mode) {
      // setZoneBookingUserByZoneBookings is used in the past
      // then => enqueueSnackbar(t("Successfully edited access rights"), { variant: "success" }))
      // catch => enqueueSnackbar(t(`Error while editing access rights`), { variant: "error" })
    } else {
      updateBookingGuests({
        noPlaceZoneBookingId: appointmentDataId.id,
        conferenceZoneBookingGuests: zoneAccess.map(guest => ({
          userId: guest.userId || "",
          isRequired: guest.isRequired || true
        }))
      });
    }
  };

  const dialogTitleForGuests = schedule.isGuest ? t("Show Guests") : t("Edit Guests");
  const dialogTitle = mode ? t("Edit Zone Access") : dialogTitleForGuests;
  const placeHolderText = schedule.isGuest ? "" : t("Choose your colleagues");

  return (
    <>
      <Dialog
        maxWidth="sm"
        data-testid="input-dialog-schedule"
        onClose={onClose}
        fullWidth
        key={props.appointmentDataId?.id}
        aria-labelledby="simple-dialog-title"
        open={open}
        PaperProps={{ sx: { backgroundImage: "unset" } }}
      >
        <StyledInputDialogHeaderTitle id={"input-dialog-schedule-header"}>
          <Typography component={"span"} variant="h5">
            {dialogTitle}
          </Typography>
          <StyledInputDialogHeaderIconButton onClose={onClose} />
        </StyledInputDialogHeaderTitle>

        <DialogContent sx={{ padding: theme.spacing(2) }}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={users}
            disabled={props.disabled}
            data-testid={"input-dialog-conference-edit-guests"}
            // freeSolo // use this later when BE is prepared
            // filterOptions={filterParticipantsOpt} // use this later when BE is prepared
            handleHomeEndKeys
            filterSelectedOptions
            isOptionEqualToValue={isOptionEqualToVal}
            getOptionLabel={getOptionLabelOfUserOrEmailMapping}
            style={{ width: "100%" }}
            onChange={(event, values, reason, details) => {
              switch (reason) {
                case "selectOption":
                case "removeOption": {
                  inputDialogEditParticipants(
                    reason,
                    values,
                    details,
                    setZoneAccess,
                    enqueueSnackbar
                  );
                  return;
                }
                case "clear": {
                  setZoneAccess(values as UserOrEmailMapping[]);
                  return;
                }
              }
            }}
            value={zoneAccess || []}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                placeholder={placeHolderText}
                className="autocompletefield"
              />
            )}
            renderTags={renderParticiapantTags}
          />
        </DialogContent>
        {!schedule.isGuest && (
          <Button
            data-testid={"confirmBTN"}
            disabled={props.disabled}
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            {t("Confirm")}
          </Button>
        )}
      </Dialog>
    </>
  );
}
