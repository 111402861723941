import { Redirect, useRouteMatch } from "react-router";
import EquipmentManagement from "../FloorManager/equipment-administration.component";
import EquipmentManagementReport from "../../components/Reports/equipment-management-report.component";

const Equipment: React.FC = () => {
  const { params } = useRouteMatch<{ dest: string }>();

  switch (params.dest) {
    case "inventory":
      return <EquipmentManagement />;
    case "capacity":
      return <EquipmentManagementReport />;
    default:
      return <Redirect to={"/"} />;
  }
};

export default Equipment;
