import { IconButton, useTheme, SvgIconTypeMap } from "@mui/material";
import { Search, ZoomOutMap } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type IconProps = {
  brandColor?: string;
  toggleZoomHighlight?: boolean;
};

type IconStyleProps = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  margin?: string | number;
  position?: "absolute" | "fixed" | "relative" | "static" | "sticky";
  disabled?: boolean;
};

const JumpIcon: React.FC<IconProps> = ({ toggleZoomHighlight, brandColor }) => {
  return (
    <IconContainer icon={!toggleZoomHighlight ? Search : ZoomOutMap} brandColor={brandColor} />
  );
};

export default JumpIcon;

/**
 * Icon container with absolute alignment.
 * @param param0
 * @returns
 */
export const IconContainer: React.FC<
  {
    brandColor?: string;
    icon?: OverridableComponent<SvgIconTypeMap>;
    onClick?: React.MouseEventHandler;
    onMouseDown?: React.MouseEventHandler;
  } & IconStyleProps
> = ({
  brandColor = "#000000",
  icon: IconComponent = Search,
  disabled = false,
  onClick,
  onMouseDown,
  ...iconStyleProps
}) => {
  const theme = useTheme();

  const iconStyle = {
    color: brandColor
  };
  if (brandColor === "#ffffff") {
    iconStyle.color = "#000000";
  }

  return (
    <IconButton
      sx={{
        position: iconStyleProps.position ?? "absolute",
        margin: iconStyleProps.margin ?? theme.spacing(1),
        padding: theme.spacing(1),
        top: iconStyleProps.top,
        left: iconStyleProps.left,
        right: iconStyleProps.right,
        bottom: iconStyleProps.bottom
      }}
      color="primary"
      disabled={disabled}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-testid="view-action-icon-btn"
      size="large"
    >
      <IconComponent style={iconStyle} sx={{ fontSize: "2rem" }} data-testid="view-action-icon" />
    </IconButton>
  );
};
