import {
  Grid,
  IconButton,
  Typography,
  Avatar,
  Chip,
  Dialog,
  DialogContent,
  Skeleton,
  Box,
  Tooltip
} from "@mui/material";
import { Group, Update } from "@mui/icons-material";
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  getCurrentFloor,
  setEquipmentState,
  setFilters,
  stepToGoNextTo
} from "../../features/Booking-Form/functions/form.functions";
import {
  selectSingleWorkplace,
  selectTeamMemberWorkplace,
  setFloors,
  setInputs
} from "../../features/Booking-Form/slices/booking.slice";
import { FilterDto } from "../../features/Booking-Form/typings/inputs-filter";
import { PricingModel } from "../../features/Login/typings/login.types";
import { MultiselectOption } from "../../features/Reports/typings/reports.types";
import { SelectFloorPlanView } from "../FacilityManager/Components/Views/SelectFloorPlanView/SelectFloorPlanView";
import { IFloorPayload } from "../FacilityManager/Domain/Types/FloorPlan/FloorPayload.type";
import FormTeamSelection from "./FormTeamSelection/form-team-selection.component";
import FormWorkplaceTeamBookingChips from "./form-workplace-team-booking-chips.component";
import {
  checkActivityBased,
  checkCurrentUser,
  fetchFloorPlanState,
  filterCategorySelection,
  filterListByCategory,
  handleFilterSelect,
  initCategoryOptions,
  tempFilterListByCategory,
  updateFilters
} from "./form.functions";
import BookingFilters from "../../features/Booking-Form/partials/booking-filters.component";
import { IPlaceCategory } from "../FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { IZoneCategory } from "../FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import { useBackgroundImage } from "../FacilityManager/Hooks/useBackgroundImage";
import { FormEditTimeDialog } from "./form-edit-time-dialog.component";

type P = {
  nextStep: () => void;
  prevStep: () => void;
  workplaceToState: (id: number) => void;
  picker: boolean;
  setValidDates: React.Dispatch<SetStateAction<boolean>>;
  validDate: boolean;
  teamToState?: (index: number, workplace: number) => void;
  preferredLocations: number[];
};

/**
 * @description Component that displays the location floors and blueprints including the desks and zones.
 * @param props.nextStep as a function which goes to the next step in the booking process.
 * @param props.prevStep as a function which goes to the previous step in the booking process.
 * @param props.workplaceToState as a function which sets the selected workplace to main state.
 * @param props.teamToState as a function which sets the selected team including the selected workplaces to the main state usersBookedFor array.
 * @param inputs as a BI which is the state of the booking form component.
 * @param props.setInputs as a function which sets the main inputs of the booking form component.
 * @version 0.1.0
 */
export const FormWorkplace: React.FC<P> = props => {
  const { t } = useTranslation();
  const {
    inputs,
    floors: { inventory, currentFloorIndex, isLoading, error }
  } = useSelector((state: RootState) => state.booking);
  const { userInformation } = useSelector((state: RootState) => state.login);
  const preferredLocations = useSelector(
    (state: RootState) => state.login.settings.preferredLocations
  );

  const [floorPlan, setFloorPlan] = useState<IFloorPayload>();

  const [currentUser, setCurrentUser] = useState<number>(
    checkCurrentUser(inputs.usersBookedFor) // index of user we are currently selecting a table for, but need to check the value shouldn't be -1
  );

  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);

  const [anchorElDevice, setAnchorElDevice] = useState<HTMLButtonElement | null>(null);
  const [anchorElDeviceCategories, setAnchorElDeviceCategories] =
    useState<HTMLButtonElement | null>(null);

  const [, setDeviceOptions] = useState<MultiselectOption[]>([]); // needs to refactor when use device filter later
  const [, setDeviceCategoryOptions] = useState<MultiselectOption[]>([]); // needs to refactor when use device category filter later
  const [placeCategoryOptions, setPlaceCategoryOptions] = useState<IPlaceCategory[]>([]);
  const [zoneCategoryOptions, setZoneCategoryOptions] = useState<IZoneCategory[]>([]);

  // filter place/zone by category selection
  const [filteredSelectables, setFilteredSelectables] = useState<
    (number | undefined)[] | undefined
  >(undefined);

  const dispatch = useDispatch();
  const location: any = useLocation();
  const { step: current } = useParams<{ step: string }>();
  const [filtersChanged, setFiltersChanged] = useState(false);

  const loaded = useRef<boolean>(false);
  const currentFloor = getCurrentFloor(inventory, currentFloorIndex);

  // download and set the background image here
  const { background, unsetBackgroundImage } = useBackgroundImage({
    backgroundImageUrl: floorPlan?.outlineUrl,
    viewport: floorPlan?.viewport
  });

  useEffect(() => {
    setCurrentUser(checkCurrentUser(inputs.usersBookedFor));
  }, [inputs.usersBookedFor]);

  useEffect(() => {
    setFiltersChanged(true);
  }, [inputs.equipment, inputs.floorInventoryId]);

  useEffect(() => {
    // This step is skipped when Mobile Working is chosen
    stepToGoNextTo(
      inputs.isMobileWorking,
      inputs.automatedSeating,
      location.state,
      current,
      props.nextStep,
      props.prevStep
    );
  }, [
    inputs.isMobileWorking,
    inputs.automatedSeating,
    location.state,
    current,
    props.nextStep,
    props.prevStep
  ]);

  useEffect(() => {
    checkActivityBased(inputs.activityBasedBooking, props.nextStep);
  }, [inputs.activityBasedBooking, props.nextStep]);

  useEffect(() => {
    updateFilters(inputs, currentUser, loaded, dispatch, setFiltersChanged, filtersChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputs.bookingEnd,
    inputs.bookingFrom,
    inputs.bookingStart,
    inputs.bookingTo,
    inputs.bookingType,
    inputs.selectedLocation,
    inputs.usersBookedFor,
    inputs.equipment,
    inputs.floorInventoryId,
    inputs.weekdays,
    inputs.mode,
    inputs.frequence,
    inputs.interval,
    inputs.specificDays,
    inputs.bookingMonthDay,
    inputs.bookingYearDay,
    filtersChanged,
    currentUser
  ]);

  useEffect(() => {
    fetchFloorPlanState(inputs, setFloorPlan, currentFloor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputs.automatedSeating,
    inputs.bookingEnd,
    inputs.bookingFrom,
    inputs.bookingStart,
    inputs.bookingTo,
    inputs.activityBasedBooking,
    inputs.isMobileWorking,
    currentFloor?.id
  ]);

  // Fetching filters
  useEffect(() => {
    if (preferredLocations) {
      const filter: FilterDto = {
        type: "location",
        ids: preferredLocations.map(l => Number(l))
      };
      setFilters(filter, setDeviceOptions, setDeviceCategoryOptions);
    }
  }, [preferredLocations]);

  useEffect(() => {
    setEquipmentState(inputs.mode, inputs.usersBookedFor, currentUser, dispatch);
  }, [inputs.mode, inputs.usersBookedFor, currentUser, dispatch]);

  // use place/zone categories from the floor plan
  useEffect(() => {
    initCategoryOptions(
      inputs.mode,
      floorPlan,
      currentFloor,
      setPlaceCategoryOptions,
      setZoneCategoryOptions
    );
  }, [floorPlan]);

  // apply category filter on the floor plan
  useEffect(() => {
    if (floorPlan) filterCategorySelection(inputs, floorPlan, setFilteredSelectables);
  }, [inputs.equipment, floorPlan, currentFloorIndex]);

  /**
   * @deprecated it used to be that if current floor has no empty workplaces select the next floor.
   * selectWorkplaceFloor(inventory, dispatch, currentFloorIndex);
   */

  if (error)
    return <Typography variant={"h5"}>{t("There was an extreme error getting floors")}</Typography>;
  return (
    <>
      <Grid data-testid="form-booking-parent" container>
        <Grid
          data-testid="form-booking-select-parent"
          item
          sx={{ justifyContent: "flex-start", alignItems: "flex-end", display: "inline-block" }}
        >
          <Dialog
            data-testid="dialog-date-open"
            onClose={() => setIsDateOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={isDateOpen}
            PaperProps={{ sx: { backgroundImage: "none", maxWidth: "350px" } }}
          >
            <DialogContent sx={{ p: 2 }}>
              <FormEditTimeDialog inputs={inputs} setIsDateOpen={setIsDateOpen} />
            </DialogContent>
          </Dialog>
          <Dialog
            data-testid="dialog-team-open"
            onClose={() => setIsTeamOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={isTeamOpen}
          >
            <DialogContent data-testid="dialog-team-content">
              <FormTeamSelection title={t("Edit your team selection")} />
            </DialogContent>
          </Dialog>

          <Grid item>
            <BookingFilters
              bookingMode={inputs.mode}
              filter={{
                type: "location",
                ids: props.preferredLocations
              }}
              placeCategories={placeCategoryOptions}
              zoneCategories={zoneCategoryOptions}
              anchorElDevice={anchorElDevice}
              anchorElDeviceCategories={anchorElDeviceCategories}
              setAnchorElDevice={setAnchorElDevice}
              setAnchorElDeviceCategories={setAnchorElDeviceCategories}
              onSelectedChange={newSelected => {
                handleFilterSelect(
                  newSelected,
                  "workplaceCategoryIds",
                  inputs,
                  currentUser,
                  dispatch
                );
              }}
            />
          </Grid>

          <Grid item>
            {userInformation.pricingModels.includes(PricingModel.ENTERPRISE) && (
              <IconButton
                data-testid="change-time"
                onClick={() => setIsDateOpen(true)}
                aria-label="change time"
                color={"primary"}
                size="large"
              >
                <Update />
              </IconButton>
            )}
            {inputs.mode === "team" && (
              <Tooltip title={t("Edit your team selection")}>
                <IconButton
                  data-testid="change-team-members"
                  onClick={() => setIsTeamOpen(true)}
                  aria-label="change members"
                  color={"primary"}
                  size="large"
                >
                  <Group />
                </IconButton>
              </Tooltip>
            )}
          </Grid>

          <Grid item>
            {inventory.map((floor, index) => {
              const {
                id,
                name,
                numberOfBookableObjects,
                availableInventoryIds,
                occupiedInventoryIds,
                disabledInventoryIds,
                forbiddenInventoryIds
              } = floor;

              const noBookableInventoryIds = [
                ...availableInventoryIds,
                ...occupiedInventoryIds,
                ...disabledInventoryIds,
                ...forbiddenInventoryIds
              ].length;

              return (
                <Chip
                  data-testid="floor-item"
                  avatar={<Avatar>{numberOfBookableObjects}</Avatar>}
                  label={name}
                  key={id}
                  clickable
                  style={{ margin: "0 10px 10px 0" }}
                  disabled={!noBookableInventoryIds}
                  color={currentFloorIndex === index ? "primary" : "default"}
                  onClick={e => {
                    e.preventDefault();
                    dispatch(setInputs({ floorInventoryId: id }));
                    dispatch(setFloors({ currentFloorIndex: index }));
                    unsetBackgroundImage();
                  }}
                />
              );
            })}
          </Grid>
        </Grid>

        <FormWorkplaceTeamBookingChips
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          inputs={inputs}
          setAnchorElDevice={setAnchorElDevice}
          setAnchorElDeviceCategories={setAnchorElDeviceCategories}
        />

        {(isLoading || !background) && (
          <Skeleton variant="rectangular" sx={{ width: "100%", height: "calc(100vh - 250px)" }} />
        )}
        {!isLoading && inputs.usersBookedFor?.length && currentFloor && floorPlan && background && (
          <Box
            data-testid="select-floorplanview-parent"
            id="select-floor-parent"
            sx={{ width: "100%", height: "calc(85vh - 250px)" }}
          >
            <SelectFloorPlanView
              background={background}
              name={"booking"}
              floorPlan={floorPlan}
              seatStatus={{
                availableList: filterListByCategory(
                  currentFloor.availableInventoryIds,
                  filteredSelectables
                ),
                occupiedList: currentFloor.occupiedInventoryIds,
                disableList: currentFloor.disabledInventoryIds,
                restrictedList: currentFloor.forbiddenInventoryIds,
                tempList: tempFilterListByCategory(
                  currentFloor.availableInventoryIds,
                  filteredSelectables
                )
              }}
              bookingInputs={{
                mode: inputs.mode,
                bookingFrom: inputs.bookingFrom,
                bookingTo: inputs.bookingTo,
                bookingStart: inputs.bookingStart,
                bookingEnd: inputs.bookingEnd,
                bookingType: inputs.bookingType,
                usersBookedFor: inputs.usersBookedFor,
                timezone: inputs.timezone,
                weekdays: inputs.weekdays,
                zoneAccess: inputs.zoneAccess
              }}
              userIndex={currentUser}
              setUserIndex={(i: number) => setCurrentUser(i)}
              warningData={{
                requiredUserWarningData: currentFloor.requiredUserWarningData,
                optionalUserWarningData: currentFloor.optionalUserWarningData
              }}
              onSingleSelect={(placeInventoryId: number) => {
                dispatch(
                  selectSingleWorkplace({
                    firstName: userInformation.firstName,
                    surname: userInformation.surname,
                    userId: userInformation.sub,
                    bookingInventoryId: placeInventoryId,
                    email: userInformation.email,
                    isExternal: false
                  })
                );
                props.nextStep();
              }}
              onTeamSelect={(index: number, placeInventoryId: number) => {
                dispatch(selectTeamMemberWorkplace({ index, placeInventoryId: placeInventoryId }));
              }}
              onNextStep={props.nextStep}
              setIsDateOpen={setIsDateOpen}
            />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default FormWorkplace;
