import React, { useMemo } from "react";
import { FormControl, FormLabel, Grid } from "@mui/material";
import { Datepicker } from "@mobiscroll/react";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { BookingType, Frequence } from "../../../features/Booking-Form/typings/booking-inputs";
import { findMaxAdvancedBookingSettingPerType } from "../booking.utils";
import generateDisabledWeekdays from "./generate-disabled-weekdays.function";
import useTimeFormat from "../../../hooks/useTimeFormat/useTimeFormat";

const DateTimePicker: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bookingStart, bookingEnd, bookingFrom, bookingTo, frequence, mode } = useSelector(
    (state: RootState) => state.booking.inputs
  );
  const { meta: metaData } = useSelector((state: RootState) => state.login.userInformation.company);
  const { timeFormat, dateFormat } = useTimeFormat();

  // checks if sa and so are allowed and generates the datepicker invalid string
  const disabledOnWeekDays = useMemo(
    () =>
      generateDisabledWeekdays({
        bookingsOnSaturdaysAllowed: metaData.bookingsOnSaturdaysAllowed,
        bookingsOnSundaysAllowed: metaData.bookingsOnSundaysAllowed
      }),
    [metaData]
  );

  const maxDate = DateTime.now()
    .plus({
      weeks:
        mode !== BookingType.PLACEZONE
          ? findMaxAdvancedBookingSettingPerType(mode, metaData.advancedBookingSettings)
          : metaData.maxAdvancedZoneBookingLength
    })
    .toFormat("yyyy-MM-dd");

  function onChangeStartDate(picker: any) {
    if (frequence === Frequence.SINGLE) {
      const bookingFromPickerValue = picker.value;
      const bookingToPicker = bookingFromPickerValue;
      const specificDays = [bookingFromPickerValue];

      dispatch(
        setInputs({
          bookingFrom: bookingFromPickerValue,
          bookingTo: bookingToPicker,
          specificDays
        })
      );
      return;
    }

    const bookingFromPicker = picker.value;
    dispatch(
      setInputs({
        bookingFrom: bookingFromPicker
      })
    );
  }

  return (
    <Grid container>
      {frequence !== Frequence.SPECIFIC && (
        <>
          <Grid item xs={12} sm={3}>
            <FormControl data-testid="datepicker-control">
              <FormLabel component="legend" style={{ marginLeft: "1em" }}>
                {t("Start Date")}
              </FormLabel>
              {/** Determine start date here */}
              <Datepicker
                aria-label={"datepicker"}
                data-testid={"date-single"}
                invalid={disabledOnWeekDays}
                value={bookingFrom}
                onChange={onChangeStartDate}
                min={DateTime.now().toFormat("yyyy-MM-dd")}
                max={maxDate}
                returnFormat="iso8601"
                dateFormat={dateFormat.toUpperCase()}
              />
            </FormControl>
          </Grid>
          {frequence !== Frequence.SINGLE && (
            <Grid item xs={12} sm={3}>
              <FormControl data-testid="datepicker-control1">
                <FormLabel component="legend" style={{ marginLeft: "1em" }}>
                  {t("End Date")}
                </FormLabel>
                {/** Determine end date here */}
                <Datepicker
                  aria-label={"datepicker1"}
                  data-testid={"date-single1"}
                  invalid={disabledOnWeekDays}
                  value={bookingTo}
                  onChange={picker => {
                    const bookingToPicker = picker.value || DateTime.now().toFormat("yyyy-MM-dd");
                    dispatch(
                      setInputs({
                        bookingTo: bookingToPicker.toString()
                      })
                    );
                  }}
                  min={DateTime.fromISO(bookingFrom ?? DateTime.now().toISO()).toFormat(
                    "yyyy-MM-dd"
                  )}
                  max={maxDate}
                  returnFormat="iso8601"
                  dateFormat={dateFormat.toUpperCase()}
                />
              </FormControl>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} sm={frequence === Frequence.SPECIFIC ? 12 : 6}>
        <FormControl fullWidth data-testid="timepicker-control">
          <FormLabel component="legend" style={{ marginLeft: "1em" }}>
            {t("Time")}
          </FormLabel>
          <Datepicker
            select="range"
            data-testid={"timePicker"}
            controls={["time"]}
            timeFormat={timeFormat}
            onChange={picker => {
              if (picker.value && Array.isArray(picker.value)) {
                dispatch(
                  setInputs({
                    bookingStart: picker.value[0]
                      ? DateTime.fromJSDate(picker.value[0] as Date).toFormat("HH:mm")
                      : "08:00",
                    bookingEnd: picker.value[1]
                      ? DateTime.fromJSDate(picker.value[1] as Date).toFormat("HH:mm")
                      : "17:00"
                  })
                );
              }
            }}
            value={[bookingStart, bookingEnd]}
            stepMinute={15}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default DateTimePicker;
