import { Box } from "@mui/material";
import { t } from "i18next";
import { MRT_Row } from "material-react-table";
import { EquipmentRow } from "./typings/equipment-row.types";
import ShowAssignedFloor from "../show-assigned-floor.component";

/** Button to display a workplace in the Facility Manager */
export const renderAssignedButton = (
  row: MRT_Row<EquipmentRow>,
  setSelectedInventoryId: (inventoryId: number) => void,
  setFloorPlan: (floorPlan: any) => void,
  setOpen: (open: boolean) => void,
  setDialogTitle: (title: string) => void
) => {
  // retrieve device data for a given device schedule
  return (
    <Box>
      {/* check if status is placed or booked, then it needs to display the relevant status and 'show details' */}
      {/* <Typography>{t(`${row.original.status}`)}</Typography> */}
      <ShowAssignedFloor
        params={row.original}
        setSelectedInventoryId={setSelectedInventoryId}
        setFloorPlan={setFloorPlan}
        setOpen={setOpen}
        setDialogTitle={setDialogTitle}
        noPlaceButtonText={t("Not placed")}
        showButtonText={!!row.original.placeInventoryId ? t("Show Workplace") : t("Show Zone")}
      />
    </Box>
  );
};
