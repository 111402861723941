import React, { forwardRef } from "react";
import { Box, Card, CardContent, CardMedia, Fade, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setFloors, setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";
import { LocationInputs } from "../../../features/Booking-Form/typings/inputs-location";
import { ReactComponent as StopIcon } from "../../../features/FloorManager/assets/no-stopping.svg";
import { InfoIconWithTooltip } from "../../Title/InfoIcon";
import MWImage from "../../../features/Booking-Form/assets/mobile-working.jpeg";

type P = {
  location: LocationAvailability;
  locationToState({
    selectedLocation,
    locationName,
    timezone,
    isMobileWorking,
    automatedSeating
  }: LocationInputs): void;
};

export const LocationCardMT: React.FC<P> = forwardRef<HTMLDivElement, P>(
  ({ location, locationToState }, ref) => {
    const usersBookedFor = useSelector((state: RootState) => state.booking.inputs.usersBookedFor);
    const inputs = useSelector((state: RootState) => state.booking.inputs);

    const {
      numberOfBookableObjects,
      name,
      address1,
      address2,
      postalCode,
      city,
      picturePath,
      isMobileWorking,
      id,
      allowForBooking,
      timezone,
      automatedSeatingObjects
    } = location;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const spotsAvailable =
      numberOfBookableObjects > 0 &&
      usersBookedFor &&
      usersBookedFor.length <= numberOfBookableObjects &&
      !isMobileWorking;

    const isMobileWorkingReady = isMobileWorking && allowForBooking && numberOfBookableObjects > 0;

    const isDisabled =
      !allowForBooking || isMobileWorking ? t("_locationDisabled") : t("_spacesOccupied");

    const locationIcons = isMobileWorkingReady ? (
      <></>
    ) : (
      <>
        <StopIcon style={{ marginRight: "8px" }} />
        <InfoIconWithTooltip tooltipText={isDisabled} />
      </>
    );

    function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      event.preventDefault();
      if (
        (numberOfBookableObjects > 0 &&
          allowForBooking &&
          usersBookedFor &&
          usersBookedFor?.length <= numberOfBookableObjects) ||
        (isMobileWorking && allowForBooking && numberOfBookableObjects > 0)
      ) {
        locationToState({
          selectedLocation: id,
          locationName: name,
          isMobileWorking: isMobileWorking,
          timezone: timezone,
          automatedSeating: inputs.automatedSeating,
          automatedSeatingObjects: automatedSeatingObjects
        });
        dispatch(
          setInputs({
            selectedLocation: id,
            locationName: name,
            isMobileWorking: isMobileWorking,
            timezone: timezone,
            automatedSeating: inputs.automatedSeating,
            automatedSeatingObjects: automatedSeatingObjects
          })
        );
        dispatch(setFloors({ isLoading: true, currentFloorIndex: 0 }));
      }
    }

    return (
      <Fade in={!!name} ref={ref}>
        <Card
          data-testid="location"
          sx={{
            display: "flex",
            width: "95vw",
            minHeight: 193,
            maxWidth: 400,
            cursor: "pointer",
            margin: "10px 20px 20px 0"
          }}
          onClick={handleClick}
        >
          <CardMedia
            sx={{
              minWidth: 160,
              "@media (max-device-width: 400px)": {
                minWidth: 130
              }
            }}
            image={picturePath ?? MWImage}
            title={name}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 345
            }}
          >
            <CardContent
              sx={{
                flex: "0 0 auto"
              }}
            >
              <Typography component="h5" variant="h5">
                {name === "Mobile Working" ? t("Mobile Working") : name}
              </Typography>
              {name !== "Mobile Working" && (
                <div style={{ marginTop: 5 }}>
                  {city}, {postalCode}
                  <Typography variant="subtitle1" color="textSecondary">
                    {address1} {address2}
                  </Typography>
                </div>
              )}
            </CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 2,
                paddingBottom: 1
              }}
            >
              {spotsAvailable ? (
                <Typography sx={{ color: "#43a047" }} variant="h6" color="textSecondary">
                  {t("_available")} {numberOfBookableObjects}
                </Typography>
              ) : (
                locationIcons
              )}
            </Box>
          </Box>
        </Card>
      </Fade>
    );
  }
);
