import { Box, Button } from "@mui/material";
import { AnnotatedTextField } from "./AnnotatedTextField";
import { IViewport } from "../../FacilityManager/Domain/Types/FloorPlan/Viewport.type";
import { useTranslation } from "react-i18next";
import { IZoneUserNumber } from "../../FacilityManager/Domain/Types/FloorPlan/ZoneSchedule";

export type MiniTextInputField = {
  id: string;
  placeholder: string;
  value: string | number | null;
  type: "text" | "number";
};
interface Props {
  fields: MiniTextInputField[];
  onInput: (id: string, value: string | number) => void;
  // bounding box for place property
  newBoundingBox?: IViewport;
  setNewBoundingBox?: (v: IViewport) => void;
  handleOk?: (newValue: IViewport) => void;
  // number of users for conference zone
  zoneUserNumber?: IZoneUserNumber;
  setZoneUserNumber?: (n: IZoneUserNumber) => void;
  readOnly?: boolean;
}

/**
 * a slim text input for small sidebars
 * it can consist of multiple fields that are glued together
 * @param fields all fields that should be created
 * @param onInput if any of the fields are edited, this emits the new value and id
 * @constructor
 */
export function MiniTextInput({
  fields,
  newBoundingBox = { width: 160, height: 80 },
  onInput,
  handleOk,
  setNewBoundingBox,
  zoneUserNumber,
  setZoneUserNumber,
  readOnly = false
}: Props) {
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: "row"
      }}
    >
      {/** Every field gets a text field with placeholder */}
      {fields.map((field, index) => (
        <AnnotatedTextField
          key={field.id}
          type={field.type}
          value={field.value}
          index={index}
          fieldLength={fields.length}
          placeholder={field.placeholder}
          onChange={newValue => {
            onInput(field.id, newValue);
            setNewBoundingBox?.({
              width: field.id === "width" ? Number(newValue) : newBoundingBox?.width,
              height: field.id === "height" ? Number(newValue) : newBoundingBox?.height
            });
            setZoneUserNumber?.({
              minUsers: field.id === "min" ? Number(newValue) : zoneUserNumber?.minUsers ?? 0,
              maxUsers: field.id === "max" ? Number(newValue) : zoneUserNumber?.maxUsers ?? null
            });
          }}
          readOnly={readOnly}
        />
      ))}
      {handleOk && (
        <Button
          data-testid="textfield-ok-btn"
          sx={{ fontSize: 10, lineHeight: 0, minWidth: "40px" }}
          onClick={() => handleOk(newBoundingBox)}
        >
          {t("Ok")}
        </Button>
      )}
    </Box>
  );
}
