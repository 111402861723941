import React, { useEffect, useState } from "react";
import { Theme, ThemeOptions as ThemeOptionsMui5, useTheme } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import Axios, { InternalAxiosRequestConfig } from "axios";
import Keycloak from "keycloak-js";
import { useTranslation } from "react-i18next";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { handleTokenUpdate } from "../../app/sockets/socket";
import {
  setRequestInterceptor,
  setSettings,
  setUserInformation,
  setUserRoles
} from "../../features/Login/slices/login.slice";
import { UserRole } from "../../features/Login/typings/auth.types";
import { MetaData, UserRolesFlags } from "../../features/Login/typings/login.types";
import { AppSettings } from "../../features/Settings/typings/user-settings.types";
import { useRemoteFetchUserProfile } from "../../hooks/Remote/User/useRemoteFetchUserProfile";
import { useRemoteFetchUserSettings } from "../../hooks/Remote/User/useRemoteFetchUserSettings";
import { WelcomeFade } from "../Animations/welcomeFadeAnimation.component";
import { OpenAPI } from "../../features/Api-legacy/type/openApi";

interface PrivateRouteParams extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  setTheme: React.Dispatch<React.SetStateAction<ThemeOptionsMui5>>;
  themeColor: string;
  setBrandColor: React.Dispatch<React.SetStateAction<string>>;
}

export function PrivateRoute({
  setTheme,
  setBrandColor,
  component: Component,
  ...rest
}: PrivateRouteParams) {
  const [isLoading, setIsLoading] = useState(true);
  const {
    settings: { colorMode }
  } = useSelector((state: RootState) => state.login);
  const [metaDataState, setMetaDataState] = useState<MetaData | undefined>(undefined);
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const { data: userProfile, error: userProfileError } = useRemoteFetchUserProfile();
  const { data: userSettings, error: userSettingsError } = useRemoteFetchUserSettings();

  useEffect(() => {
    if (userProfileError || userSettingsError) keycloak.logout();
  }, [userProfileError, userSettingsError]);

  /** store user profile information */
  useEffect(() => {
    if (!userProfile) return;
    if (!keycloak.authenticated || !keycloak.token) return;

    setMetaDataState(userProfile.company.meta);
    setTheme(makeTheme(theme, colorMode, userProfile.company.meta.color ?? undefined));
    dispatch(setUserInformation(userProfile));

    setTimeout(() => setIsLoading(false), 300);
  }, [userProfile]);

  /** set language and store settings when userSettings are fetched */
  useEffect(() => {
    if (!userSettings) return;
    // assign english language if no other language is set
    if (userSettings.language === "") userSettings.language = "en";
    i18n.changeLanguage(userSettings.language).then();
    dispatch(setSettings({ settings: makeData(userSettings) }));
  }, [userSettings]);

  /** handle login with keycloak */
  useEffect(() => {
    setToken(keycloak, dispatch);
    keycloak.onAuthRefreshSuccess = () => setToken(keycloak, dispatch);
    keycloak.onAuthRefreshError = keycloak.logout;
  }, [keycloak]);

  /** update theme on demand */
  useEffect(() => {
    if (metaDataState?.color) {
      setBrandColor(metaDataState?.color);
      setTheme(makeTheme(theme, colorMode, metaDataState.color));
    }
  }, [colorMode, metaDataState]);

  // Update socket connection
  useEffect(() => {
    if (keycloak.token) {
      handleTokenUpdate(keycloak.token);
    }
  }, [keycloak.token]);

  return (
    <Route
      {...rest}
      render={props => {
        if (keycloak?.authenticated && isLoading) {
          return <WelcomeFade text={t("Welcome to WePlace")} />;
        } else if (keycloak?.authenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
}

function getUserRoles(keycloak: Keycloak): UserRolesFlags {
  return {
    isTenantAdmin: keycloak.hasRealmRole(UserRole.isTenantAdmin),
    isCompanyAdmin: keycloak.hasRealmRole(UserRole.isCompanyAdmin),
    isLocationAdmin: keycloak.hasRealmRole(UserRole.isLocationAdmin),
    isHrAdmin: keycloak.hasRealmRole(UserRole.isHrAdmin),
    isFinanceAdmin: keycloak.hasRealmRole(UserRole.isFinanceAdmin),
    isCapacityResponsible: keycloak.hasRealmRole(UserRole.isCapacityResponsible),
    isDeviceResponsible: keycloak.hasRealmRole(UserRole.isEquipmentResponsible),
    isCostCenterResponsible: keycloak.hasRealmRole(UserRole.isCostCenterResponsible),
    isEmployeeResponsible: keycloak.hasRealmRole(UserRole.isEmployeeResponsible),
    isProjectResponsible: keycloak.hasRealmRole(UserRole.isProjectResponsible),
    isWorkOrderResponsible: keycloak.hasRealmRole(UserRole.isWorkOrderResponsible),
    isZoneBookingCapable: keycloak.hasRealmRole(UserRole.isZoneBookingCapable)
  };
}

/**
 * function to attach authorization header
 * used to attach : cfg.headers = { ...cfg.headers, Authorization: `Bearer ${keycloak.token}` };
 */
export const getAttachAuthorizationHeaderFunction = (keycloak: Keycloak) => {
  return async (cfg: InternalAxiosRequestConfig) => {
    cfg.headers.Authorization = `Bearer ${keycloak.token}`;

    return cfg;
  };
};

const setToken = (keycloak: Keycloak, dispatch: ReturnType<typeof useDispatch>) => {
  if (keycloak.authenticated && keycloak.token) {
    OpenAPI.TOKEN = keycloak.token;

    const interceptorId = Axios.interceptors.request.use(
      getAttachAuthorizationHeaderFunction(keycloak)
    );

    dispatch(setRequestInterceptor(interceptorId));
    dispatch(setUserRoles(getUserRoles(keycloak)));
  }
};

export function makeTheme(
  theme: Theme,
  colorMode: string,
  brandColor = "#006651"
): ThemeOptionsMui5 {
  const commonPalette = {
    primary: { main: brandColor },
    secondary: {
      main: "#e0e0e0" // instead of brandColor
    }
  };
  const commonTypography = { typography: { fontFamily: "Montserrat, Arial" } };

  return colorMode === "dark"
    ? {
        palette: {
          mode: "dark",
          ...commonPalette,
          background: { paper: "#1e1f29", default: "#131421" },
          text: {
            primary: "#ffffff",
            secondary: "#ececec",
            disabled: "#c4c4c4"
          }
        },
        ...commonTypography,
        components: {
          MuiDialog: { styleOverrides: { paper: { backgroundImage: "unset" } } },
          MuiTextField: { defaultProps: { variant: "standard" } },
          MuiButton: { defaultProps: { variant: "contained" } }
        }
      }
    : {
        palette: {
          mode: "light",
          ...commonPalette,
          background: { paper: "#eaeaea", default: "#f3f3f3" },
          text: {
            primary: "#000000",
            secondary: "#151515",
            disabled: "#8c8c8c"
          }
        },
        ...commonTypography,
        components: {
          MuiTextField: { defaultProps: { variant: "standard" } },
          MuiButton: { defaultProps: { variant: "contained" } },
          MuiDialog: { styleOverrides: { paper: { backgroundImage: "unset" } } }
        }
      };
}

export function makeData(data: any): AppSettings {
  return {
    useDeviceTimezone: data.useDeviceTimezone,
    colorMode: data.colorMode,
    timezone: data.timezone,
    preferredPlaceCategories: data.preferredPlaceCategories,
    preferredEquipmentCategories: data.preferredEquipmentCategories,
    preferredLocations: data.preferredLocations,
    standardStartTime: data.defaultStartTime,
    standardEndTime: data.defaultEndTime,
    // Automation is not implemented in backend
    isZoneAutomation: false,
    useAutomatedBooking: false,
    useAutomatedZoneBooking: false,
    advancedBookingLength: 0,
    numberOfActiveZones: 0,
    language: data.language,
    weekdays: [
      { location: "OV", active: false },
      { location: "OV", active: false },
      { location: "OV", active: false },
      { location: "OV", active: false },
      { location: "OV", active: false },
      { location: "OV", active: false }
    ],
    lastLogin: null,
    notificationSettings: data.notificationSettings,
    enabledBookingNotifications: data.enabledBookingNotifications,
    enabledSyncs: data.enabledSyncs
  };
}
