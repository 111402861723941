import {
  Autocomplete,
  AutocompleteChangeReason,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

type Props = {
  entries: any[];
  subjectEntityName: string;
  defaultValue: string;
  availableEntries: any[];
  currentEntries: any[];
  onChange: (event: SelectChangeEvent) => void;
  onDeviceChange?: (value: string[], reasons: AutocompleteChangeReason) => void;
};

export const OptionSelect = ({
  entries,
  subjectEntityName,
  defaultValue,
  availableEntries,
  currentEntries,
  onChange,
  onDeviceChange
}: Props) => {
  const { t } = useTranslation();
  const checkSubjectEntry = subjectEntityName !== "Devices";

  return (
    <>
      <FormControl sx={{ flex: 1 }}>
        {checkSubjectEntry && (
          <>
            <InputLabel id="option-select-inputLabel">{t("SelectSideToolbarLabel")}</InputLabel>
            <Select
              labelId="option-select-label"
              data-testid={"option-select"}
              id="option-select"
              label={subjectEntityName}
              defaultValue={defaultValue}
              onChange={newValue => onChange(newValue)}
            >
              {entries.length !== 0 ? (
                entries.map(entry => (
                  <MenuItem key={entry.id} value={entry.id}>
                    {entry.name}
                    {` (${entry.id})`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No {subjectEntityName}</MenuItem>
              )}
            </Select>
          </>
        )}
        {!checkSubjectEntry && availableEntries && currentEntries && (
          <Autocomplete
            options={availableEntries}
            filterSelectedOptions
            value={currentEntries}
            onChange={(e, newDevices, reasons) => onDeviceChange?.(newDevices, reasons)}
            isOptionEqualToValue={(option, value) => option.assetRefId === value.assetRefId}
            data-testid="place-device-autocomplete"
            disabled={!availableEntries}
            multiple
            id="place-device-assignment"
            getOptionLabel={option => generateOptionLabel(option, t)}
            renderInput={params => (
              <TextField {...params} label={t("Devices")} variant="standard" />
            )}
          />
        )}
      </FormControl>
    </>
  );
};

export const generateOptionLabel = (device: any, t: TFunction): string => {
  return `${device.name} (${device.assetRefId ? device.assetRefId : t("Null")})`;
};
