import { RemoteApiError } from "../useRemote/types";
import { useRemoteMutation } from "../useRemote/useRemoteMutation";
import { TableArrangementDto } from "../../../components/FacilityManager/Domain/Types/FloorPlan/TableArrangement.type";
import { useTranslation } from "react-i18next";

export function useRemoteFloorPlanService() {
  const { t } = useTranslation();

  return useRemoteMutation<void, RemoteApiError, TableArrangementDto>({
    url: `/table-arrangements`,
    key: ["conferencezone", "update-table-arrangement"],
    method: "put",
    snackbarTextOnFail: t("UpdateTableArrangementFail")
  });
}
