import { MutableRefObject, useEffect, useState } from "react";
import { Alert, Box, Button, useTheme } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../../app/helpers";
import { RootState } from "../../../../app/rootReducer";
import DefaultSpinner from "../../../../components/LoadingSpinner/default-spinner.component";
import { Timeframe } from "../../../../features/FloorManager/typings/shared/timeframe";
import { finishInit } from "../../../../functions/FacilityManagerInitializer";
import { useRemoteFetchFloorPlan } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlan";
import currentPlanUniqueTimeframesSelector from "../../selectors/currentPlanUniqueTimeframesSelector";
import selectedEntitiesSelector from "../../selectors/selectedEntitiesSelector";
import InitializingLoadingScreenModal from "../initializing-loadingscreen.component";
import TimelinesTimeframe from "../timeline-timeframes.partial";
import { NewTimeframeCreator } from "./NewTimeframeCreator";
import { useRemoteFetchFloorPlanService } from "../../../../hooks/Remote/FloorPlan/useRemoteFetchFloorPlanService";

type Props = {
  floorTimeframes: Timeframe[];
  refetchFloorTimeframes: () => void;
};

const TimeframesMenu: React.FC<Props> = ({ floorTimeframes, refetchFloorTimeframes }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();
  const selected = useSelector(selectedEntitiesSelector);
  const uniqueTimeframes = useSelector(currentPlanUniqueTimeframesSelector);
  const { isInitializing: isInitializingPlan } = useSelector(
    (state: RootState) => state.floorManager.floors
  );

  const [newTimeframe, setNewTimeframe] = useState(false);
  const [startDate, setStartDate] = useState<DateTime | null>(null);

  const [selectedTimeframe, setSelectedTimeframe] = useState<Timeframe>({ start: "", end: "" });
  const [newTimeframeData, setNewTimeframeData] = useState<Timeframe>({ start: "", end: "" });

  const [isCreateFirstTimeframe, setIsCreateFirstTimeframe] = useState(false);
  const [isEditTimeframe, setIsEditTimeframe] = useState(false);
  const [isContinueTimeframe, setIsContinueTimeframe] = useState(false);

  const [isEditService, setIsEditService] = useState(false);

  const { data: floorPlan, isFetching: floorPlanIsFetching } = useRemoteFetchFloorPlan(
    selected.floor?.id || 0,
    selectedTimeframe.start,
    selectedTimeframe.end
  );
  const { data: floorPlanForService, isFetching: floorPlanForServiceIsFetching } =
    useRemoteFetchFloorPlanService(
      selected.floor?.id || 0,
      selectedTimeframe.start,
      null,
      isEditService
    );

  const clickEdit = async (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsEditTimeframe(true);
  };

  const editServices = (timeframe: Timeframe) => {
    setSelectedTimeframe(timeframe);
    setIsEditService(true);
  };

  /**
   * @argument selectedTimeframe_for_floorplan
   * when timeframe is existed and create new one,
   * it used to select the latest one from the existing by setSelectedTimeframe(floorTimeframes.slice(-1)[0])
   * but now, BE returns the latest
   */
  const clickNextCreate = (startDay: string) => {
    setSelectedTimeframe({ start: startDay, end: null });
    setNewTimeframeData({ start: startDay, end: null });
    if (floorTimeframes.length) setIsContinueTimeframe(true);
    else setIsCreateFirstTimeframe(true);
  };

  useEffect(() => {
    // edit the floor plan only for services
    if (isEditService && !floorPlanForServiceIsFetching && floorPlanForService) {
      finishInit(dispatch, selectedTimeframe, {
        ...floorPlanForService,
        freeInventories: { places: [], zones: [], equipments: [] }
      });
      setIsEditService(false);
    }
  }, [isEditService, floorPlanForServiceIsFetching]);

  useEffect(() => {
    // show plan or edit the last time frame
    if (isEditTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, selectedTimeframe, floorPlan);
      setIsEditTimeframe(false);
    }
  }, [isEditTimeframe, floorPlanIsFetching]);

  useEffect(() => {
    // timeframe is existed but create new one
    if (isContinueTimeframe && !floorPlanIsFetching && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setIsContinueTimeframe(false);
    }
  }, [isContinueTimeframe, newTimeframeData, floorPlanIsFetching]);

  useEffect(() => {
    // initial the very first timeframe
    if (isCreateFirstTimeframe && floorPlan) {
      finishInit(dispatch, newTimeframeData, floorPlan);
      setIsCreateFirstTimeframe(false);
    }
  }, [isCreateFirstTimeframe, newTimeframeData, floorPlan]);

  if (!uniqueTimeframes || !selected.floor) return <DefaultSpinner />;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {/* description text for edit or new timeframe */}
      <Alert severity="info">{t(`_editOrNewTimeframe`)}</Alert>

      {/* button to refresh time frame */}
      <Button
        data-testid="refresh-btn"
        variant={"text"}
        sx={{
          margin: "40px 10px 0 10px !important",
          textTransform: "none",
          fontSize: "1.1rem",
          color: theme.palette.text.primary
        }}
        onClick={() => refetchFloorTimeframes()}
      >
        <Refresh /> {t("Refresh")}
      </Button>

      {/* list of timeframe */}
      <TimelinesTimeframe
        timeframes={floorTimeframes}
        clickEdit={clickEdit}
        selectedFloor={selected.floor.id}
        refetchFloorTimeframes={refetchFloorTimeframes}
        editServices={editServices}
      />

      {/* when empty timeframe, button to create new timeframe */}
      {!newTimeframe && (
        <Button
          data-testid="new-btn"
          sx={{
            margin: "40px 10px 0 10px !important",
            textTransform: "none",
            fontSize: "1.1rem"
          }}
          onClick={() => setNewTimeframe(true)}
          variant="contained"
          startIcon={<Add />}
        >
          {t("Create new timeframe")}
        </Button>
      )}

      {/* when empty timeframe, datePick to create new timeframe */}
      {newTimeframe && (
        <NewTimeframeCreator
          startDate={startDate}
          setStartDate={setStartDate}
          clickNextCreate={clickNextCreate}
          floorTimeframes={floorTimeframes}
        />
      )}
      <InitializingLoadingScreenModal
        isOpen={isInitializingPlan}
        title={t("Calculating basis for your next plan...")}
      />
    </Box>
  );
};

export default TimeframesMenu;

export function checkPreviousAndSelectedTimeframe(
  previousSelectedTimeframe: MutableRefObject<Timeframe>,
  selectedTimeframe: Timeframe,
  newTimeframe: boolean,
  selectTimeframe: () => void
) {
  if (
    previousSelectedTimeframe.current.start == selectedTimeframe.start ||
    previousSelectedTimeframe.current.end == selectedTimeframe.end ||
    newTimeframe
  ) {
    selectTimeframe();
  } else {
    previousSelectedTimeframe.current = selectedTimeframe;
  }
}

export function checkStartAndReturnTargetFrame(start: DateTime | null, end: DateTime | null) {
  if (!start) throw new Error("Timeframe needs start");

  const targetFrame = {
    start: start.toISO(),
    end: end?.toISO() ?? null
  };

  return targetFrame;
}
