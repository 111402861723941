import React, { useEffect, useState } from "react";
import { Fade, Paper, useTheme } from "@mui/material";
import { ResponsiveBarCanvas } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";

type WeekdayData = {
  id?: number | string;
  monday?: number;
  tuesday?: number;
  wednesday?: number;
  thursday?: number;
  friday?: number;
  saturday?: number;
  sunday?: number;
};
interface WeekdayBarsProps {
  data: any[];
  width?: string;
}
const WeekdayBars: React.FC<WeekdayBarsProps> = ({ data, width }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { currentFloors, selectedZoneOrWorkplace, selectableEntities } = useSelector(
    (state: RootState) => state.report
  );

  const [weekdayData, setWeekdayData] = useState<WeekdayData[]>([]);

  const commonProps = {
    margin: { top: 30, right: 20, bottom: 50, left: 70 },
    padding: 0.2
  };

  const responsiveBarTooltip = (id: string | number, value: number) => {
    return (
      <strong style={{ color: "black", background: "#ffffff", padding: 5, borderRadius: 4 }}>
        {`${t(id.toString())}` + ": " + (value * 100).toFixed(2)}%
      </strong>
    );
  };

  const labelName = (index: number) =>
    selectableEntities && selectableEntities.length
      ? selectableEntities &&
        selectableEntities[0].floors.find(e => e.inventoryId === currentFloors[index].id)?.name
      : "";

  useEffect(() => {
    /** istanbul ignore next */
    let first: any[] = [];
    if (selectedZoneOrWorkplace && selectedZoneOrWorkplace.open) {
      first = data.map(d => ({
        id: selectedZoneOrWorkplace.name,
        monday: d?.capacity?.averageCapacityPerformancePerWeekday?.monday,
        tuesday: d?.capacity?.averageCapacityPerformancePerWeekday?.tuesday,
        wednesday: d?.capacity?.averageCapacityPerformancePerWeekday?.wednesday,
        thursday: d?.capacity?.averageCapacityPerformancePerWeekday?.thursday,
        friday: d?.capacity?.averageCapacityPerformancePerWeekday?.friday,
        saturday: d?.capacity?.averageCapacityPerformancePerWeekday?.saturday,
        sunday: d?.capacity?.averageCapacityPerformancePerWeekday?.sunday
      }));
    } else {
      first = [data.find(d => d?.id === currentFloors[0].id)];
      if (data.length > 1) first.push(data.find(d => d?.id === currentFloors[1].id));

      first = first.map((d, index) => {
        return {
          id: labelName(index),
          monday: d?.capacity?.averageCapacityPerformancePerWeekday?.monday,
          tuesday: d?.capacity?.averageCapacityPerformancePerWeekday?.tuesday,
          wednesday: d?.capacity?.averageCapacityPerformancePerWeekday?.wednesday,
          thursday: d?.capacity?.averageCapacityPerformancePerWeekday?.thursday,
          friday: d?.capacity?.averageCapacityPerformancePerWeekday?.friday,
          saturday: d?.capacity?.averageCapacityPerformancePerWeekday?.saturday,
          sunday: d?.capacity?.averageCapacityPerformancePerWeekday?.sunday
        };
      });
    }
    setWeekdayData(first);
  }, [data]);

  return (
    <Fade mountOnEnter unmountOnExit in={!!weekdayData}>
      <Paper
        data-testid="weekday-report-chart-paper"
        style={{ width: width ? width : "100%", height: 365, margin: 10 }}
        variant={"outlined"}
      >
        <ResponsiveBarCanvas
          {...commonProps}
          data={weekdayData.map(singleData => ({
            id: singleData.id ?? "",
            monday: singleData.monday?.toFixed(2) ?? "",
            tuesday: singleData.tuesday?.toFixed(2) ?? "",
            wednesday: singleData.wednesday?.toFixed(2) ?? "",
            thursday: singleData.thursday?.toFixed(2) ?? "",
            friday: singleData.friday?.toFixed(2) ?? "",
            saturday: singleData.saturday?.toFixed(2) ?? "",
            sunday: singleData.sunday?.toFixed(2) ?? ""
          }))}
          groupMode="grouped"
          keys={["monday", "tuesday", "wednesday", "thursday", "friday"]}
          tooltip={({ id, value }) => responsiveBarTooltip(id, value)}
          theme={{
            tooltip: {
              container: {
                background: "#ffffff",
                borderRadius: 4
              }
            },
            axis: {
              ticks: { text: { fontSize: 10, fill: theme.palette.text.primary } },
              legend: { text: { fill: theme.palette.text.primary } }
            },
            grid: {
              line: {
                stroke: "#585858",
                strokeWidth: 1
              }
            }
          }}
          enableLabel={false}
          enableGridX={false}
          enableGridY={true}
          axisLeft={{
            format: tick => (Number(tick) * 100).toFixed(1) + "%",
            tickValues: 5
          }}
          axisBottom={{
            legend: t("capacity per weekday"),
            legendOffset: 36,
            legendPosition: "middle"
          }}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
        />
      </Paper>
    </Fade>
  );
};
export default WeekdayBars;
