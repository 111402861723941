import { ProviderContext } from "notistack";
import { BookingInputs } from "../../../../Domain/Types/BookingInputs.type";
import { IPlaceSchedule } from "../../../../Domain/Types/FloorPlan/PlaceSchedule";
import { ISeatStatus } from "../../../../Domain/Types/FloorPlan/SeatStatus.type";
import { generatePlaceVariant } from "../../../../Functions/generateVariant";
import { TFunction } from "i18next";
import { TeamMember } from "../../../../../../features/Booking-Form/typings/team-member";
import { BookingType } from "../../../../../../features/Booking-Form/typings/booking-inputs";
import { IZoneSchedule } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { FloorAvailabilityWarningData } from "../../../../../../features/FloorManager/typings/floor-warningData.entity";
import { UserInformation } from "../../../../../../features/Login/typings/login.types";
import { PlaceVariant } from "../../../../Domain/Types/FloorPlan/PlaceVariant.type";

export function selectableByVariant(
  { place, zone }: { place: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined },
  seatStatus: ISeatStatus,
  bookingType: BookingType | null
) {
  if (!bookingType) return false;
  if (!place && !zone) return false;

  const variant = generatePlaceVariant(
    { workplace: place, zone: zone },
    seatStatus,
    undefined,
    bookingType
  );

  return variant === PlaceVariant.AVAILABLE || variant === PlaceVariant.TEMP;
}

export function handleSelection(
  bookingInputs: BookingInputs,
  userIndex: number,
  floorPlan: IFloorPayload,
  selectedEntry: {
    selectedPlace: number | undefined;
    selectedZone: number | undefined;
  },
  stateSelectActions: {
    setSelectedPlace: (p: number | undefined) => void;
    setUserIndex: (i: number) => void;
  },
  onSelectActions: {
    onSingleSelect?: (placeInventoryId: number) => void;
    onTeamSelect?: (index: number, id: number) => void;
    onNextStep: () => void;
  },
  snackActions: {
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
    t: TFunction;
  }
) {
  const { onSingleSelect, onTeamSelect, onNextStep } = onSelectActions;
  const { setSelectedPlace, setUserIndex } = stateSelectActions;
  const { enqueueSnackbar, t } = snackActions;
  const { mode, usersBookedFor } = bookingInputs;

  if (mode === "team") {
    handleTeamSelection(
      usersBookedFor,
      userIndex,
      floorPlan.places,
      selectedEntry.selectedPlace,
      setUserIndex,
      { onNextStep, onTeamSelect },
      { enqueueSnackbar, t }
    );
  } else handleSingleSelection(floorPlan, selectedEntry, setSelectedPlace, onSingleSelect);
}

export function handleSingleSelection(
  floorPlan: IFloorPayload,
  selectedEntry: {
    selectedPlace: number | undefined;
    selectedZone: number | undefined;
  },
  setSelectedPlace: (p: number | undefined) => void,
  onSingleSelect?: (placeInventoryId: number) => void
) {
  const { selectedPlace, selectedZone } = selectedEntry;

  let inventoryId: number | undefined;

  if (selectedPlace) {
    const plInventoryId = floorPlan.places.find(wp => wp.id === selectedPlace)?.inventoryId;
    const plInventory = floorPlan.places.find(wp => wp.id === selectedPlace)?.inventory;
    inventoryId = plInventoryId || plInventory?.id;
  }
  if (selectedZone) {
    const znInventoryId = floorPlan.zones.find(zn => zn.id === selectedZone)?.inventoryId;
    const znInventory = floorPlan.zones.find(zn => zn.id === selectedZone)?.inventory;
    inventoryId = znInventoryId || znInventory?.id;
  }

  if (!inventoryId) return;

  onSingleSelect && onSingleSelect(inventoryId);
  setSelectedPlace(undefined);
}

export function handleTeamSelection(
  usersBookedFor: TeamMember[],
  userIndex: number,
  workplaces: IPlaceSchedule[],
  placeId: number | undefined,
  setUserIndex: (i: number) => void,
  onSelectActions: {
    onTeamSelect?: (index: number, placeInventoryId: number) => void;
    onNextStep: () => void;
  },
  snackActions: {
    enqueueSnackbar: ProviderContext["enqueueSnackbar"];
    t: TFunction;
  }
) {
  const { onTeamSelect, onNextStep } = onSelectActions;
  const { enqueueSnackbar, t } = snackActions;

  const checkProps =
    userIndex !== undefined && usersBookedFor !== undefined && setUserIndex !== undefined;

  const selectedPlaceInventoryId = workplaces.find(wp => wp.id === placeId)?.inventoryId;
  const selectedPlaceInventory = workplaces.find(wp => wp.id === placeId)?.inventory;
  const selected = selectedPlaceInventoryId || selectedPlaceInventory?.id;

  if (!selected) return;

  if (usersBookedFor?.some(user => user.bookingInventoryId === selected)) {
    enqueueSnackbar(t("_alreadyAssginedToAnotherMember"), {
      variant: "error"
    });
  } else {
    if (checkProps && userIndex !== usersBookedFor.length - 1) {
      onTeamSelect && onTeamSelect(userIndex, selected);
      setUserIndex(userIndex + 1);
    } else if (checkProps && userIndex === usersBookedFor.length - 1) {
      onTeamSelect && onTeamSelect(userIndex, selected);
      setUserIndex(0);
      onNextStep();
    }
  }
}

export function generateInitial(
  name: string,
  usersBookedFor: TeamMember[] | undefined,
  userInformation: UserInformation | undefined,
  placeInventoryId: number | undefined
) {
  // prohibit to generate other cases renders
  if (name !== "booking") return;

  if (!usersBookedFor || !placeInventoryId) return undefined;

  const booking = usersBookedFor?.find(u => u.bookingInventoryId === placeInventoryId);

  if (!booking) return undefined;
  if (booking.photoUrl) return booking.photoUrl;
  if (booking.isExternal && booking.firstName && booking.surname)
    return booking.firstName[0] + booking.surname[0];
  // check if userInformation has photoUrl
  else if (!usersBookedFor[0].isExternal && userInformation?.photoUrl) {
    return userInformation?.photoUrl;
  }

  if (booking.firstName && booking.surname) return booking.firstName[0] + booking.surname[0];

  return undefined;
}

export const compareBookingTypeAndPlaceType = (
  bookingType: BookingType | null | undefined,
  { placeTypeId, zoneTypeId }: { placeTypeId: number | undefined; zoneTypeId: number | undefined }
) => {
  if (!bookingType) return true;
  if (!placeTypeId && !zoneTypeId) return false;

  if (bookingType === BookingType.WORKPLACE && placeTypeId === 1) return true;
  if (bookingType === BookingType.TEAM && placeTypeId === 1) return true;
  if (bookingType === BookingType.PARKINGPLACE && placeTypeId === 2) return true;
  if (bookingType === BookingType.CANTEENPLACE && placeTypeId === 3) return true;
  if (bookingType === BookingType.ELECTRICCHARGINGSTATIONPLACE && placeTypeId === 4) return true;

  if (bookingType === BookingType.CONFERENCEZONE && zoneTypeId === 3) return true;

  return false;
};

export function clickableByZoneType(
  bookingType: BookingType | null,
  zoneTypeId: number | undefined
) {
  if (zoneTypeId === 0 || !bookingType) return false;
  return bookingType === BookingType.CONFERENCEZONE && zoneTypeId === 3;
}

export function isZoneClickable({
  bookingType,
  zoneTypeId,
  zoneInventoryId,
  seatStatus
}: {
  bookingType: BookingType | null;
  zoneTypeId: number | undefined;
  zoneInventoryId: number;
  seatStatus: ISeatStatus;
}) {
  if (zoneTypeId === 0 || !bookingType) return false;

  return (
    bookingType === BookingType.CONFERENCEZONE &&
    zoneTypeId === 3 &&
    !seatStatus.disableList.includes(zoneInventoryId) // (seatStatus.availableList.includes(zoneInventoryId) || seatStatus.tempList?.includes(zoneInventoryId))
  );
}

export function clickableByPlaceType(
  bookingType: BookingType | null,
  placeTypeId: number | undefined
) {
  if (placeTypeId === 0 || !bookingType) return false;
  switch (bookingType) {
    case BookingType.WORKPLACE:
    case BookingType.TEAM:
      return placeTypeId === 1;

    case BookingType.PARKINGPLACE:
      return placeTypeId === 2;

    case BookingType.CANTEENPLACE:
      return placeTypeId === 3;

    case BookingType.ELECTRICCHARGINGSTATIONPLACE:
      return placeTypeId === 4;

    default:
      return false;
  }
}

/**
 * function to check if user clicks selectable place or zone
 * matching with booking type
 */
export function isClickedSelectableEntity(
  { place, zone }: { place: IPlaceSchedule | undefined; zone: IZoneSchedule | undefined },
  bookingType: BookingType | null,
  seatStatus: ISeatStatus
) {
  const zoneClickable = isZoneClickable({
    bookingType,
    zoneTypeId: zone?.inventory?.zoneTypeId,
    zoneInventoryId: zone?.inventory?.id as number,
    seatStatus
  });

  const placeClickable = clickableByPlaceType(
    bookingType,
    place?.placeTypeId || place?.inventory?.placeTypeId || 0
  );

  return zoneClickable || placeClickable;
}

export function selectedEntry(
  floorPlan: IFloorPayload,
  selectedPlace: number | undefined,
  selectedZone: number | undefined
) {
  let place: IPlaceSchedule | undefined;
  let zone: IZoneSchedule | undefined;

  if (selectedPlace) place = floorPlan.places.find(w => w.id === selectedPlace);
  if (selectedZone) zone = floorPlan.zones.find(z => z.id === selectedZone);

  return { place, zone };
}

// compare min and maxUsers of conference zone and booking guests number, then return boolean
export function checkUserNumberBooking(
  bookingType: BookingType | null,
  minUsers: number | null | undefined,
  maxUsers: number | null | undefined,
  zoneAccess: BookingInputs["zoneAccess"],
  warningData: FloorAvailabilityWarningData
) {
  const { requiredUserWarningData, optionalUserWarningData } = warningData;

  // check the booking type
  if (bookingType !== BookingType.CONFERENCEZONE) return false;
  // sanity check
  if (minUsers === undefined || maxUsers === undefined) return false;

  // expected participants of required user
  const amountRequiredUser = requiredUserWarningData?.amountUsersBookedSomewhereElse ?? 0;
  // expected participants of optional user
  const amountOptionalUser = optionalUserWarningData?.amountUsersBookedSomewhereElse ?? 0;
  const selectedGuestsNumber = zoneAccess ?? [];

  // compare with the number : guests + 1 (booking owner)
  if (
    minUsers &&
    minUsers > selectedGuestsNumber.length + 1 - (amountRequiredUser + amountOptionalUser)
  )
    return true;

  // maxUsers null means unlimited
  if (maxUsers === null) return false;

  if (maxUsers < selectedGuestsNumber.length + 1 - (amountRequiredUser + amountOptionalUser))
    return true;

  return false;
}
