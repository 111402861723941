export enum UserRole {
  // admin roles
  isTenantAdmin = "tenant-admin",
  isCompanyAdmin = "company-admin",
  isLocationAdmin = "location-admin",
  isFinanceAdmin = "finance-admin",
  isHrAdmin = "hr-admin",
  // responsible roles
  isEquipmentResponsible = "equipment-responsible",

  isProjectResponsible = "project-responsible",
  isWorkOrderResponsible = "work-order-responsible",
  isCostCenterResponsible = "cost-center-responsible",
  isCapacityResponsible = "capacity-responsible",
  isEmployeeResponsible = "employee-responsible",
  isZoneBookingCapable = "zonebooking-capable"
}
