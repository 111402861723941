import { PropsWithChildren, forwardRef } from "react";
import { Box, Stack } from "@mui/material";

/**
 * a sidebar shown on the right side of the canvas.
 * it allows scrolling when there are too many elements
 * @param children
 * @param height height of the sidebar, should be so it closes with the end of the canvas
 * @param width the width of the sidebar
 */

type Props = {
  height: number;
  width: number;
  overflow: string;
};

export const InfoSideBar = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, height, width, overflow }, ref) => {
    return (
      <>
        <Box
          ref={ref}
          overflow={overflow}
          maxHeight={height - 65 - 8}
          sx={{
            position: "absolute",
            top: 65,
            right: 0,
            m: 1
          }}
        >
          <Stack width={width} gap={1}>
            {children}
          </Stack>
        </Box>
      </>
    );
  }
);
