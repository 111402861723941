import { useMemo } from "react";

export type ExcelHeadCell<DataType> = {
  key: Extract<keyof DataType, string>;
  label: string;
};
export type ExcelData<DataType> = {
  excelHeaders: ExcelHeadCell<DataType>[];
  excelData: Array<DataType>;
};

export function useExcelPrint<T>({ excelHeaders, excelData }: ExcelData<T>) {
  const ColumnsKeys = excelHeaders.map(item => item.key);

  const CSVheaders = excelHeaders.map((header: any) => {
    return header.label;
  });

  const CSVData = useMemo(
    () =>
      excelData.map(row => {
        return ColumnsKeys.map((column: keyof T) => {
          return row[column];
        });
      }),

    [excelData]
  );
  return { CSVheaders, CSVData };
}