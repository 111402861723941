import { useState } from "react";
import { Box } from "@mui/material";
import { useTextFieldColors } from "../CompactTextfield/useTextFieldColors";
import { useTranslation } from "react-i18next";

export function AnnotatedTextField({
  index,
  fieldLength,
  placeholder,
  value,
  onChange,
  type = "text",
  readOnly
}: {
  index: number;
  fieldLength: number;
  placeholder: string;
  value: string | number | null;
  onChange?: (newValue: string) => void;
  type?: "text" | "number";
  readOnly?: boolean;
}) {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { borderColor, placeholderBackgroundColor, textColor } = useTextFieldColors(isFocused);

  function handleFocus() {
    setIsFocused(true);
  }

  function handleBlur() {
    setIsFocused(false);
  }

  const boxBorderRadiusTopLeftAndBottomLeft = index === 0 ? 4 : 0;
  const boxBorderRadiusTopRightAndBottomRight = index === fieldLength - 1 ? 4 : 0;
  const spanBorderRadiusTopLeftAndBottomLeft = index === 0 ? 2 : 0;
  const spanPaddingForPlaceHolder = placeholder === "Min" || placeholder === "Max" ? 2 : 4;
  const inputPaddingForPlaceHolder = placeholder === "Min" || placeholder === "Max" ? 3 : 6;
  const letterSpacingForPlaceHolder =
    placeholder === "Min" || placeholder === "Max" ? "-0.8px" : "inherit";

  return (
    <Box
      position={"relative"}
      flex={1}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row"}
      sx={{
        border: `1px solid ${borderColor}`,
        borderTopLeftRadius: boxBorderRadiusTopLeftAndBottomLeft,
        borderBottomLeftRadius: boxBorderRadiusTopLeftAndBottomLeft,
        borderTopRightRadius: boxBorderRadiusTopRightAndBottomRight,
        borderBottomRightRadius: boxBorderRadiusTopRightAndBottomRight,
        borderRadius: index !== fieldLength - 1 && index !== 0 ? 0 : "none",
        borderRight: index !== fieldLength - 1 ? "none" : `1px solid ${borderColor}`,
        height: 30
      }}
    >
      <span
        style={{
          transitionDuration: "200ms",
          color: textColor,
          fontSize: 12,
          fontFamily: "sans-serif",
          borderTopLeftRadius: spanBorderRadiusTopLeftAndBottomLeft,
          borderBottomLeftRadius: spanBorderRadiusTopLeftAndBottomLeft,
          paddingLeft: spanPaddingForPlaceHolder,
          paddingRight: spanPaddingForPlaceHolder,
          background: placeholderBackgroundColor,
          height: "100%",
          display: "flex",
          alignItems: "center",
          letterSpacing: letterSpacingForPlaceHolder
        }}
      >
        {placeholder}
      </span>
      <input
        readOnly={readOnly}
        type={type}
        value={value ?? ""}
        onChange={event => onChange?.(event.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder === "Max" && value === null ? t("Unlimited") : placeholder} // max number of users for conference zone can be nullable
        style={{
          color: textColor,
          paddingLeft: inputPaddingForPlaceHolder,
          paddingRight: inputPaddingForPlaceHolder,
          width: "100%",
          height: 20,
          border: "none",
          background: "none",
          outline: "none",
          fontSize: 12,
          letterSpacing: letterSpacingForPlaceHolder
        }}
      />
    </Box>
  );
}
