import { forwardRef, useEffect, useState } from "react";
import { InfoSideBar } from "../InfoSidebar";
import { IZoneSchedule, IZoneUserNumber } from "../../../../Domain/Types/FloorPlan/ZoneSchedule";
import { InfoBox } from "../InfoBox";
import { Box, Grid, IconButton, Modal } from "@mui/material";
import { CompactTextField } from "../../../../../Common/CompactTextfield/CompactTextfield";
import { Close } from "@mui/icons-material";
import { AssignBox } from "../AssignBox/AssignBox";
import { IFloorPayload } from "../../../../Domain/Types/FloorPlan/FloorPayload.type";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { MultiInfoHeader } from "../MultiInfoHeader/MultiInfoHeader";
import { MiniTextInput } from "../../../../../Common/MiniTextInput/MiniTextInput";
import { InfoIconWithTooltip } from "../../../../../Title/InfoIcon";
import { handleUserNumbersInput, initialUserNumbers } from "./ZoneInfoSidebar.partial";
import { TablePlacementDialogContent } from "./TablePlacementDialogContent/TablePlacementDialogContent";
import { SingleTableArrangement } from "../../../../Domain/Types/FloorPlan/TableArrangement.type";

interface Props {
  height: number;
  zoneSchedules: IZoneSchedule[];
  floorPlan: IFloorPayload;
  onClose: () => void;
  onChangeUserNumber: (ids: number[], userNumber: IZoneUserNumber) => void;
  onChangeConfService: (id: number, service: SingleTableArrangement[]) => void;
}

/** simple component for editing zone service info */
export const ZoneServiceInfoSidebar = forwardRef<HTMLDivElement, Props>(
  ({ height, zoneSchedules, floorPlan, onClose, onChangeUserNumber, onChangeConfService }, ref) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { minUsers, maxUsers } = initialUserNumbers(zoneSchedules);

    const zoneName = zoneSchedules[0]?.inventory?.name ?? "";
    const zoneDesc = zoneSchedules[0]?.description ?? "";

    const [zoneUserNumber, setZoneUserNumber] = useState<IZoneUserNumber>({
      minUsers: minUsers,
      maxUsers: maxUsers
    });

    const [isTableModalOpen, setIsTableModalOpen] = useState<boolean>(false);

    useEffect(() => {
      setZoneUserNumber({
        minUsers: minUsers,
        maxUsers: maxUsers
      });
    }, [minUsers, maxUsers]);

    const zoneTypeId =
      zoneSchedules[0]?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneTypeId ||
      zoneSchedules[0]?.inventory?.zoneType?.id;
    const zoneTypeName = floorPlan.zoneTypes?.find(type => type.id === zoneTypeId)?.name || "";

    // length of selected zone
    const singleZoneSelected = zoneSchedules.length === 1;

    // check if multi selected zone have same type
    const isIdenticalZoneTypeSelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === (zoneSchedules[0].inventory?.zoneTypeId || zoneSchedules[0].zoneTypeId));

    // check if multi selected zone type includes conference zone
    const isConferenceOnlySelected = zoneSchedules
      .map(z => z.zoneTypeId || z.inventory?.zoneTypeId)
      .every(v => v === 3);

    return (
      <InfoSideBar height={height} width={250} overflow="visible" ref={ref}>
        {zoneSchedules.length > 0 && (
          <InfoBox color={"#00000088"}>
            {/* header of multi zones edit and listing the names */}
            <MultiInfoHeader
              showMultiInfoHeader={zoneSchedules.length > 1}
              entries={zoneSchedules}
              type={"Zone"}
            />

            {/* name of the zone */}
            <CompactTextField
              placeholder={t("Name")}
              value={zoneName}
              newText={zoneName}
              rows={2}
              readOnly={true}
            />

            {/* description of the zone */}
            <CompactTextField
              rows={2}
              placeholder={t("Description")}
              value={zoneDesc}
              newText={zoneDesc}
              readOnly={true}
            />

            {/* type of the zone */}
            {isIdenticalZoneTypeSelected && (
              <AssignBox
                buttonDataTestId="type-assign-box"
                id={
                  zoneSchedules[0].zoneTypeId ||
                  zoneSchedules[0].inventory?.zoneType?.id ||
                  zoneSchedules[0].inventory?.zoneTypeId
                }
                name={zoneTypeName}
                type={"Type"}
              />
            )}

            {/** inventory */}
            {singleZoneSelected && (
              <AssignBox id={zoneSchedules[0].inventory?.id} type={"Inventory"} />
            )}

            {/** category */}
            <AssignBox
              type={"Category"}
              buttonDataTestId="assign-category-btn"
              id={zoneSchedules[0].category?.id}
            />

            {/** property for conference zone */}
            {isConferenceOnlySelected && singleZoneSelected && (
              <>
                {/** min and max users number */}
                <Grid
                  sx={{ pt: "0.3rem", flexWrap: "nowrap" }}
                  data-testid="zone-userNumber-input"
                  container
                >
                  <Grid item>
                    <MiniTextInput
                      fields={[
                        {
                          id: "min",
                          type: "number",
                          placeholder: "Min",
                          value: zoneUserNumber.minUsers
                        },
                        {
                          id: "max",
                          type: "number",
                          placeholder: "Max",
                          value: zoneUserNumber.maxUsers
                        }
                      ]}
                      readOnly={true}
                      onInput={(id, value) => {
                        handleUserNumbersInput(
                          id,
                          value,
                          zoneSchedules[0].id,
                          zoneUserNumber,
                          setZoneUserNumber,
                          onChangeUserNumber,
                          enqueueSnackbar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item data-testid="user-number-info-desc" sx={{ pt: "2px", pl: "2px" }}>
                    <InfoIconWithTooltip tooltipText={t("EditInfoUserNumberForConferenceZone")} />
                  </Grid>
                </Grid>

                {/** table placements */}
                <AssignBox
                  onClickAssign={() => setIsTableModalOpen(true)}
                  id={zoneSchedules[0].inventory?.zoneTypeId}
                  type={"TablePlacement"}
                  buttonDataTestId="assign-tablePlacement-btn"
                />
              </>
            )}

            {/** bottom bar */}
            <Box display={"flex"} alignItems={"center"}>
              <Box flex={1} />

              <Box>
                <IconButton data-testid={"close-btn"} size={"small"} onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>

            {/* conference zone - table placement modal */}
            <Modal
              open={isTableModalOpen}
              onClose={() => setIsTableModalOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <>
                <TablePlacementDialogContent
                  tableArr={floorPlan.tableArrangementAssignments}
                  onClose={() => setIsTableModalOpen(false)}
                  zoneSchedule={zoneSchedules[0]}
                  onChangeConfService={onChangeConfService}
                />
              </>
            </Modal>
          </InfoBox>
        )}
      </InfoSideBar>
    );
  }
);
