import React, { useRef, useState } from "react";
import { Box, Popover } from "@mui/material";
import { BookingType, NotSerializedBookingMode } from "../typings/booking-inputs";
import { FilterDto } from "../typings/inputs-filter";
import EquipmentCategoryFilter from "./equipment-category-filter.component";
import EquipmentFilter from "./equipment-filter.component";
import PlaceCategoryFilter from "./place-category-filter.component";
import ZoneCategoryFilter from "./zone-category-filter.component";
import { IPlaceCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/PlaceCategory.type";
import { IZoneCategory } from "../../../components/FacilityManager/Domain/Types/FloorPlan/ZoneCategory.type";
import BookingFiltersButtons from "./booking-filters-buttons.component";
import { useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { PricingModel } from "../../Login/typings/login.types";
import { MultiselectOption } from "../../Reports/typings/reports.types";

type P = {
  bookingMode?: NotSerializedBookingMode;
  filter: FilterDto;
  placeCategories: IPlaceCategory[];
  zoneCategories: IZoneCategory[];
  anchorElDevice: HTMLButtonElement | null;
  anchorElDeviceCategories: HTMLButtonElement | null;
  setAnchorElDevice: (el: HTMLButtonElement | null) => void;
  setAnchorElDeviceCategories: (el: HTMLButtonElement | null) => void;
  onSelectedChange: (selected: MultiselectOption[]) => void;
};

const BookingFilters: React.FC<P> = ({
  bookingMode,
  filter,
  placeCategories,
  zoneCategories,
  anchorElDevice,
  anchorElDeviceCategories,
  setAnchorElDevice,
  setAnchorElDeviceCategories,
  onSelectedChange
}) => {
  const {
    userInformation: { pricingModels }
  } = useSelector((state: RootState) => state.login);

  const [isOpenCategoryFilter, setIsOpenCategoryFilter] = useState(false);

  // check booking mode or pricing model to display buttons
  const showPlaceCategoryFilter =
    bookingMode === BookingType.WORKPLACE ||
    bookingMode === BookingType.TEAM ||
    bookingMode === BookingType.PARKINGPLACE ||
    bookingMode === BookingType.ELECTRICCHARGINGSTATIONPLACE;
  const showZoneCategoryFilter =
    bookingMode === BookingType.PLACEZONE || bookingMode === BookingType.CONFERENCEZONE;
  const showDeviceFilters = pricingModels.includes(PricingModel.ENTERPRISE);

  // open popover when anchor element is defined
  const openDevice = Boolean(anchorElDevice);
  const openDeviceCategories = Boolean(anchorElDeviceCategories);

  const categoryButtonRef = useRef(null);

  return (
    <>
      {/* display buttons to filter */}
      <BookingFiltersButtons
        showDeviceFilters={showDeviceFilters}
        showPlaceCategoryFilter={showPlaceCategoryFilter}
        showZoneCategoryFilter={showZoneCategoryFilter}
        setAnchorElDevice={setAnchorElDevice}
        setAnchorElDeviceCategories={setAnchorElDeviceCategories}
        isOpenCategoryFilter={isOpenCategoryFilter}
        setIsOpenCategoryFilter={setIsOpenCategoryFilter}
        categoryButtonRef={categoryButtonRef}
      />

      {/* category filter for place/zone */}
      {categoryButtonRef.current && (
        <Popover
          open // to apply the preferred category items on the filter
          sx={{ visibility: isOpenCategoryFilter ? "visible" : "collapse" }} // display: isOpenCategoryFilter ? "inherit" : "none"
          anchorEl={categoryButtonRef.current}
          onClose={() => setIsOpenCategoryFilter(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          <Box data-testid="category-filter-box" sx={{ padding: 2 }}>
            {showPlaceCategoryFilter && (
              <PlaceCategoryFilter
                categories={placeCategories}
                onSelectedChange={onSelectedChange}
                isOpenCategoryFilter={isOpenCategoryFilter}
              />
            )}
            {showZoneCategoryFilter && (
              <ZoneCategoryFilter
                categories={zoneCategories}
                onSelectedChange={onSelectedChange}
                isOpenCategoryFilter={isOpenCategoryFilter}
              />
            )}
          </Box>
        </Popover>
      )}

      {/* device filter, currently not implemented */}
      <Popover
        open={openDevice}
        anchorEl={anchorElDevice}
        onClose={() => setAnchorElDevice(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Box style={{ width: 200, padding: 10 }}>
          <EquipmentFilter filter={filter} />
        </Box>
      </Popover>

      {/* device-category filter, currently not implemented */}
      <Popover
        open={openDeviceCategories}
        anchorEl={anchorElDeviceCategories}
        onClose={() => setAnchorElDeviceCategories(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Box style={{ width: 200, padding: 10 }}>
          <EquipmentCategoryFilter filter={filter} />
        </Box>
      </Popover>
    </>
  );
};

export default BookingFilters;
