import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { MRT_Row } from "material-react-table";
import { PlaceApprovalRow, PlaceApprovalTableState } from "../place-booking-approval.component";

interface P {
  renderButton: (params: MRT_Row<PlaceApprovalRow>["original"]) => JSX.Element | null;
  localeFullFormat: string;
}

export default function usePlaceApprovalColumns({ renderButton, localeFullFormat }: P) {
  const { t } = useTranslation();

  return (): PlaceApprovalTableState =>
    ({
      columns: [
        {
          header: t("Name"),
          accessorKey: "userBookedFor",
          size: 180
        },
        {
          accessorKey: "locationName",
          header: t("Location"),
          size: 150
        },
        { accessorKey: "floorName", header: t("Floor"), size: 100 },
        {
          header: t("Category"),
          accessorKey: "workplaceCategory",
          size: 60
        },
        {
          size: 60,
          accessorKey: "weekday",
          header: t("Weekday")
        },
        {
          header: t("Start Date"),
          size: 200,
          sortingFn: "datetime",
          filterVariant: "date",
          filterFn: "greaterThan",
          Cell: ({ cell }) =>
            DateTime.fromJSDate(cell.getValue() as Date).toFormat(localeFullFormat),
          muiFilterDatePickerProps: {
            format: "DD.MM.YYYY"
          },
          accessorKey: "start",
          accessorFn: row => new Date(row.start)
        },
        {
          accessorKey: "end",
          filterVariant: "date",
          filterFn: "lessThan",
          sortingFn: "datetime",
          accessorFn: row => new Date(row.end),
          header: t("End Date"),
          size: 200,
          muiFilterDatePickerProps: {
            format: "DD.MM.YYYY"
          },
          Cell: ({ cell }) =>
            DateTime.fromJSDate(cell.getValue() as Date).toFormat(localeFullFormat)
        },
        {
          header: "",
          size: 20,
          accessorKey: "renderButton",
          Cell: ({ cell }) => renderButton(cell.row.original),
          enableColumnFilter: false
        }
      ]
    }) as PlaceApprovalTableState;
}
